import numeral from "numeral";

export let roundValues = (a) => {
  if (!a || isNaN(a)) return a;
  return numeral(
    Math.round(
      numeral(a)
        .multiply(100)
        .value()
    )
  )
    .divide(100)
    .value()
    .toFixed(2);
};

export let ceilValues = (a) => {
  if (!a || isNaN(a)) return a;
  return numeral(
    Math.ceil(
      numeral(a)
        .multiply(100)
        .value()
    )
  )
    .divide(100)
    .value()
    .toFixed(2);
};
