import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import moment from "moment";
function TextField(props) {
  const [value, setValue] = useState("");
  const [loggedInEmail, setLoggedInEmail] = useState("");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    loggedInEmailId();
  }, []);

  let loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      setLoggedInEmail(sessionData["attributes"]["email"]);
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  const checkcommentAvailble = (comment) => {
    if (comment) {
      if (comment?.[0] !== "") {
        if (typeof comment?.[0] === "string") {
          return "#FFE7C1";
        }
      }
    }

    if (comment?.length > 1) {
      return "#FFE7C1";
    }
  };
  return (
    <textarea
      value={value || ""}
      className="underwriter_comment"
      rows="1"
      style={{
        width: "100%",
        backgroundColor: checkcommentAvailble(props.comment),
        borderRadius:"4px",
      }}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        let uwCommentsCopy = props.comment;

        let currentTimestamp = moment().valueOf();
        let dataToSave = {};

        if (e.target.value.trim() !== "") {
          if (e.target.value.trim() !== uwCommentsCopy[0].comment) {
            e.target.value.trim() !== uwCommentsCopy[0]?.comment &&
              uwCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          } else {
            return; // Return because no comment was entered;
          }
        } else if (
          e.target.value.length === 0 ||
          e.target.value.trim() === ""
        ) {
          if (e.target.value.trim() !== uwCommentsCopy[0]?.comment) {
            uwCommentsCopy.unshift({
              comment: e.target.value.trim(),
              commentTimestamp: currentTimestamp,
              previousComment: "true",
              email: loggedInEmail,
            });
          } else {
            return; // Return because no comment was entered;
          }
        }

        if (props?.rowData?.uuidCarriers) {
          dataToSave.sortKeyList = props.rowData.uuidCarriers;
        }

        dataToSave[props.rowDataName] = props?.rowDataID;
        dataToSave[props.rowDataComments] = uwCommentsCopy;
        dataToSave[props.rowDataComments] && props.callback(dataToSave);
      }}
    ></textarea>
  );
}
export default TextField;
