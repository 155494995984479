
import PDFMerger from 'pdf-merger-js/browser';

export const mergePdf = async (files) => {
  try {
    const merger = new PDFMerger();

    for (const file of files) {
      console.log("type: ", typeof file)
      await merger.add(file)
    }

    const mergedPdf = await merger.saveAsBlob();
    return mergedPdf;
  } catch (error) { }
}