import React from "react";

export default function UniqueAboutUs(props) {
  return process.env.REACT_APP_MODE == "beta" ? (
    " "
  ) : (
    <div className="unique-about-us" id="unique-about-us">
      <div className="row row-1 row-no-margin">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="our-services-text">What’s unique about us?</div>
      </div>
      <div className="row-2 row-no-margin">
        <div className="play-vid-container">
          {/* <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/rdw7UMsbRk8"
            title="Affinity Group - Fourth"
            frameborder="0"
            allow="accelerometer; autoplay;
                clipboard-write; encrypted-media;
                 gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> */}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/GJJn7PzxGVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          {/* <img src={props.noPath} className="vid-img" width={"100%"} height={"100%"} alt="vid" />
                <img src={props.noPathCover} className="vid-img-cover" width={"100%"} height={"100%"} alt="vid" />
                <img src={props.play} className="play-button" width={"109px"} height={"109px"} alt="play" /> */}
        </div>
      </div>
    </div>
  );
}
