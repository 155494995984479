import React from "react";
import { Link } from "react-router-dom";
let HotScheduleAffinity = require("../../../images/affinity-new.png");
let mobilebackground = require("../../../images/mobilebackground.png");
let HotSchedulesLogo = require("../../../images/HotSchedulesLogo.png");
let FourthLogo = require("../../../images/fourth-new.webp");
let ismobile = window.innerWidth < 992;
let ismobile1 = window.innerWidth < 700;
export default function TopImage(props) {
  let style1 = {
    background: ` url(${props.imageURL})`,
  };
  let style2 = {
    background: ` url(${mobilebackground})`,
  };
  let displayImage = style1;
  //!ismobile ? style1 : style2;
  return (
    <div className="top-image" style={displayImage}>
      <div
        className="d-flex justify-content-between first-row bringingcolumn"
        style={{ paddingTop: "65px" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          {process.env.REACT_APP_MODE !== "beta" && (
            <Link
              to={"/"}
              // style={{ height: "150px", width: "265px" }}
              className="navbar-brand d-flex align-items-center justify-content-center hotlogotop"
            >
              <img
                style={{ height: "440px", marginLeft: "1rem" }}
                src={FourthLogo}
                alt="affinity logo1"
                className="logoreduce"
              />
            </Link>
          )}
          ;
        </div>
        <div className="logo-2 d-flex justify-content-center align-items-center">
          <Link to={"/"} className="navbar-brand d-flex align-items-center">
            {
              <img
                src={HotScheduleAffinity}
                alt="affinity logo1"
                className="logoreducing "
              />
            }
          </Link>
        </div>
      </div>
      <div className={`${ismobile1 ? "container" : "container-fluid"}`}>
        <div className="row row-no-margin ">
          <div className="col-lg-6 topImageContent">
            <div className={` lower-block ${ismobile1 ? "" : ""}`}>
              <div className="ready-for-whatever-comes-next">
                Ready for Whatever Comes Next
              </div>
              <div className="aquired-by">
                {`Acquired by   ${
                  process.env.REACT_APP_MODE == "beta" ? "ABC" : "Fourth"
                }  in 2018, ${
                  process.env.REACT_APP_MODE == "beta" ? "PEO" : "Choice HR"
                } has been helping hundreds of
            business owners over the last 15 years`}
              </div>
              {
                <button
                  className={"btn button_color"}
                  onClick={async (e) => {
                    if (props.domain && props.user) {
                      await props.handleHomeGetQuoteClicked(
                        props.domain,
                        props.user
                      );
                      sessionStorage.clear();
                      sessionStorage.setItem("homeCampaign", "true");
                      window.location.href = `/Quote/?user=${props.user}&campaignName=${props.campaignName}`;
                    } else {
                      window.location.href = "/Quote";
                    }
                  }}
                >
                  GET QUOTE NOW
                </button>
              }
            </div>
          </div>

          <div className="col-lg-6 twoPeopleImage">
            <span className="et_pb_image_wrap">
              <img
                src={props.twoPeopleImage}
                alt="et-pb-image"
                className="et_pb_image"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="et_pb_bottom_inside_divider"></div>
    </div>
  );
}
