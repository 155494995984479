import React, {
  Component,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Loader from "../common/Loader";
import Header from "../common/Header";
import Stats2 from "../subcompo/sections-home/Stats2";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import clipboard from "../../images/clipboard.svg";
import toast, { Toaster } from "react-hot-toast";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { alpha } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { emailNameMap } from "../../utils/emailMap.json";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "react-bootstrap";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";

import parse from "html-react-parser";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";

import { createCommentForDownload } from "../common/dashboard_helper.js";
import { isDeepEquals } from "../../utils/isDeepEquals.js";

import NumberFormat from "react-number-format";
import { getSalesDashboardDataAdmin } from "../../api/commonApi.js";

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");
let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <></>,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  MQL: 6,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  "Opened Email": 23,
  "Not Campaigned": 24,
  "-": 999,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const EMPTY_ROW_PANEL = {
  contact_name: "",
  phone_number: "",
  email: "",
  job_description: "",
  visit_count: 0,
  status: "-",
  isSaved: false,
};

function Tab2DetailPanel(props) {
  const {
    rowData,
    parentAdditionalRows,
    showAddContact,
    prim_key,
    saveContact,
  } = props;
  const prev_props = usePrevious({
    rowData,
    parentAdditionalRows,
    showAddContact,
  });
  const [additionalRows, setAdditionalRows] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));

    if (
      (parentAdditionalRows?.length && !_additionalRows?.length) ||
      (parentAdditionalRows?.length ===
        prev_props?.parentAdditionalRows?.length &&
        !isDeepEquals(parentAdditionalRows, prev_props?.parentAdditionalRows))
    ) {
      setAdditionalRows(parentAdditionalRows);
    }
  }, [parentAdditionalRows]);

  const onAddContact = () => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows.push(JSON.parse(JSON.stringify(EMPTY_ROW_PANEL)));
    setAdditionalRows(_additionalRows);
  };

  const onChangeValue = (key, val, indx, error) => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows[indx][key] = val;

    if (error) _additionalRows[indx][`${key}_error`] = true;
    else _additionalRows[indx][`${key}_error`] = false;

    setAdditionalRows(_additionalRows);
  };

  const validateEmail = (email) => {
    //check if email address is valid
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = reg.test(email);
    if (!isValid) return false;

    //check if email already exists in email_specific
    let existing_emails = rowData.email_specific
      ? Object.keys(rowData.email_specific)
      : [];
    if (existing_emails.includes(email)) return false;

    //check if email already exists in additional_rows
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    for (let _row of _additionalRows) if (_row.email === email) return false;

    return true;
  };

  const validateData = () => {
    //check all the data
    for (let indx in additionalRows) {
      let row = additionalRows[indx];

      if (
        !(
          row.contact_name &&
          row.phone_number &&
          row.email &&
          !row.email_error &&
          row.job_description
        )
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <div style={{ padding: "25px" }}>
      <TableContainer component={Paper}>
        <Table size="small" className="sub_tab2">
          <TableHead
            style={{
              background: "#00000066",
              color: "whitesmoke",
            }}
          >
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Description</TableCell>
              <TableCell>Visit Count</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.email_specific ? (
              Object.values(rowData.email_specific).map((element, index) => {
                let step;
                if (element.companyProfile) {
                  if (Number(element.proposalGeneratedCount) > 0) {
                    step = "Proposal Generated";
                  } else if (element.quote_status) {
                    if (element.quote_status === "uwportal") {
                      step = "UW Portal";
                    } else if (element.quote_status === "proceed_uw") {
                      step = "Approved";
                    } else if (element.quote_status === "reject") {
                      step = "Rejected";
                    }
                  } else if (Number(element.quoteGeneratedCount) > 0) {
                    step = "Quote Generated";
                  } else if (element.formStage) {
                    if (element.formStage === "two") {
                      if (element.nonEligibilityList) {
                        step = "Not Eligible";
                      } else {
                        step = "UW Questions";
                      }
                    } else if (element.formStage === "one") {
                      step = "Company Profile";
                    }
                  } else if (
                    element.visitCount &&
                    Number(element.visitCount) > 0
                  ) {
                    step = "Landing Page";
                  } else {
                    step = "-";
                  }
                  if (
                    element.companyProfile.status &&
                    element.companyProfile.status.value
                  ) {
                    step =
                      statusPriorityMap[element.companyProfile.status.value] <
                      statusPriorityMap[step]
                        ? element.companyProfile.status.value
                        : step;
                  }
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {element.companyProfile &&
                        element.companyProfile.contact_name
                          ? element.companyProfile.contact_name.value
                          : ""}
                      </TableCell>
                      <TableCell scope="row">
                        {element?.companyProfile?.phoneNumber?.value?.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        ) ||
                          element?.companyProfile?.contact_number?.value?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          ) ||
                          ""}
                      </TableCell>
                      <TableCell scope="row">
                        {element?.companyProfile?.emailId?.value}
                      </TableCell>
                      <TableCell scope="row">
                        {element?.companyProfile?.job_title?.value}
                      </TableCell>
                      <TableCell scope="row">
                        {element.visitCount || 0}
                      </TableCell>
                      <TableCell scope="row">{step}</TableCell>
                    </TableRow>
                  );
                }
              })
            ) : (
              <TableRow key={0}>
                <TableCell scope="row">{rowData.contactName}</TableCell>
                <TableCell scope="row">
                  {rowData.phoneNumber
                    ? rowData.phoneNumber.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "-"}
                </TableCell>
                <TableCell scope="row">{rowData.userEmail}</TableCell>
                <TableCell scope="row">{""}</TableCell>
                <TableCell scope="row">{rowData.visitCount}</TableCell>
                <TableCell scope="row">{rowData.step || ""}</TableCell>
              </TableRow>
            )}
            {additionalRows?.map((ele, indx) => {
              return (
                <TableRow key={`add-rows-${indx}`}>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.contact_name
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "contact_name",
                            e.target.value?.trim(),
                            indx
                          )
                        }
                        defaultValue={ele.contact_name}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.phone_number
                    ) : (
                      <NumberFormat
                        defaultValue={ele.phone_number}
                        onChange={(e) =>
                          onChangeValue(
                            "phone_number",
                            e.target.value?.trim(),
                            indx
                          )
                        }
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.email
                    ) : (
                      <input
                        type="text"
                        onChange={(e) => {
                          let val = e.target.value.trim();
                          if (val && validateEmail(val)) {
                            onChangeValue("email", val, indx, false);
                          } else {
                            onChangeValue("email", val, indx, true);
                          }
                        }}
                        defaultValue={ele.email}
                        style={
                          ele.email_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.job_description
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "job_description",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.job_description}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.visit_count
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "visit_count",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.visit_count}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.status
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue("status", e.target.value.trim(), indx)
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.status}
                      ></input>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {showAddContact && (
              <TableRow key={1000}>
                <TableCell scope="row">
                  <button
                    className="btn btn-link"
                    onClick={(e) => onAddContact()}
                  >
                    <img src={require("../../images/plus4.png")} /> Add Contact
                  </button>
                </TableCell>
                {errMsg && (
                  <TableCell scope="row">
                    <div style={{ color: "red" }}>{errMsg}</div>
                  </TableCell>
                )}
              </TableRow>
            )}
            {showAddContact && (
              <TableRow key={1000}>
                <TableCell scope="row">
                  <button
                    className="btn btn-success"
                    onClick={(e) => {
                      if (validateData()) {
                        setErrMsg("");
                        saveContact(prim_key, additionalRows);
                      } else {
                        setErrMsg("Please, fill all details.");
                      }
                    }}
                  >
                    Save
                  </button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

class DashboardSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab4",
      dataToDisplay: {
        tab1: [],
        tab2: [],
        tab3: [],
        tab4: [],
        tab5: [],
        tab6: [],
      },
      dealMap: {},
      isLoading: {
        tab1: true,
        tab2: true,
        tab3: true,
        tab4: true,
        tab5: true,
        tab6: true,
      },
      globalAddContact: {},
      stateSpecificCheck: false,
      dashboradSpecific: false,
      loginSpecificCheck: false,
      dashboardView: [],
      salesDataLoading: true,
      dataLoading: true,
    };
  }

  handleAgentCommentHistory = (rowDataId, tabName, updatedComment) => {
    this.setState((prevstate) => {
      let updatedDisplayComment = JSON.parse(
        JSON.stringify(prevstate.dataToDisplay || {})
      );
      updatedDisplayComment[tabName]["salescommentsHistory"][
        rowDataId
      ] = updatedComment;
      return { dataToDisplay: updatedDisplayComment };
    });
  };

  handleSMCommentHistory = (rowDataId, tabName, updatedComment) => {
    this.setState((prevstate) => {
      let updatedDisplayComment = JSON.parse(
        JSON.stringify(prevstate.dataToDisplay || {})
      );
      updatedDisplayComment[tabName]["salesManagerCommentHistory"][
        rowDataId
      ] = updatedComment;
      return { dataToDisplay: updatedDisplayComment };
    });
  };

  async componentDidMount() {
    await this.fetchData();
    sessionStorage.clear();
  }

  saveContact = (key, add_rows) => {
    this.setState((prevState) => {
      let _globalAddContact = JSON.parse(
        JSON.stringify(prevState.globalAddContact)
      );

      axios
        .post(
          awsUrl + "/api/addContact",
          JSON.stringify({
            domain: key,
            rows: add_rows,
          })
        )
        .then((res) => {
          //api call to save email in email_specific and domain_mapping
          for (let row of add_rows) {
            if (!_globalAddContact[key]) _globalAddContact[key] = [];
            row.isSaved = true;
            _globalAddContact[key].push(row);
          }

          this.setState({ globalAddContact: _globalAddContact });
        })
        .catch((err) => console.log(err));
    });
  };

  updateDealMap = (key, value) => {
    let { dealMap } = this.state;
    dealMap[key] = value;
    this.setState({ dealMap });
  };

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  processSalesData = (data, sessionData) => {
    let {
      dashboradSpecific,
      stateSpecificCheck,
      loginSpecificCheck,
    } = this.state;
    let list = [];
    let wonList = [];
    let lostList = [];
    let _salescommentsHistory = {};
    let _salesManagerCommentHistory = {};
    let newData = [];
    let dashboradView = [];

    if (sessionData?.attributes?.["custom:dashboard"]) {
      dashboradView = sessionData?.attributes?.["custom:dashboard"].split(",");
    }

    if (
      dashboradSpecific &&
      stateSpecificCheck &&
      dashboradView.includes("salesforce")
    ) {
      if (sessionData) {
        let specifiedState = sessionData?.attributes?.["custom:State"].split(
          ","
        );
        for (let i = 0; i < data.length; i++) {
          let state = data[i]?.companyProfile?.state1?.value || "";
          if (state) {
            if (specifiedState.includes(state)) {
              newData.push(data[i]);
            }
          }
        }
      }
    } else if (dashboradSpecific && loginSpecificCheck) {
      if (sessionData) {
        let stateSpeficEmail = sessionData?.attributes?.email;
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].salespersonNameAttr === stateSpeficEmail ||
            data[i].salespersonName === stateSpeficEmail
          ) {
            newData.push(data[i]);
          }
        }
      }
    } else {
      newData = data;
    }

    for (let userDataRow in newData) {
      try {
        let userData = newData[userDataRow];
        let step;

        if (userData.formStage) {
          switch (userData.formStage) {
            case "two":
              step = "UW Questions";
              break;
            case "three":
              step = "Quote Generated";
              break;
            default:
              break;
          }
        }
        let currProspectDetails = {};
        if (userData.currProspect) {
          currProspectDetails = userData.currProspect;
        } else {
          currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
            companyProfileAddQuestions: userData.companyProfileAddQuestions,
            uwQues: userData.uwQues,
            requestedPricing: userData.requestedPricing,
          };
        }

        currProspectDetails = {
          ...currProspectDetails,
          brokerDetails: userData.brokerDetails,
        };

        let total_emp = 0,
          payroll = 0;

        if (userData.payrollData) {
          let childrenLoc = userData.payrollData;
          for (let locIndex in childrenLoc) {
            let loc = childrenLoc[locIndex];
            for (let ccIndex in loc.classCodesInfo) {
              let cc = loc.classCodesInfo[ccIndex];
              total_emp =
                total_emp +
                Number(cc?.ft?.value || 0) +
                Number(cc?.pt?.value || 0);
              let payrollVlue = cc?.payroll?.value?.replace(/[^\d.]/g, "") || 0;
              payroll = payroll + Number(payrollVlue || 0);
            }
          }
        }

        let rowData = {
          uploadedTimeStamp: userData.created_timestamp
            ? moment(userData.created_timestamp, "x").format("M/D/YYYY")
            : "-",
          visitDate:
            userData.updated_timestamp &&
            moment(Number(userData.updated_timestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A"),
          phoneNumber:
            userData.companyProfile && userData.companyProfile.phoneNumber
              ? userData.companyProfile.phoneNumber.value
              : "",
          effectiveDate:
            userData.companyProfile &&
            userData.companyProfile.effectiveDate &&
            userData.companyProfile.effectiveDate.value
              ? moment(userData.companyProfile.effectiveDate.value).format("x")
              : "",
          contactName:
            userData.companyProfile && userData.companyProfile.firstName
              ? userData.companyProfile.firstName.value
              : "",
          companyName:
            userData.companyProfile && userData.companyProfile.companyName
              ? userData.companyProfile.companyName.value
              : "",
          step: userData.isSubmitted ? "Submitted" : step,
          currProspectDetails,
          salesID: userData.id,
          formStage: userData.formStage,
          amtrustAccountDetails: userData.amtrustAccountDetails,
          amtrustAccountDetailsCopy: userData.amtrustAccountDetailsCopy,
          salespersonName:
            userData.salespersonNameAttr || userData.salespersonName,
          total_emp,
          state: userData?.companyProfile?.state1?.value || "",
          city: userData?.companyProfile?.cityName1?.value || "",
          quoteData: userData.quoteData,
          address: userData.address,
          payroll,
          visitCount: "0",
          latestCampaign: "-",
          sales: "true",
          salescomment: userData?.comment
            ? typeof userData?.comment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.comment,
                    commentTimestamp:
                      userData?.created_timestamp ||
                      userData?.updated_timestamp,
                  },
                ]
              : userData?.comment
            : [{ ...initialcomment }],
          sortKeyList: userData.sortKeyList,
          companyProfile: userData.companyProfile,
          isSalesforce: userData.isSalesforce,
          sfQuoteId: userData.sfQuoteId,
          uploadedDate: userData.uploadedDate,
          isPreUnderwritten: userData?.preUwData?.preUwStatus ? true : "na",
          source: userData.origin,
          quoteOwner: userData.sf_quote_owner,
          smcomment: userData?.smcomment
            ? typeof userData?.smcomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.smcomment,
                    commentTimestamp:
                      userData?.created_timestamp ||
                      userData?.updated_timestamp,
                  },
                ]
              : userData?.smcomment
            : [{ ...initialcomment }],
          admincomment: userData?.admincomment
            ? typeof userData?.admincomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.admincomment,
                    commentTimestamp:
                      userData?.created_timestamp ||
                      userData?.updated_timestamp,
                  },
                ]
              : userData?.admincomment
            : [{ ...initialcomment }],
          dealFlag:
            userData?.dealData?.status === "true"
              ? "closed"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealFlagFilter:
            userData?.dealData?.status === "true"
              ? "won"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealConfirmed: userData?.dealConfirmed,
          recordDetails: {
            salesId: userData.id,
          },
          latestQuoteKey: userData?.latestQuoteKey || "",
        };

        if (rowData.salesID) {
          _salescommentsHistory[rowData.salesID] = rowData.salescomment
            ? typeof rowData.salescomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: rowData.salescomment,
                    commentTimestamp:
                      rowData?.uploadedTimeStamp || rowData?.visitDate,
                  },
                ]
              : rowData.salescomment
            : [{ ...initialcomment }];
        }
        if (rowData.salesID) {
          _salesManagerCommentHistory[rowData.salesID] = rowData.smcomment
            ? typeof rowData.smcomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: rowData.smcomment,
                    commentTimestamp:
                      rowData?.uploadedTimeStamp || rowData?.visitDate,
                  },
                ]
              : rowData.smcomment
            : [{ ...initialcomment }];
        }

        createCommentForDownload(rowData, "comment");
        createCommentForDownload(rowData, "admincomment");
        createCommentForDownload(rowData, "salescomment");
        createCommentForDownload(rowData, "smcomment");

        if (rowData.dealConfirmed === "true") {
          if (rowData.dealFlag === "closed") {
            wonList.push(rowData);
          } else {
            lostList.push(rowData);
          }
        } else {
          list.push(rowData);
        }
      } catch (error) {
        console.log("error in processing: ", data[userDataRow].id);
      }
    }

    return {
      value: list,
      won: wonList,
      lost: lostList,
      domain_messageid_files: {},
      salescommentsHistory: _salescommentsHistory,
      salesManagerCommentHistory: _salesManagerCommentHistory,
    };
  };

  processEngagedData = (userDataList, sessionData) => {
    let { dashboradSpecific, stateSpecificCheck } = this.state;
    let rowDataList = [];
    let wonList = [];
    let lostList = [];
    let domain_messageid_files = {};
    let salescommentsHistory = {};
    let salesManagerCommentHistory = {};
    let rowData;
    let newUserDataList = [];

    if (dashboradSpecific && stateSpecificCheck) {
      if (sessionData) {
        let specifiedState = sessionData?.attributes?.["custom:State"].split(
          ","
        );
        for (let i = 0; i < userDataList.length; i++) {
          let state = userDataList[i].state;
          if (state) {
            if (specifiedState.includes(state)) {
              newUserDataList.push(userDataList[i]);
            }
          }
        }
      }
    } else {
      newUserDataList = userDataList;
    }

    for (let userData of newUserDataList) {
      try {
        let quote_status;
        let step;
        let excluded_status;
        let email_specific_status;
        let landing_email_status, landing_email_get_quote_clicked;

        // patch code to not display entries with the following campaign names
        let incorrectCampaignNames = new Set([
          "AEMheybtb",
          "TfgDhbgf",
          "BAybtb",
          "AEybtb",
        ]);
        if (incorrectCampaignNames.has(userData?.latestCampaign)) continue;

        for (let email in userData.email_specific) {
          let db_quote_status = userData.email_specific[email].quote_status;
          let db_excluded = userData.email_specific[email].nonEligibilityList;
          if (db_excluded && db_excluded.length > 0) {
            excluded_status = true;
          }

          if (
            userData.email_specific[email].companyProfile &&
            userData.email_specific[email].companyProfile.status &&
            userData.email_specific[email].companyProfile.status.value
          ) {
            let emailStatus =
              userData.email_specific[email].companyProfile.status.value;
            if (!email_specific_status) email_specific_status = emailStatus;
            else if (
              statusPriorityMap[emailStatus] <
              statusPriorityMap[email_specific_status]
            ) {
              email_specific_status = emailStatus;
            }
          }

          if (!landing_email_status) {
            landing_email_status = userData.email_specific[email].landingPage
              ? userData.email_specific[email].landingPage
              : null;
          }

          if (!landing_email_get_quote_clicked) {
            landing_email_get_quote_clicked = userData.email_specific[email]
              .homeGetQuote
              ? userData.email_specific[email].homeGetQuote
              : null;
          }

          if (
            (!quote_status && db_quote_status) ||
            (quote_status === "reject" && db_quote_status) ||
            db_quote_status === "proceed_uw"
          ) {
            quote_status = db_quote_status;
          }
        }

        if (userData.isSalespersonSubmitted) {
          step = "Submitted";
        } else if (userData.isUserSubmitted) {
          step = "User Submitted";
        } else if (userData.isSubmitted) {
          step = "Submitted";
        } else if (Number(userData.proposalGeneratedCount) > 0) {
          step = "Proposal Generated";
        } else if (quote_status) {
          if (quote_status === "uwportal") {
            step = "UW Portal";
          } else if (quote_status === "proceed_uw") {
            step = "Approved";
          } else if (quote_status === "reject") {
            step = "Rejected";
          }
        } else if (Number(userData.quoteGeneratedCount) > 0) {
          step = "Quote Generated";
        } else if (userData.formStage) {
          if (userData.formStage === "one") {
            step = "Company Profile";
            if (landing_email_get_quote_clicked) {
              step += " (Get Quote)";
            }
          } else {
            if (excluded_status) {
              step = "Not Eligible";
            } else {
              step = "UW Questions";
            }
          }
        } else if (userData.visitCount && Number(userData.visitCount) > 0) {
          step = "Landing Page";
        } else if (landing_email_status) {
          step =
            landing_email_status === "affinityLogo"
              ? "Affinity Logo Clicked"
              : "Fourth Logo Clicked";
        } else {
          step = "-";
        }
        if (email_specific_status) {
          step =
            statusPriorityMap[email_specific_status] < statusPriorityMap[step]
              ? email_specific_status
              : step;
        }

        let currProspectDetails = {
          companyProfile: userData.companyProfile,
          childrenLoc: userData.payrollData,
          emodStatesData: userData.emodStatesData,
        };

        // let total_emp = 0,
        //   payroll = 0;

        // if (userData.payrollData) {
        //   let childrenLoc = userData.payrollData;
        //   for (let locIndex in childrenLoc) {
        //     let loc = childrenLoc[locIndex];
        //     for (let ccIndex in loc.classCodesInfo) {
        //       let cc = loc.classCodesInfo[ccIndex];
        //       total_emp =
        //         total_emp +
        //         Number(cc?.ft?.value || 0) +
        //         Number(cc?.pt?.value || 0);
        //       let payrollVlue = cc?.payroll?.value?.replace(/\D/g, "") || 0;
        //       payroll = payroll + Number(payrollVlue || 0);
        //     }
        //   }
        // }

        rowData = {
          visitDate:
            userData.timestamp &&
            moment(Number(userData.timestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A"),
          userEmail: userData.email,
          quoteGeneratedCount: userData.quoteGeneratedCount || 0,
          proposalGeneratedCount: userData.proposalGeneratedCount || 0,
          uuid: userData.uuid,
          phoneNumber: userData.phoneNumber,
          payroll: userData.payroll,
          indicationCost: userData.indicationCost,
          emod:
            userData.emod && Number(userData.emod)
              ? parseFloat(userData.emod).toFixed(2)
              : "-",
          uwEmail: userData.uwEmail,
          effectiveDate: userData.effectiveDate
            ? moment(userData.effectiveDate).format("x")
            : "",
          contactName: userData.contactName || "",
          companyName:
            userData?.companyProfile?.company_name?.value ||
            userData?.companyProfile?.companyName?.value ||
            "",
          comment: userData?.comment
            ? typeof userData?.comment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.comment,
                    commentTimestamp:
                      userData?.timestamp || userData?.UploadedDate,
                  },
                ]
              : userData?.comment
            : [{ ...initialcomment }],
          salescomment: userData?.salescomment
            ? typeof userData?.salescomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.salescomment,
                    commentTimestamp:
                      userData?.timestamp || userData?.UploadedDate,
                  },
                ]
              : userData?.salescomment
            : [{ ...initialcomment }],
          admincomment: userData?.admincomment
            ? typeof userData?.admincomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.admincomment,
                    commentTimestamp:
                      userData?.timestamp || userData?.UploadedDate,
                  },
                ]
              : userData?.admincomment
            : [{ ...initialcomment }],
          smcomment: userData?.smcomment
            ? typeof userData?.smcomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: userData?.smcomment,
                    commentTimestamp:
                      userData?.timestamp || userData?.UploadedDate,
                  },
                ]
              : userData?.smcomment
            : [{ ...initialcomment }],
          domain: userData.domain,
          visitCount: userData.visitCount || 0,
          step,
          latestCampaign: userData.latestCampaign || "-",
          email_specific: userData.email_specific,
          messageID: userData.messageID,
          dashboardFiles: userData.dashboardFiles,
          uploadedTimeStamp: userData.UploadedDate
            ? moment(userData.UploadedDate, "x").format("M/D/YYYY")
            : userData.zywave_uploaded_date
            ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                "M/D/YYYY"
              )
            : "-",
          currProspectDetails,
          salesID: userData.id,
          formStage: userData.formStage,
          address: userData.address,
          quoteData: userData.quoteData,
          total_emp: userData.total_emp || 0,
          payroll: userData.payroll || 0,
          // sales: "true",
          state: userData?.state || "",
          city:
            userData?.companyProfile?.cityName1?.value ||
            userData?.companyProfile?.city1?.value ||
            userData?.companyProfile?.city?.value,
          salespersonName:
            userData.salespersonNameAttr || userData.salespersonName || "",
          uploadedDate: userData.uploadedDate,
          source: userData.source,
          isPreUnderwritten: userData?.preUwData?.preUwStatus ? true : false,
          dealFlag:
            userData?.dealData?.status === "true"
              ? "closed"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealFlagFilter:
            userData?.dealData?.status === "true"
              ? "won"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealConfirmed: userData?.dealConfirmed,
          recordDetails: {
            domain: userData.domain,
          },
        };

        createCommentForDownload(rowData, "comment");
        createCommentForDownload(rowData, "admincomment");
        createCommentForDownload(rowData, "salescomment");
        createCommentForDownload(rowData, "smcomment");

        if (rowData.email_specific) {
          Object.values(rowData.email_specific).map((email) => {
            if (
              email.companyProfile &&
              email.companyProfile.contact_number &&
              email.companyProfile.contact_number.value
            ) {
              email.companyProfile.contact_number.value = this.appendRemaining(
                email.companyProfile.contact_number.value.toString()
              );
            }
          });
        } else {
          if (rowData.phoneNumber)
            rowData.phoneNumber = this.appendRemaining(
              rowData.phoneNumber.toString()
            );
        }

        if (rowData.dealConfirmed) {
          if (rowData.dealFlag === "closed") {
            wonList.push(rowData);
          } else {
            lostList.push(rowData);
          }
        } else {
          rowDataList.push(rowData);
        }

        domain_messageid_files[rowData.domain] = {};
        if (rowData.messageID)
          domain_messageid_files[rowData.domain]["messageID"] =
            rowData.messageID;
        if (rowData.dashboardFiles)
          domain_messageid_files[rowData.domain]["dashboardFiles"] =
            rowData.dashboardFiles;

        if (userData.preUwFiles) {
          let _preUwFiles = [];
          userData.preUwFiles.forEach((ele) => {
            _preUwFiles.push(ele.key);
          });
          domain_messageid_files[userData.domain]["preUwFiles"] = _preUwFiles;
        }

        if (userData.fileListToStore) {
          let _fileListToStore = [];
          userData.fileListToStore.forEach((ele) => {
            _fileListToStore.push(ele.key);
          });
          domain_messageid_files[userData.domain][
            "fileListToStore"
          ] = _fileListToStore;
        }

        if (rowData.domain) {
          salescommentsHistory[rowData.domain] = rowData.salescomment
            ? typeof rowData.salescomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: rowData.salescomment,
                    commentTimestamp:
                      rowData?.visitDate || rowData?.uploadedTimeStamp,
                  },
                ]
              : rowData.salescomment
            : [{ ...initialcomment }];
        }

        if (rowData.domain) {
          salesManagerCommentHistory[rowData.domain] = rowData.smcomment
            ? typeof rowData.smcomment === "string"
              ? [
                  {
                    ...initialcomment,
                    comment: rowData.smcomment,
                    commentTimestamp:
                      rowData?.visitDate || rowData?.uploadedTimeStamp,
                  },
                ]
              : rowData.smcomment
            : [{ ...initialcomment }];
        }
      } catch (error) {
        console.log("error in processEngagedData: ", error);
      }
    }

    // console.log("returning list: ", rowDataList);

    return {
      value: rowDataList,
      won: wonList,
      lost: lostList,
      domain_messageid_files,
      salescommentsHistory,
      salesManagerCommentHistory,
    };
  };

  fetchData = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups) {
          if (groups.includes(process.env.REACT_APP_STATE_GROUP)) {
            this.setState({ stateSpecificCheck: true });
          }

          if (groups.includes(process.env.REACT_APP_DASHBOARD_GROUP)) {
            this.setState({ dashboradSpecific: true });
            this.setState({ selected: "tab4" });
          }

          if (groups.includes(process.env.REACT_APP_LOGIN_GROUP)) {
            this.setState({ loginSpecificCheck: true });
          }
        }
      }
    } catch (error) {
      console.log("ERRO:", error);
    }
    let requestBody = {
      first_time_loading: "true",
      sales_dashboard: "true",
      lastEvaluatedKey: "",
      lastEvaluatedKey1: "",
    };
    let lastKey1 = "";
    let lastKey2 = "";
    let indx = 0;
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      let dataToDisplay = {};
      requestBody.salesPersonEmail = sessionData.attributes.email;

      do {
        requestBody = {
          ...requestBody,
          lastEvaluatedKey: lastKey1,
          lastEvaluatedKey1: lastKey2,
        };

        if (indx === 1) delete requestBody["first_time_loading"];

        await getSalesDashboardDataAdmin(requestBody)
          .then((res) => {
            let apiData = res.data;
            if (indx === 0) {
              lastKey1 = res.data.old_key || "";
            } else {
              lastKey1 = res.data.old_key || undefined;
            }
            lastKey2 = res.data.engaged_lastEvaluatedKey || undefined;
            let {
              value: salesValue,
              won: salesWon,
              lost: salesLost,
              domain_messageid_files: sales_domain_messageid_files,
              salescommentsHistory,
              salesManagerCommentHistory,
            } = this.processSalesData(apiData["salesforce"], sessionData);
            let {
              value: hotschedulesValue,
              won: hotschedulesWon,
              lost: hotschedulesLost,
              domain_messageid_files: hotschedules_domain_messageid_files,
              salescommentsHistory: hotschedulesCommentsHistory,
              salesManagerCommentHistory: hotSchedulesSalesManagerCommentHistory,
            } = this.processEngagedData(apiData["hotschedules"], sessionData);
            let {
              value: affinityValue,
              won: affinityWon,
              lost: affinityLost,
              domain_messageid_files: affinity_domain_messageid_files,
              salescommentsHistory: affinityCommentsHistory,
              salesManagerCommentHistory: affinitySalesManagerCommentHistory,
            } = this.processEngagedData(apiData["affinity"], sessionData);

            // console.log("hotschedule", hotschedulesValue);
            // console.log("affinity", affinityValue);
            // console.log("Salesforce", salesValue);
            // console.log(dataToDisplay, "soujuuu");

            // dataToDisplay = {
            //   tab1: {
            //     value: [
            //       ...salesValue,
            //       ...(dataToDisplay?.["tab1"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...dataToDisplay?.["tab1"]?.["domain_messageid_files"],
            //       ...sales_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...dataToDisplay?.["tab1"]?.["salescommentsHistory"],
            //       ...salescommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...dataToDisplay?.["tab1"]?.["salesManagerCommentHistory"],
            //       ...salesManagerCommentHistory,
            //     },
            //   },
            //   tab2: {
            //     value: [
            //       ...hotschedulesValue,

            //       ...(dataToDisplay?.["tab2"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...dataToDisplay?.["tab2"]?.["domain_messageid_files"],
            //       ...hotschedules_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...dataToDisplay?.["tab2"]?.["salescommentsHistory"],
            //       ...hotschedulesCommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...dataToDisplay?.["tab2"]?.["salesManagerCommentHistory"],
            //       ...hotSchedulesSalesManagerCommentHistory,
            //     },
            //   },
            //   tab3: {
            //     value: [
            //       ...affinityValue,

            //       ...(dataToDisplay?.["tab3"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...dataToDisplay?.["tab3"]?.["domain_messageid_files"],
            //       ...affinity_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...dataToDisplay?.["tab3"]?.["salescommentsHistory"],
            //       ...affinityCommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...dataToDisplay?.["tab2"]?.["salesManagerCommentHistory"],
            //       ...affinitySalesManagerCommentHistory,
            //     },
            //   },
            //   tab4: {
            //     value: [
            //       ...affinityValue,
            //       ...hotschedulesValue,
            //       ...salesValue,
            //       ...salesWon,
            //       ...hotschedulesWon,
            //       ...affinityWon,
            //       ...salesLost,
            //       ...hotschedulesLost,
            //       ...affinityLost,
            //       ...(dataToDisplay?.["tab4"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...affinity_domain_messageid_files,
            //       ...hotschedules_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...salescommentsHistory,
            //       ...affinityCommentsHistory,
            //       ...hotschedulesCommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...salesManagerCommentHistory,
            //       ...hotSchedulesSalesManagerCommentHistory,
            //       ...affinitySalesManagerCommentHistory,
            //     },
            //   },
            //   tab5: {
            //     value: [
            //       ...salesWon,
            //       ...hotschedulesWon,
            //       ...affinityWon,
            //       ...(dataToDisplay?.["tab5"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...affinity_domain_messageid_files,
            //       ...hotschedules_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...salescommentsHistory,
            //       ...affinityCommentsHistory,
            //       ...hotschedulesCommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...salesManagerCommentHistory,
            //       ...hotSchedulesSalesManagerCommentHistory,
            //       ...affinitySalesManagerCommentHistory,
            //     },
            //   },
            //   tab6: {
            //     value: [
            //       ...salesLost,
            //       ...hotschedulesLost,
            //       ...affinityLost,
            //       ...(dataToDisplay?.["tab6"]?.["value"] ?? []),
            //     ],
            //     domain_messageid_files: {
            //       ...affinity_domain_messageid_files,
            //       ...hotschedules_domain_messageid_files,
            //     },
            //     salescommentsHistory: {
            //       ...salescommentsHistory,
            //       ...affinityCommentsHistory,
            //       ...hotschedulesCommentsHistory,
            //     },
            //     salesManagerCommentHistory: {
            //       ...salesManagerCommentHistory,
            //       ...hotSchedulesSalesManagerCommentHistory,
            //       ...affinitySalesManagerCommentHistory,
            //     },
            //   },
            // };

            if (this.state.dashboradSpecific) {
              let hotsheduledata = [];
              let affinitydata = [];
              let wonData = [];
              let lostData = [];
              let dashboardView = sessionData?.attributes?.[
                "custom:dashboard"
              ].split(",");
              let tabMapCopy = {
                tab4: { value: "All" },
                tab1: { value: "Salesforce" },
              };
              if (dashboardView.includes("affinity")) {
                affinitydata = [...affinityValue];
                tabMapCopy["tab3"] = { value: "Affinity" };
                dataToDisplay["tab3"].value = affinitydata;
              } else {
                dataToDisplay["tab3"].value = [];
                affinitydata = [];
              }

              if (dashboardView.includes("hotschedules")) {
                hotsheduledata = [...hotschedulesValue];
                tabMapCopy["tab2"] = {
                  value:
                    process.env.REACT_APP_MODE === "beta"
                      ? "Client CRM"
                      : "HotSchedules",
                };
                dataToDisplay["tab2"].value = hotsheduledata;
              } else {
                dataToDisplay["tab2"].value = [];
                hotsheduledata = [];
              }

              if (dashboardView.includes("won")) {
                wonData = [...salesWon, ...affinityWon, ...hotschedulesWon];
                tabMapCopy["tab5"] = { value: "Won" };
                dataToDisplay["tab5"].value = wonData;
              }

              if (dashboardView.includes("lost")) {
                lostData = [...salesLost, ...affinityLost, ...hotschedulesLost];
                tabMapCopy["tab6"] = { value: "Lost" };
                dataToDisplay["tab6"].value = lostData;
              }

              dataToDisplay["tab4"] = {
                value: [
                  ...affinitydata,
                  ...hotsheduledata,
                  ...salesValue,
                  ...wonData,
                  ...lostData,
                ],
                domain_messageid_files: {
                  ...affinity_domain_messageid_files,
                  ...hotschedules_domain_messageid_files,
                },
                salescommentsHistory: {
                  ...salescommentsHistory,
                  ...affinityCommentsHistory,
                  ...hotschedulesCommentsHistory,
                },
                salesManagerCommentHistory: {
                  ...salesManagerCommentHistory,
                  ...hotSchedulesSalesManagerCommentHistory,
                  ...affinitySalesManagerCommentHistory,
                },
              };

              this.setState({ dashboardView: tabMapCopy });
            }

            this.setState((prevState) => {
              return {
                dataToDisplay: {
                  tab1: {
                    value: [
                      ...salesValue,
                      ...(prevState.dataToDisplay?.["tab1"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...prevState.dataToDisplay?.["tab1"]?.[
                        "domain_messageid_files"
                      ],
                      ...sales_domain_messageid_files,
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab1"]?.[
                        "salescommentsHistory"
                      ],
                      ...salescommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab1"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...salesManagerCommentHistory,
                    },
                  },
                  tab2: {
                    value: [
                      ...hotschedulesValue,
                      ...(prevState.dataToDisplay?.["tab2"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...prevState.dataToDisplay?.["tab2"]?.[
                        "domain_messageid_files"
                      ],
                      ...hotschedules_domain_messageid_files,
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab2"]?.[
                        "salescommentsHistory"
                      ],
                      ...hotschedulesCommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab2"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...hotSchedulesSalesManagerCommentHistory,
                    },
                  },
                  tab3: {
                    value: [
                      ...affinityValue,
                      ...(prevState.dataToDisplay?.["tab3"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...prevState.dataToDisplay?.["tab3"]?.[
                        "domain_messageid_files"
                      ],
                      ...affinity_domain_messageid_files,
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab3"]?.[
                        "salescommentsHistory"
                      ],
                      ...affinityCommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab3"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...affinitySalesManagerCommentHistory,
                    },
                  },
                  tab4: {
                    value: [
                      ...affinityValue,
                      ...hotschedulesValue,
                      ...salesValue,
                      ...salesWon,
                      ...hotschedulesWon,
                      ...affinityWon,
                      ...salesLost,
                      ...hotschedulesLost,
                      ...affinityLost,
                      ...(prevState.dataToDisplay?.["tab4"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...affinity_domain_messageid_files,
                      ...hotschedules_domain_messageid_files,
                      ...prevState.dataToDisplay?.["tab4"]?.[
                        "domain_messageid_files"
                      ],
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab4"]?.[
                        "salescommentsHistory"
                      ],
                      ...salescommentsHistory,
                      ...affinityCommentsHistory,
                      ...hotschedulesCommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab4"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...salesManagerCommentHistory,
                      ...hotSchedulesSalesManagerCommentHistory,
                      ...affinitySalesManagerCommentHistory,
                    },
                  },
                  tab5: {
                    value: [
                      ...salesWon,
                      ...hotschedulesWon,
                      ...affinityWon,
                      ...(prevState.dataToDisplay?.["tab5"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...affinity_domain_messageid_files,
                      ...hotschedules_domain_messageid_files,
                      ...prevState.dataToDisplay?.["tab5"]?.[
                        "domain_messageid_files"
                      ],
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab5"]?.[
                        "salescommentsHistory"
                      ],
                      ...salescommentsHistory,
                      ...affinityCommentsHistory,
                      ...hotschedulesCommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab5"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...salesManagerCommentHistory,
                      ...hotSchedulesSalesManagerCommentHistory,
                      ...affinitySalesManagerCommentHistory,
                    },
                  },
                  tab6: {
                    value: [
                      ...salesLost,
                      ...hotschedulesLost,
                      ...affinityLost,
                      ...(prevState.dataToDisplay?.["tab6"]?.["value"] ?? []),
                    ],
                    domain_messageid_files: {
                      ...affinity_domain_messageid_files,
                      ...hotschedules_domain_messageid_files,
                      ...prevState.dataToDisplay?.["tab6"]?.[
                        "domain_messageid_files"
                      ],
                    },
                    salescommentsHistory: {
                      ...prevState.dataToDisplay?.["tab6"]?.[
                        "salescommentsHistory"
                      ],
                      ...salescommentsHistory,
                      ...affinityCommentsHistory,
                      ...hotschedulesCommentsHistory,
                    },
                    salesManagerCommentHistory: {
                      ...prevState.dataToDisplay?.["tab6"]?.[
                        "salesManagerCommentHistory"
                      ],
                      ...salesManagerCommentHistory,
                      ...hotSchedulesSalesManagerCommentHistory,
                      ...affinitySalesManagerCommentHistory,
                    },
                  },
                },
              };
            });
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            let tabs = ["tabl", "tab2", "tab3", "tab4", "tab5", "tab6"];
            let isLoading = tabs.reduce((intial, tab) => {
              return { ...intial, [tab]: false };
            }, {});

            this.setState({ isLoading });
            if (!lastKey1 && indx > 1) {
              this.setState({ salesDataLoading: false });
            }
            if (!lastKey2) {
              this.setState({ dataLoading: false });
            }
          });
        indx += 1;
      } while (indx === 1 || lastKey1 || lastKey2);
    }
  };

  render() {
    let {
      selected,
      dataToDisplay,
      dealMap,
      dashboradSpecific,
      dashboardView,
      globalAddContact,
    } = this.state;
    const tabsMap = {
      tab4: { value: "All" },
      tab1: { value: "Salesforce" },
      tab2: {
        value:
          process.env.REACT_APP_MODE === "beta" ? "Client CRM" : "HotSchedules",
      },
      tab3: { value: "Affinity" },
      tab5: { value: "Won" },
      tab6: { value: "Lost" },
    };

    return (
      <div className="dashboard-container">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header />
        </div>
        <div className="tabs p-4">
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px" }}
          >
            <select
              className="select-type-salesforce-dashboard"
              onChange={(e) => this.setSelected(e.target.value)}
            >
              {dashboradSpecific
                ? Object.keys(dashboardView).map((tab, index) => {
                    return (
                      <option key={index} value={tab}>
                        {dashboardView[tab].value}
                      </option>
                    );
                  })
                : Object.keys(tabsMap).map((tab, index) => {
                    return (
                      <option key={index} value={tab}>
                        {tabsMap[tab].value}
                      </option>
                    );
                  })}
            </select>
            {console.log(
              selected,
              this.state.salesDataLoading,
              this.state.dataLoading,
              "soujuuu"
            )}
            {selected === "tab1" && this.state.salesDataLoading ? (
              <div
                style={{ margin: "10px" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
            ) : (
              ""
            )}
            {(selected === "tab2" || selected === "tab3") &&
            this.state.dataLoading ? (
              <div
                style={{ margin: "10px" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
            ) : (
              ""
            )}
            {selected === "tab4" &&
            (this.state.salesDataLoading || this.state.dataLoading) ? (
              <div
                style={{ margin: "10px" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
            ) : (
              ""
            )}
            {(selected === "tab6" || selected === "tab5") && <div></div>}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {
                <SalesDashboard
                  tabName={tab}
                  selected={selected}
                  list={dataToDisplay[tab].value}
                  domain_messageid_files={
                    dataToDisplay[tab].domain_messageid_files
                  }
                  salescommentsHistory={dataToDisplay[tab].salescommentsHistory}
                  salesManagerCommentHistory={
                    dataToDisplay[tab].salesManagerCommentHistory
                  }
                  handleAgentCommentHistory={this.handleAgentCommentHistory}
                  handleSMCommentHistory={this.handleSMCommentHistory}
                  dealMap={dealMap}
                  updateDealMap={this.updateDealMap}
                  isLoading={this.state.isLoading[tab]}
                  globalAddContact={globalAddContact}
                  saveContact={this.saveContact}
                />
              }
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

let initialcomment = {
  comment: "",
  commentTimestamp: 0,
  email: "",
};

class SalesDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      rowDataList: [],
      domain_messageid_files: {},
      salescommentsHistory: {},
      style: {},
      salesPersonLogin: false,
      salesManagerLogin: false,
      uploadDateFlag: false,
      scrollLeft: false,
      scrollRight: true,
      openModal: false,
      modalBody: [],
      stateSpecificCheck: false,
    };

    this.handleDisplayHide = this.handleDisplayHide.bind();
  }

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;

    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups) {
          if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
            this.setState({ salesManagerLogin: true });
          }
          if (groups.includes(process.env.REACT_APP_DATA_TEAM)) {
            this.setState({ uploadDateFlag: true });
          } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
            this.setState({ salesPersonLogin: true });
          } else {
            this.setState({ uploadDateFlag: false });
          }

          if (groups.includes(process.env.REACT_APP_STATE_GROUP)) {
            this.setState({ stateSpecificCheck: true });
          }
        }
      }
    } catch (error) {}
    window.addEventListener("load", this.handleDisplayHide);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
    if (
      this.props.selected !== prevProps.selected ||
      this.props?.list?.length !== prevProps?.list?.length
    ) {
      this.setState({ rowDataList: this.props.list });
    }
    let len1 = Object.keys(this.props.domain_messageid_files || {}).length,
      len2 = Object.keys(this.state.domain_messageid_files || {}).length;
    if (
      len1 &&
      len2 &&
      (len1 !== len2 ||
        JSON.stringify(this.props.domain_messageid_files) !==
          JSON.stringify(this.state.domain_messageid_files))
    ) {
      this.setState({
        domain_messageid_files: this.props.domain_messageid_files,
      });
    }
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };
  updateEngagedComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.salescomment) {
      requestBody.salescomment = data.salescomment;
    }
    requestBody.tableName = "tab1";

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateAdminComments = (e, rowData) => {
    let { rowDataList, program, source } = JSON.parse(
      JSON.stringify(this.state)
    );
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = rowDataList[index].domain;
    requestBody.admincomment = e.target.value;
    requestBody.tableName = "tab1";

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateSalesComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.smcomment) {
      requestBody.smcomment = data.smcomment;
    }
    if (data.comment) {
      requestBody.comment = data.comment;
    }
    requestBody.tableName = "tab3";
    requestBody.sortKeyList = data.sortKeyList;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateSalesManagerComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.smcomment) {
      requestBody.smcomment = data.smcomment;
    }
    if (data.comment) {
      requestBody.comment = data.comment;
    }

    requestBody.tableName = "tab1";
    requestBody.sortKeyList = data.sortKeyList;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  onClickCompanyName = (rowData) => {
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    if (rowData.dealConfirmed === "true") {
      sessionStorage.setItem("hideButtons", "true");
    }
    if (rowData.salesID) {
      sessionStorage.setItem("salesID", rowData.salesID);
    }
    if (rowData.formStage) {
      sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.amtrustAccountDetailsCopy) {
      sessionStorage.setItem(
        "amtrustAccountDetailsCopy",
        JSON.stringify(rowData.amtrustAccountDetailsCopy)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    if (rowData.isSalesforce) {
      sessionStorage.setItem("isSalesforce", true);
      sessionStorage.setItem("sfQuoteId", rowData.sfQuoteId);
    }

    if (rowData.latestQuoteKey) {
      sessionStorage.setItem("latestQuoteKey", rowData.latestQuoteKey);
    }
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
    let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  copySuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  source = (Source) => {
    if (Source == "hotschedules") {
      return "HotSchedules";
    } else if (Source == "affinity") {
      return "Affinity";
    } else {
      return Source;
    }
  };
  copyError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  copyId = (id) => {
    try {
      navigator.clipboard.writeText(id);
      this.copySuccess("Copied");
    } catch (error) {
      console.log(error);
      this.copyError("Error while copying");
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }

    // if (term[0] == ">" && term[1] == "=") {
    //   return firstTwoCharacter <= rowData[customFieldName];
    // } else if (term[0] == "<" && term[1] == "=") {
    //   return firstTwoCharacter >= rowData[customFieldName];
    // } else if (term[0] == ">") {
    //   return firstCharacter < rowData[customFieldName];
    // } else if (term[0] == "<") {
    //   return firstCharacter > rowData[customFieldName];
    // } else if (term[0] == "=") {
    //   return firstCharacter == rowData[customFieldName];
    // } else {
    //   return rowData[customFieldName]?.toString()?.startsWith(term);
    // }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  tab1DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab1">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(this.formatInCamel(
                      rowData?.companyProfile?.firstName?.value
                    ) || "") +
                      " " +
                      (this.formatInCamel(
                        rowData?.companyProfile?.lastName?.value
                      ) || "") || "-"}
                  </TableCell>
                  <TableCell scope="row">
                    {rowData?.companyProfile?.phoneNumber?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) || "-"}
                  </TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"0"}</TableCell>
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  convertToTimestamp = (submittedDate) => {
    let timestamp;
    if (moment(submittedDate, "M/D/YYYY - h:mm A", true)?.isValid()) {
      timestamp = moment(submittedDate, "M/D/YYYY - h:mm A")?.valueOf();
    } else if (moment(submittedDate)?.isValid()) {
      timestamp = moment(submittedDate)?.valueOf();
    } else {
      console.log("Invalid timestamp format");
    }
    return timestamp;
  };

  handleCommentHistory = (rowdata, rowkey, name) => {
    rowdata.forEach((item) => {
      let submittedDate;
      if (item.visitDate) {
        submittedDate = item.visitDate;
      } else {
        submittedDate = item.uploadedTimeStamp;
      }
      let timestamp = this.convertToTimestamp(submittedDate);
      if (item.salesID || item.domain === rowkey) {
        if (typeof item[name]?.[item[name]?.length - 1] === "string") {
          let updatedComment = item[name]?.slice(0, -1); // Copy all comments except the last one
          updatedComment.push({
            // Push the updated last comment
            ...initialcomment,
            comment: item[name]?.[item[name]?.length - 1],
            commentTimestamp: timestamp,
          });
          this.setState({ modalBody: updatedComment });
        } else {
          this.setState({ modalBody: item[name] });
        }
      }
    });
    this.setState({ openModal: true });
  };

  setCommentBoxColor = (comments) => {
    if (comments?.[0]?.comment === "") {
      if (comments?.length > 1) {
        return "#FFE7C1";
      }
    } else if (comments?.length > 0) {
      return "#FFE7C1";
    }
  };

  setTableData = () => {
    let {
      rowDataList,
      isLoading,
      salesPersonLogin,
      salesManagerLogin,
      uploadDateFlag,
      scrollLeft,
      scrollRight,
    } = JSON.parse(JSON.stringify(this.state));

    let {
      salesManagerCommentHistory,
      salescommentsHistory,
      domain_messageid_files,
      tabName,
      selected,
      list,
      updateDealMap,
      dealMap,
      globalAddContact,
      saveContact,
    } = this.props;

    let columns = [
      {
        title: "Visit Date (ET)",
        field: "visitDate",
        defaultSort: "desc",
        filtering: true,
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),
        customSort: (a, b) => this.dateSort(a, b, "visitDate"),

        render: (rowData) => {
          if (rowData.visitDate) {
            let date = rowData.visitDate.split("-")[0];
            let time = rowData.visitDate.split("-")[1];
            return (
              <span>
                {date}

                {/* {"&"}
                {time} */}
              </span>
            );
          } else {
            return "-";
          }
        },
        cellStyle: {
          wordBreak: "break-all",
          padding: "3px 0px 3px 3px",
          width: "100px",
          minWidth: "100px",
        },
        headerStyle: {
          padding: "5px 0px 5px 4px",
          width: "100px",
          minWidth: "100px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
      },
      {
        title: "Company Name",
        field: "companyName",

        cellStyle: {
          padding: "6px 5px 6px 10px",
          minWidth: "12rem",
          wordBreak: "break-word",
        },
        headerStyle: {
          padding: "3px 0px 3px 10px",
          minWidth: "12rem",
        },
        filterCellStyle: {
          padding: "2px 0px 2px 4px",
        },
        filtering: true,
        filterPlaceholder: "Filter",
        render: (rowData) => {
          if (rowData.sales === "true") {
            //copy here
            return (
              <span className="d-flex">
                <img
                  style={{ width: "12px", cursor: "pointer" }}
                  className="mr-2"
                  src={clipboard}
                  title={rowData.salesID}
                  onClick={() => {
                    this.copyId(rowData.salesID);
                  }}
                />
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              </span>
            );
          } else {
            let random_user = Object.keys(rowData.email_specific)[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("landingEmail", random_user);
                    // sessionStorage.setItem("domain", rowData.domain);
                    sessionStorage.setItem("salespersonMailCampaign", "true");
                  }}
                >
                  <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                    {this.camelCase(rowData.companyName)}
                  </Link>
                </span>
              </Clamp>
            );
          }
        },
      },
      {
        title: "Payroll",
        field: "payroll",
        filterPlaceholder: "Filter",
        filtering: true,

        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterfunction(term, rowData, field),

        render: (rowData) => numeral(rowData.payroll).format("$0,0"),
        cellStyle: {
          padding: "6px 10px 6px 10px",
          minWidth: "8rem",
          wordBreak: "break-all",
          textAlign: "right",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "7rem",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 0px",
        },
      },
      {
        title: "State",
        field: "state",
        filterPlaceholder: "Filter",
        filtering: true,
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),

        render: (rowData) => {
          return rowData.state.toUpperCase();
        },
        cellStyle: {
          textTransform: "uppercase",
          padding: "6px 0 6px 10px",
          minWidth: "5rem",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "5rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
      },
      {
        title: "City",
        field: "city",
        filterPlaceholder: "Filter",
        filtering: true,
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),
        render: (rowData) => this.camelCase(rowData.city),
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "8rem",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 7px 2px 1px",
        },
      },
      {
        title: "# Emp",
        field: "total_emp",
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterfunction(term, rowData, field),

        filtering: true,
        cellStyle: {
          padding: "6px 0",
          minWidth: "5rem",
          paddingLeft: "20px",
        },
        headerStyle: {
          padding: "6px 0 6px 18px",
          whiteSpace: "nowrap",
          minWidth: "5rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 15px",
        },
      },
      {
        title: "Status",
        field: "step",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: (e, rowData) => {
          let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
          if (rowData.step === "Submitted") {
            style.backgroundColor = "#5ADA38";
          } else if (rowData.step === "User Submitted") {
            style.backgroundColor = "#5ADA38";
          } else if (rowData.step === "Proposal Generated") {
            style.backgroundColor = "#5ADA38";
          } else if (rowData.step === "UW Portal") {
            style.backgroundColor = "#ffd737ac";
          } else if (rowData.step === "Approved") {
            style.backgroundColor = "#87FA4D";
          } else if (rowData.step === "Rejected") {
            style.backgroundColor = "#fa6d3e";
          } else if (rowData.step === "Quote Generated") {
            style.backgroundColor = "#87FA20";
          } else if (rowData.step === "UW Questions") {
            style.backgroundColor = "#FFD737";
          } else if (Number(rowData.visitCount) > 0) {
            style.backgroundColor = "#FFD737";
          } else {
            style.backgroundColor = "#FFF";
          }
          return style;
        },
        headerStyle: {
          padding: "6px 0 6px 13px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 5px 2px 5px",
        },
      },
    ];
    if (selected === "tab2" || selected === "tab3") {
      if (uploadDateFlag) {
        columns.unshift({
          title: "Upload Date",
          field: "uploadedTimeStamp",
          filtering: true,
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
          ),
        });
      }
    }
    if (selected === "tab1" || selected === "tab4") {
      columns.unshift({
        title: "Upload Date",
        field: "uploadedTimeStamp",
        filterPlaceholder: "Filter",
        filtering: true,
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),

        customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
        cellStyle: {
          wordBreak: "break-all",
          padding: "3px 0",
          minWidth: "6rem",
        },
        headerStyle: {
          padding: "3px 0px 3px 4px",
          minWidth: "6rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
        render: (rowData) => (
          <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
        ),
      });
    }
    // if (selected === "tab1" || selected === "tab4") {
    if (salesManagerLogin) {
      columns.push({
        title: "Comments",
        field: "smcomment_download",
        filterPlaceholder: "Filter",
        filtering: true,
        render: (rowData) => {
          const callbackFunction = (data, rowData) => {
            if (rowData.sales === "true") {
              this.updateSalesComment(data);
            } else {
              this.updateSalesManagerComment(data);
            }
          };

          return (
            <div className="d-flex">
              <TextField
                key={`${rowData?.salesID || rowData?.domain}_comment`}
                value={
                  typeof salesManagerCommentHistory?.[rowData?.salesID]?.[0] ===
                    "object" ||
                  typeof salesManagerCommentHistory?.[rowData?.domain]?.[0] ===
                    "object"
                    ? salesManagerCommentHistory?.[rowData?.salesID]?.[0]
                        ?.comment ||
                      salesManagerCommentHistory?.[rowData?.domain]?.[0]
                        ?.comment
                    : salesManagerCommentHistory?.[rowData?.salesID]?.[0] ||
                      salesManagerCommentHistory?.[rowData?.domain]?.[0]
                }
                callback={callbackFunction}
                rowData={rowData}
                rowDataID={rowData.salesID || rowData.domain}
                rowDataName={rowData.salesID ? "id" : "domain"}
                rowDataSortKeyList={rowData.sortKeyList}
                rowDataComments={"smcomment"}
                comment={
                  salesManagerCommentHistory?.[rowData?.salesID] ||
                  salesManagerCommentHistory?.[rowData?.domain]
                }
                handleAgentCommentHistory={this.props.handleSMCommentHistory}
              />
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={() =>
                    this.handleAgentCommentsButtonClick(
                      salesManagerCommentHistory?.[rowData?.salesID] ||
                        salesManagerCommentHistory?.[rowData?.domain],
                      rowData.uploadedTimeStamp
                    )
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          padding: "6px 0 6px 18px",
          minWidth: "230px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 10px",
        },
      });
    } else {
      columns.push({
        title: "Comments",
        field: "salescomment_download",
        filterPlaceholder: "Filter",
        filtering: true,

        render: (rowData) => {
          const callbackFunction = (data, rowData) => {
            if (rowData.sales === "true") {
              this.updateSalesComment(data);
            } else {
              this.updateEngagedComment(data);
            }
          };
          return (
            <div className="d-flex">
              <TextField
                key={`${rowData?.salesID || rowData?.domain}_comment`}
                value={
                  typeof salescommentsHistory?.[rowData?.salesID]?.[0] ===
                    "object" ||
                  typeof salescommentsHistory?.[rowData?.domain]?.[0] ===
                    "object"
                    ? salescommentsHistory?.[rowData?.salesID]?.[0]?.comment ||
                      salescommentsHistory?.[rowData?.domain]?.[0]?.comment
                    : salescommentsHistory?.[rowData?.salesID]?.[0] ||
                      salescommentsHistory?.[rowData?.domain]?.[0]
                }
                callback={callbackFunction}
                rowData={rowData}
                rowDataID={rowData.salesID || rowData.domain}
                rowDataName={rowData.salesID ? "id" : "domain"}
                rowDataSortKeyList={rowData.sortKeyList}
                rowDataComments={rowData.salesID ? "comment" : "salescomment"}
                comment={
                  salescommentsHistory?.[rowData?.salesID] ||
                  salescommentsHistory?.[rowData?.domain]
                }
                selected={selected}
                handleAgentCommentHistory={this.props.handleAgentCommentHistory}
              />
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={() =>
                    this.handleAgentCommentsButtonClick(
                      salescommentsHistory?.[rowData?.salesID] ||
                        salescommentsHistory?.[rowData?.domain],
                      rowData.uploadedTimeStamp
                    )
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          padding: "6px 0 6px 18px",
          minWidth: "230px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 10px",
        },
      });
    }

    // columns.push({
    //   title: "Comments",
    //   field: "salescomment",
    //   render: (rowData) => {
    //     return (
    //       <textarea
    //         defaultValue={rowData.salescomment || ""}
    //         onBlur={(e) => {
    //           if (salesManagerLogin && rowData.sales !== "true") return;
    //           if (rowData.sales !== "true")
    //             this.updateEngagedComment(e, rowData);
    //           else this.updateSalesComment(e, rowData);
    //         }}
    //         rows="2"
    //         disabled={salesManagerLogin && rowData.sales !== "true" ? true : false}
    //         style={{ width: "100%" }}
    //       ></textarea>
    //     );
    //   },
    //   cellStyle: {
    //     padding: "6px 15px 6px 10px",
    //     minWidth: "160px",
    //   },
    // });

    // }

    // if (selected === "tab2" || selected === "tab3") {

    //   columns.push({
    //     title: "Comments",
    //     field: "salescomment",
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.salescomment || ""}
    //           onBlur={(e) => {
    //             this.updateEngagedComment(e, rowData);
    //           }}
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "6px 15px 6px 10px",
    //       minWidth: "160px",
    //     },
    //   });

    // }

    columns.push(
      {
        title: "Visit Count",
        field: "visitCount",
        filterPlaceholder: "Filter",

        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterfunction(term, rowData, field),
        filtering: true,
        cellStyle: {
          padding: "6px 0 6px 23px",
          minWidth: "5rem",
        },
        headerStyle: {
          padding: "6px 0px 6px 15px",
          minWidth: "5rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 10px",
        },
      },
      {
        title: (
          <img
            src={require("../../images/file-download-circle-green.svg")}
            alt=""
            style={{
              width: "40px",
              // filter: "grayscale(100%)  opacity(0.4)",
            }}
          />
        ),
        disableSortBy: true,
        filtering: true,
        cellStyle: {
          minWidth: "30px",
          width: "30px",
          maxWidth: "30px",
          padding: "0 0",
        },
        headerStyle: {
          paddingLeft: "15px",
        },
        render: (rowData) => {
          if (rowData.sales === "true") {
            return (
              <button className="btn pt-0" type="button">
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={{
                    width: "40px",
                    filter: "grayscale(100%)  opacity(0.4)",
                  }}
                />
              </button>
            );
          }

          let title = "";

          if (
            domain_messageid_files &&
            domain_messageid_files[rowData.domain]?.dashboardFiles?.length
          ) {
            for (let file of domain_messageid_files[rowData.domain]
              .dashboardFiles) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }
          if (domain_messageid_files[rowData.domain]?.fileListToStore?.length) {
            for (let file of domain_messageid_files[rowData.domain]
              .fileListToStore) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }
          if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
            for (let file of domain_messageid_files[rowData.domain]
              .preUwFiles) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }

          let totalFiles =
            (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
              0) +
            (domain_messageid_files[rowData.domain]?.preUwFiles?.length || 0) +
            (domain_messageid_files[rowData.domain]?.fileListToStore?.length ||
              0);

          return (
            <button
              className="btn pt-0"
              type="button"
              title={parse(title)}
              data-html="true"
              onClick={() => {
                this.setState({
                  showModel: true,
                  modalTitle: <h1>{"Download"}</h1>,
                  modalContent: (
                    <>
                      <DownloadDashboardFiles
                        dashboardFiles={
                          domain_messageid_files[rowData.domain]
                            .dashboardFiles || []
                        }
                        preUwFiles={
                          domain_messageid_files[rowData.domain].preUwFiles ||
                          []
                        }
                        fileListToStore={
                          domain_messageid_files[rowData.domain]
                            .fileListToStore || []
                        }
                        domainName={rowData.domain}
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                      />
                    </>
                  ),
                });
              }}
            >
              <img
                src={require("../../images/file-download-circle-green.svg")}
                alt=""
                style={
                  totalFiles > 0
                    ? { width: "40px", filter: "grayscale(0)" }
                    : { width: "40px", filter: "grayscale(100%)  opacity(0.4)" }
                }
              />
            </button>
          );
        },
      },
      {
        title: "Campaign Name",
        field: "latestCampaign",
        filterPlaceholder: "Filter",
        filtering: true,
        cellStyle: {
          padding: "6px 15px 6px 10px",
          wordBreak: "break-word",
          minWidth: "180px",
        },
        headerStyle: {
          minWidth: "180px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 3px",
        },
        render: (rowData) => (
          <Clamp lines={2}>
            {this.camelCase(rowData?.latestCampaign) || ""}
          </Clamp>
        ),
      }
    );

    columns.push({
      title: "Last Modified By",
      field: "salespersonName",
      filterPlaceholder: "Filter",
      filtering: true,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        wordBreak: "break-word",
        minWidth: "180px",
      },
      headerStyle: {
        minWidth: "180px",
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 3px",
      },
      render: (rowData) => {
        let name = "";
        let emailName = emailNameMap[rowData.salespersonName]
          ? emailNameMap[rowData.salespersonName]
          : rowData.salespersonName;
        try {
          if (emailName.length > 15) {
            name = `${emailName.slice(0, 15)}...`;
          } else {
            name = emailName;
          }
        } catch (error) {}
        return name;
      },
    });

    if (selected === "tab4" || selected === "tab1") {
      columns.push({
        title: "Quote Owner",
        field: "quoteOwner",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 15px 6px 10px",
          wordBreak: "break-word",
          minWidth: "180px",
        },
        headerStyle: {
          minWidth: "180px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 3px",
        },
        render: (rowData) => {
          let name = "";
          try {
            if (rowData?.quoteOwner?.length > 15) {
              name = `${rowData?.quoteOwner.slice(0, 15)}...`;
            } else {
              name = rowData.quoteOwner;
            }
          } catch (error) {}
          return <Clamp lines={2}>{name || ""}</Clamp>;
        },
      });
    }

    columns.push({
      title: "Source",
      field: "source",
      filtering: true,
      filterPlaceholder: "Filter",
      // customSort: (a, b) => a.Source.length - b.Source.length,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "170px",
      },
      headerStyle: {
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 5px",
      },
      render: (rowData) => (
        <Clamp lines={1}>{this.source(rowData.source) || "-"}</Clamp>
      ),
    });

    if (selected !== "tab1") {
      columns.push({
        title: "Pre UW?",
        filterPlaceholder: "Filter",
        field: "isPreUnderwritten",
        lookup: { true: "Yes", false: "No", na: "N/A" },

        cellStyle: {
          padding: "6px 0 6px 20px",
          minWidth: "150px",
        },
        headerStyle: {
          padding: "6px 0px 6px 13px",
          minWidth: "150px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 10px",
        },
        render: (rowData) =>
          rowData.isPreUnderwritten === "na"
            ? "N/A"
            : rowData.isPreUnderwritten
            ? "YES"
            : "NO",
      });
    }

    if (selected !== "tab1") {
      columns.push({
        title: "PreUW Comments",
        filterPlaceholder: "Filter",
        filtering: true,
        field: "comment_download",
        render: (rowData) => {
          // let index = rowData.tableData.id;
          // let { rowDataList } = JSON.parse(JSON.stringify(this.state));
          return (
            <div className="d-flex">
              <textarea
                defaultValue={
                  typeof rowData?.comment?.[0] === "object"
                    ? rowData?.comment?.[0]?.comment
                    : rowData?.comment?.[0] || rowData?.comment || ""
                }
                rows="1"
                disabled
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  backgroundColor: this.setCommentBoxColor(rowData?.comment),
                }}
              ></textarea>
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={
                    () =>
                      this.handleCommentHistory(
                        [rowData],
                        `${rowData?.domain || rowData?.salesID}`,
                        "comment"
                      )
                    // console.log(rowData, rowData.domain || rowData.salesID, rowData.comment)
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          minWidth: "230px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 5px",
        },
      });
    }

    columns.push({
      title: "Admin Comments",
      field: "admincomment_download",
      filterPlaceholder: "Filter",
      filtering: true,
      render: (rowData) => {
        return (
          <div className="d-flex">
            <textarea
              defaultValue={
                typeof rowData?.admincomment?.[0] === "object"
                  ? rowData?.admincomment?.[0]?.comment
                  : rowData?.admincomment?.[0] || rowData?.admincomment || ""
              }
              rows="1"
              disabled
              style={{
                width: "100%",
                borderRadius: "3px",
                backgroundColor: this.setCommentBoxColor(rowData?.admincomment),
              }}
            ></textarea>
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                onClick={() =>
                  this.handleCommentHistory(
                    [rowData],
                    `${rowData?.domain || rowData?.salesID}`,
                    "admincomment"
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        minWidth: "230px",
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 5px",
      },
    });

    if (!salesManagerLogin) {
      columns.push({
        title: "SalesManager Comments",
        filterPlaceholder: "Filter",
        filtering: true,
        field: "smcomment_download",
        render: (rowData) => {
          return (
            <div className="d-flex">
              <textarea
                defaultValue={
                  typeof rowData?.smcomment?.[0] === "object"
                    ? rowData?.smcomment?.[0]?.comment
                    : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                }
                disabled
                rows="1"
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  backgroundColor: this.setCommentBoxColor(rowData?.smcomment),
                }}
              ></textarea>
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={() =>
                    this.handleCommentHistory(
                      [rowData],
                      `${rowData?.domain || rowData?.salesID}`,
                      "smcomment"
                    )
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          minWidth: "230px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 5px",
        },
      });
    } else {
      columns.push({
        title: "SalesPerson Comments",
        filterPlaceholder: "Filter",
        filtering: true,
        field: "salescomment_download",
        render: (rowData) => {
          return (
            <div className="d-flex">
              <textarea
                defaultValue={
                  typeof rowData?.salescomment?.[0] === "object"
                    ? rowData?.salescomment?.[0]?.comment
                    : rowData?.salescomment?.[0] || rowData?.salescomment || ""
                }
                disabled
                rows="1"
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  backgroundColor: this.setCommentBoxColor(
                    rowData?.salescomment
                  ),
                }}
              ></textarea>
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={() =>
                    this.handleCommentHistory(
                      [rowData],
                      `${rowData?.domain || rowData?.salesID}`,
                      "salescomment"
                    )
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          minWidth: "230px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 5px",
        },
      });
    }

    if (selected !== "tab5" && selected !== "tab6") {
      columns.push({
        title: "Deal Won/Lost",
        field: "dealFlagFilter",
        filtering: false,
        render: (rowData) => {
          let key = rowData.domain || rowData.salesID;
          let isClosed = false,
            isLost = false;
          if (key in dealMap) {
            isClosed = dealMap[key] === "true" ? true : false;
            isLost = dealMap[key] === "false" ? true : false;
          } else {
            isClosed = rowData.dealFlag === "closed" ? true : false;
            isLost = rowData.dealFlag === "lost" ? true : false;
          }
          return (
            <DealClosedLostButtons
              recordDetails={rowData.recordDetails}
              isClosed={isClosed}
              isLost={isLost}
              updateDealMap={updateDealMap}
              disabled={rowData.dealConfirmed === "true"}
            />
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "150px",
        },
        headerStyle: {
          padding: "6px 5px 6px 20px",
        },
      });
    }

    return (
      <>
        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}

          <MaterialTable
            isLoading={this.props.isLoading}
            icons={tableIcons}
            data={rowDataList}
            columns={columns}
            options={{
              stickyHeader: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [
                10,
                25,
                50,
                75,
                100,
                rowDataList?.length ? rowDataList.length : 0,
              ],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "2px 10px 2px 0px",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
            detailPanel={
              tabName === "tab2" || tabName === "tab3"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <Tab2DetailPanel
                            showAddContact={true}
                            rowData={rowData}
                            parentAdditionalRows={
                              globalAddContact?.[rowData.domain] || []
                            }
                            prim_key={rowData.domain}
                            saveContact={saveContact}
                          />
                        );
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab1DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab4"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if ("true" === rowData?.sales) {
                          return this.tab1DetailPanel(rowData);
                        } else {
                          return (
                            <Tab2DetailPanel
                              showAddContact={false}
                              rowData={rowData}
                              parentAdditionalRows={
                                globalAddContact?.[rowData.domain] || []
                              }
                              saveContact={saveContact}
                              prim_key={rowData.domain}
                            />
                          );
                        }
                      },
                    },
                  ]
                : tabName === "tab5" || tabName === "tab6"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if ("true" === rowData?.sales) {
                          return this.tab1DetailPanel(rowData);
                        } else {
                          return (
                            <Tab2DetailPanel
                              showAddContact={false}
                              rowData={rowData}
                              parentAdditionalRows={
                                globalAddContact?.[rowData.domain] || []
                              }
                              saveContact={saveContact}
                              prim_key={rowData.domain}
                            />
                          );
                        }
                      },
                    },
                  ]
                : false
            }
          />
        </div>
      </>
    );
  };

  handleAgentCommentsButtonClick = (comments, submittedDate) => {
    let timestamp = this.convertToTimestamp(submittedDate);

    if (typeof comments?.[comments?.length - 1] === "string") {
      let updatedComment = comments?.slice(0, -1); // Copy all comments except the last one
      updatedComment.push({
        // Push the updated last comment
        ...initialcomment,
        comment: comments?.[comments?.length - 1],
        commentTimestamp: timestamp,
      });
      this.setState({ modalBody: updatedComment });
    } else {
      this.setState({ modalBody: comments });
    }

    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    let {
      showModel,
      modalTitle,
      modalContent,
      openModal,
      modalBody,
    } = this.state;
    let { tabName, selected } = this.props;
    return (
      <>
        {selected === tabName ? (
          <div
            className={"sales-dashboard " + `${tabName}`}
            style={this.state.style}
          >
            <div ref={this.myRef}>{this.setTableData()}</div>

            <Dialog open={openModal} onClose={this.handleCloseModal}>
              <DialogTitle>
                <p style={{ textAlign: "center" }}>
                  {(modalBody?.length > 0 &&
                    modalBody?.[0] !== "" &&
                    modalBody?.[0]?.comment !== "") ||
                  modalBody?.[0]?.previousComment
                    ? "Comment History"
                    : "No Comment History"}
                </p>
                <IconButton
                  aria-label="close"
                  onClick={this.handleCloseModal}
                  style={{ position: "absolute", right: "5px", top: "8px" }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {modalBody?.length > 0 &&
                  modalBody[0] !== "" &&
                  modalBody.map((item, index) => {
                    if (typeof item.commentTimestamp === "string") {
                      item.commentTimestamp = +item.commentTimestamp;
                    }
                    let date = moment(item.commentTimestamp)
                      .format("M/D/YY h:mm A")
                      .split(" ")[0];
                    let time = moment(item.commentTimestamp)
                      .format("M/D/YY h:mm A")
                      .split(" ")[1];
                    if (item.comment !== "") {
                      if (item.commentTimestamp || item.email || item) {
                        return (
                          <div>
                            <div>
                              <span>
                                <b>{date}</b>
                              </span>
                              <span
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <b>{time}</b>
                              </span>
                              <span>{item.email ? "- " : ""}</span>
                              <span>
                                <b>{item.email?.split("@")[0]}</b>
                              </span>
                            </div>
                            <div>
                              <p>
                                {item?.comment
                                  ? item.comment
                                  : modalBody[index + 1]?.comment
                                  ? modalBody[index + 1].comment
                                  : item}
                              </p>
                            </div>
                            <br />
                          </div>
                        );
                      }
                    } else {
                      return;
                    }
                  })}
              </DialogContent>
            </Dialog>

            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Toaster />
      </>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      this.setState({ loggedInEmail: sessionData["attributes"]["email"] });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    const checkcommentAvailble = (comment) => {
      if (comment?.[0] !== "") {
        if (typeof comment?.[0] === "string") {
          return "#FFE7C1";
        }
      }
      if (comment?.length > 1) {
        return "#FFE7C1";
      }
    };
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          borderRadius: "4px",
          backgroundColor: checkcommentAvailble(this.props.comment),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let agentCommentsCopy = JSON.parse(
            JSON.stringify(this.props.comment)
          );

          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (e.target.value.trim() !== "") {
            if (e.target.value.trim() !== agentCommentsCopy[0].comment) {
              e.target.value.trim() !== agentCommentsCopy[0]?.comment &&
                agentCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  email: loggedInEmail,
                });
            } else {
              return; // Return because no comment was entered;
            }
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (e.target.value.trim() !== agentCommentsCopy[0]?.comment) {
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
            } else {
              return; // Return because no comment was entered;
            }
          }

          this.props.handleAgentCommentHistory(
            this.props.rowDataID,
            this.props.selected,
            agentCommentsCopy
          );
          if (this.props.rowDataSortKeyList) {
            dataToSave.sortKeyList = this.props.rowDataSortKeyList;
          }

          dataToSave[this.props.rowDataName] = this.props.rowDataID;
          dataToSave[this.props.rowDataComments] = agentCommentsCopy;
          dataToSave[this.props.rowDataComments] &&
            this.props.callback(dataToSave, this.props.rowData);
        }}
      ></textarea>
    );
  }
}

export default DashboardSales;
