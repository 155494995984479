const moment = require("moment")

export const createCommentForDownload = (rowData, cmmnt_key) => {
  if (rowData?.[cmmnt_key]) {
    rowData[`${cmmnt_key}_download`] = "";

    if (typeof rowData?.[cmmnt_key]?.[0] === "object") {
      for (let cmmnt_obj_key in rowData[cmmnt_key]) {
        let cmmnt_obj = rowData[cmmnt_key][cmmnt_obj_key];

        if (cmmnt_obj.commentTimestamp && cmmnt_obj.comment)
          rowData[`${cmmnt_key}_download`] += `${moment(Number(cmmnt_obj.commentTimestamp))
            .utcOffset("-0500")
            .format("M/D/YYYY - hh:mm A")}-${cmmnt_obj.comment}, `;
      }
    } else if (typeof rowData?.[cmmnt_key]?.[0] === "string") {
      rowData[`${cmmnt_key}_download`] += `${rowData?.[cmmnt_key]?.[0]}`;
    }
  }
}