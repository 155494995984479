import React from "react";
import TopImage from "../subcompo/sections-home/TopImage";
import OurServices from "../subcompo/sections-home/OurServices";
import WhyUs from "../subcompo/sections-home/WhyUs";
import UniqueAboutUs from "../subcompo/sections-home/UniqueAboutUs";
import LearnMoreAboutUs from "../subcompo/sections-home/LearnMoreAboutUs";
import Testimonial from "../subcompo/sections-home/Testimonial";
import FixedBall from "../subcompo/sections-home/FixedBall";

let imageURL = require("../../images/mask_group_small.png");
let fourthLogo = require("../../images/Fourth_Logo_Horizontal_WHITE.png");
let affinityLogo = require("../../images/affinity_new.png");
let rectange2 = require("../../images/Rectangle 2.png");
let rectange5 = require("../../images/Rectangle 5.png");
let rectange7 = require("../../images/Rectangle 7.png");
let rectange13 = require("../../images/Rectangle 13.png");
let rectange10 = require("../../images/Rectangle 10.png");
let rectange11 = require("../../images/Rectangle 11.png");

let cityBuilding = require("../../images/city-building.png");
let timer = require("../../images/timer.png");
let file = require("../../images/file.png");
let technology = require("../../images/technology.png");

let noPath = require("../../images/no-path.png");
let play = require("../../images/play-4.png");
let noPathCover = require("../../images/no-path-cover.png");

let yellowArrowRight = require("../../images/yellow-arrow-right.png");

let whiteGreenMarks = require("../../images/white-green-marks.png");
let greenDesignBg = require("../../images/green-design-bg.png");

let orangeLine = require("../../images/orange-line.png");

let purpleBg = require("../../images/purple-bg.png");

let ourServiceList = [
  { img: rectange2, text: "Unemployment Claims Administration" },
  { img: rectange5, text: "Workers’ Compensation & Risk Management" },
  { img: rectange7, text: "Employee Benefit Administration" },
  { img: rectange13, text: "Compliance & Healthcare Reform Consulting" },
  { img: rectange10, text: "Employee Benefit Programs" },
  { img: rectange11, text: "Employee Discounts" },
];

let whyUsList = [
  {
    img: cityBuilding,
    text: `In 2011, ${
      process.env.REACT_APP_MODE == "beta" ? "We" : "ChoiceHR"
    } became full-service Professional Employer Organization (PEO).`,
  },
  { img: timer, text: "Generate quotes within minutes with this platform." },
  { img: file, text: "Our extensive range of services and flexible approach." },
  {
    img: technology,
    text:
      "Unique combination of technology and services to restaurants and hospitality business across the country.",
  },
];

export default class Home3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home-landing-page">
        <TopImage imageURL={imageURL} logo1={fourthLogo} logo2={affinityLogo} />
        <OurServices ourServiceList={ourServiceList} />
        <WhyUs whyUsList={whyUsList} purpleBg={purpleBg} />
        <UniqueAboutUs noPath={noPath} play={play} noPathCover={noPathCover} />
        <LearnMoreAboutUs yellowArrowRight={yellowArrowRight} />
        <Testimonial
          whiteGreenMarks={whiteGreenMarks}
          greenDesignBg={greenDesignBg}
          orangeLine={orangeLine}
        />
        <FixedBall />
      </div>
    );
  }
}
