export var acordQues = [
  {
    name: "q1",
    ques: "Do you own, operate or lease aircraft / watercraft?",
    // response: false,
  },
  {
    name: "q2",
    ques:
      "Do you have past, present or discontinued operations involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material? (e.G. Landfills, wastes, fuel tanks, etc)",
    // response: false,
  },
  {
    name: "q3",
    ques: "Do you have any work performed underground or above 15 feet?",
    // response: false,
  },
  {
    name: "q4",
    ques:
      "Do you have any work performed on barges, vessels, docks, bridge over water?",
    // response: false,
  },
  {
    name: "q5",
    ques: "Are you engaged in any other type of business?",
    // response: false,
  },
  {
    name: "q6",
    ques:
      'Are any sub-contractors used? (if "Yes", give % of work subcontracted)',
    // response: false,
    type: "percent",
  },
  {
    name: "q7",
    ques: "Do you have any work sublet without certificates of insurance?",
    // response: false,
  },
  {
    name: "q8",
    ques: "Is there a written safety program in your operations?",
    // response: true,
  },
  {
    name: "q9",
    ques: "Any group transportation provided?",
    // response: false,
  },
  {
    name: "q10",
    ques: "Any employees under 16 or over 60 years of age?",
    // response: false,
  },
  {
    name: "q11",
    ques: "Any seasonal employees?",
    // response: false,
  },
  {
    name: "q12",
    ques: "Is there any volunteer or donated labor?",
    // response: false,
  },
  {
    name: "q13",
    ques: "Any employees with physical disabilities?",
    // response: false,
  },
  {
    name: "q14",
    ques: "Do employees travel out of state?",
    // response: false,
  },
  {
    name: "q15",
    ques: "Are athletic teams sponsored?",
    // response: false,
  },
  {
    name: "q16",
    ques: "Are physicals required after offers of employment are made?",
    // response: false,
  },
  {
    name: "q17",
    ques: "Any other insurance with this insurer?",
    // response: false,
  },
  {
    name: "q18",
    ques:
      "Any prior coverage declined / cancelled / non-renewed in the last three (3) years?",
    // response: false,
  },
  {
    name: "q19",
    ques: "Are employee health plans provided?",
    // response: true,
  },
  {
    name: "q20",
    ques: "Do any employees perform work for other businesses or subsidiaries?",
    // response: false,
  },
  {
    name: "q21",
    ques: "Do you lease employees to or from other employers?",
    // response: false,
  },
  {
    name: "q22",
    ques: "Do any employees predominantly work at home?",
    // response: false,
  },
  {
    name: "q23",
    ques: "Any tax liens or bankruptcy within the last five (5) years?",
    // response: false,
  },
  {
    name: "q24",
    ques:
      "Any undisputed and unpaid worker's compensation premium due from you or any commonly managed or owned enterprises?",
    // response: false,
  },
  {
    name: "q25",
    ques: "Do you need EPLI?",
    // response: false,
  },
  // {
  //   name: "q26",
  //   ques: "Health Benefits?",
  //   response: true,
  // },
];

export var quesListAdd = [
  {
    name: "q26",
    ques: "Are you a safe workplace and do you qualify for safety credit?",
    response: false,
  },
  {
    name: "q27",
    ques:
      "Are you alcohol/drug free and do you qualify for drug free workplace certificate?",
    response: false,
  },
  {
    name: "q28",
    ques: "Contract classification premium adjustment program?",
    response: false,
  },
];

export var companyProfileAddQuestions = [
  {
    name: "qc1",
    ques: "Are you the incumbent agent?",
    response: true,
  },
  {
    name: "qc2",
    ques: "Is the applicant a Non-Profit Operation?",
    response: false,
  },
  {
    name: "qc3",
    ques: "Are you interested in PEO/PAYGO/Both Quotes?",
    response: "c",
  },
  {
    name: "qc3-1",
    ques: "What is the PAYGO Quote type?",
    response: true,
    optionLabels: ["C/O", "Cert"],
  },
  {
    name: "qc4",
    ques: "Are you Interested in E-verification:",
    response: false,
  },
  {
    name: "qc5",
    ques: "Are there additional FEIN #’s tied to this entity?",
    response: false,
  },
  {
    name: "qc6",
    ques: "Are there additional physical addresses tied to this entity?",
    response: false,
  },
];

export var fourthQues = [
  {
    name: "qf1",
    ques: "Does Business remain open beyond 11:00 PM?",
    // response: false,
    ifYes: "approved",
    ifNo: "referred",
  },
  {
    name: "qf2",
    ques: "Is % of Alcohol sales > 50%",
    // response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "qf3",
    ques:
      "Do your employees provide delivery service using a motorized scooter?",
    // response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "qf4",
    ques: "Does applicant provide catering services?",
    // response: false,
    ifYes: "approved",
    ifNo: "referred",
  },
  {
    name: "qf5",
    ques: "Does the restaurant employees also provide room service?",
    // response: false,
    ifYes: "approved",
    ifNo: "referred",
  },
];

export var fourthAmtrustGenericQues = [
  {
    name: "qfag1",
    ques:
      "Does business have more than 50 people working at one location at a time?",
    // response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "qfag2",
    ques:
      "Does business currently have workers compensation coverage in effect?",
    // response: false,
    ifYes: "approved",
    ifNo: "referred",
  },
  {
    name: "qfag3",
    ques:
      "In the past 2 years has business had 2 or more Workers Compensation claims, a single Workers Compensation claim over 20K, or any employee who suffered a work related injury requiring more than 2 days off of work? ",
    // response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
];
