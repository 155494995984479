export function startLoader() {
  try {
    let element = document.getElementById("loader");
    element.style.display = "block";
  } catch (error) { }
}

export function stopLoader() {
  try {
    let element = document.getElementById("loader");
    element.style.display = "none";
  } catch (error) { }
}