import React from "react";
import { stopLoader, startLoader } from "../../../utils/loader";
import {
  extractSpecialAdminDetails,
  dataToSave,
  calculateFinalAdmin,
  calculateFinalSutaAndWC,
  calculateAdminDiscount,
} from "./utils/utils";

export function ActionButton({
  tableRows,
  additionalData,
  setTableRows,
  setAdditionalData,
  setReCalculateData,
}) {
  const onSaveClick = async () => {
    try {
      startLoader();
      let response = extractSpecialAdminDetails(tableRows, additionalData);

      let domain = sessionStorage.getItem("domain");
      let salesID = sessionStorage.getItem("salesID");
      let currProspect = sessionStorage.getItem("currProspect");
      let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));

      let user_email_id = domain
        ? domain
        : salesID
        ? salesID.toLowerCase()
        : currProspect?.companyProfile?.companyName?.value
        ? currProspect.companyProfile.companyName.value.toLowerCase()
        : "";

      let requestBody = {
        user_email_id,
        sortKeyList,
        specialAdminData: response,
      };

      await dataToSave(requestBody);

      setReCalculateData(true);

      console.log("data saved ");
    } catch (error) {
      console.log("error in onSaveClick: ", error);
    } finally {
      stopLoader();
    }
  };

  return (
    <div className="d-flex justify-content-center mt-2 mb-2">
      <button
        className="btn btn-sm btn-outline-primary"
        onClick={(event) => {
          event.preventDefault();
          onSaveClick();
        }}
      >
        Save
      </button>
    </div>
  );
}
