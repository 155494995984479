import React from "react";
import NumberFormat from "react-number-format";
import { calculateAdminDiscount } from "./utils/utils";
import { roundValues, ceilValues } from "../../../utils/math_functions";
import numeral from "numeral";

const additonalTableHeader = () => {
  return (
    <tr>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",
          borderLeft: "2px solid #589aca",
          textAlign: "center",
        }}
      >
        Requested <br></br>Admin
      </th>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",

          textAlign: "center",
        }}
      >
        Tool Calculated<br></br> Admin
      </th>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",

          textAlign: "center",
        }}
      >
        SM Admin <br></br>Discount
      </th>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",

          textAlign: "center",
        }}
      >
        SM Approval <br></br>Limit
      </th>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",

          textAlign: "center",
        }}
      >
        Final<br></br> Admin %
      </th>
      <th
        className="font-family-montserrat-semi-bold"
        style={{
          verticalAlign: "middle",
          borderRight: "2px solid #589aca",
          textAlign: "center",
        }}
      >
        Final Admin <br></br> Fee
      </th>
    </tr>
  );
};

function additionalRows(additionalData, setAdditionalData, wcRate) {
  if (!additionalData) return;

  let sign = additionalData?.type === "percent" ? "%" : "$";

  let onchangeFunction = (event, key, orginalKey, wcRate) => {
    try {
      let value = Number(event?.target?.value);
      let curr = additionalData[key];
      let isAdminSales = wcRate === Number.MAX_SAFE_INTEGER ? true : false;
      let max_lim_value = Number(roundValues(additionalData[orginalKey]));
      if (isAdminSales) {
        max_lim_value = Number.MAX_SAFE_INTEGER;
      }
      let min_lim_value = 0;

      let discount_limit =
        additionalData?.smApprovalLimit &&
        Number(additionalData.smApprovalLimit)
          ? Number(additionalData?.smApprovalLimit)
          : 0;

      if (discount_limit !== Number.MAX_SAFE_INTEGER) {
        try {
          min_lim_value = numeral(100)
            .subtract(discount_limit)
            .divide(100)
            .multiply(max_lim_value)
            .value();
          min_lim_value = ceilValues(min_lim_value);
        } catch (error) {
          console.log(error);
        }
      }

      if (
        value &&
        (numeral(min_lim_value)
          .subtract(value)
          .value() > 0 ||
          numeral(value)
            .subtract(max_lim_value)
            .value() > 0)
      ) {
        value = curr;
      }

      additionalData[key] = Number(roundValues(value));

      calculateAdminDiscount(additionalData, additionalData?.type);

      setAdditionalData(JSON.parse(JSON.stringify(additionalData || {})));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" specialAdminRow">
      <div
        style={{ overflowX: "auto" }}
        className="table-responsive table-wrapper"
      >
        <table className="table table-sm table-bordered text-center">
          <thead className="Header_QuoteTable" id="quoteGenerate">
            {additonalTableHeader()}
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  borderLeft: "2px solid #589aca",
                  borderBottom: "3px solid #589aca",
                }}
              >
                {additionalData?.requestedAdmin
                  ? roundValues(additionalData?.requestedAdmin) + sign
                  : " "}
              </td>
              <td
                style={{
                  borderBottom: "3px solid #589aca",
                }}
              >
                {additionalData?.toolcalculatedAdmin
                  ? roundValues(additionalData?.toolcalculatedAdmin) + sign
                  : " "}
              </td>
              <td
                style={{
                  borderBottom: "3px solid #589aca",
                }}
              >
                {additionalData?.smAdminDiscount
                  ? roundValues(additionalData?.smAdminDiscount) + "%"
                  : sign === "$"
                  ? "$0.00"
                  : "0.00%"}
                {/* <NumberFormat
                  value={additionalData.smAdminDiscount}
                  onChange={onchangeFunction}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  placeholder="0.00%"
                  autoComplete="off"
                  id="smAdminDiscount"
                  style={{ textAlign: "center", maxWidth: "120px" }}
                  type="text"
                /> */}
              </td>
              <td
                style={{
                  borderBottom: "3px solid #589aca",
                }}
              >
                {additionalData?.smApprovalLimit
                  ? additionalData?.smApprovalLimit === Number.MAX_SAFE_INTEGER
                    ? "-"
                    : roundValues(additionalData?.smApprovalLimit) + "%"
                  : " "}
              </td>
              <td
                style={{ maxWidth: "30px", borderBottom: "3px solid #589aca" }}
              >
                {sign === "$" ? (
                  additionalData?.finalAdminPercent ? (
                    roundValues(additionalData?.finalAdminPercent) + "%"
                  ) : (
                    " "
                  )
                ) : (
                  <NumberFormat
                    value={roundValues(additionalData?.finalAdminPercent)}
                    onBlur={(e) =>
                      onchangeFunction(
                        e,
                        "finalAdminPercent",
                        "originalFinalAdminPercent",
                        wcRate
                      )
                    }
                    fixedDecimalScale={true}
                    decimalScale={2}
                    placeholder="0.00%"
                    autoComplete="off"
                    id="finalAdminPercent"
                    style={{
                      textAlign: "center",
                      maxWidth: "-webkit-fill-available",
                    }}
                    type="text"
                  />
                )}
              </td>{" "}
              <td
                style={{
                  borderRight: "2px solid #589aca",
                  borderBottom: "3px solid #589aca",
                }}
              >
                {sign === "%" ? (
                  additionalData?.finalAdminFee ? (
                    "$" + roundValues(additionalData?.finalAdminFee)
                  ) : (
                    " "
                  )
                ) : (
                  <NumberFormat
                    value={roundValues(additionalData?.finalAdminFee)}
                    onBlur={(e) =>
                      onchangeFunction(
                        e,
                        "finalAdminFee",
                        "originalFinalAdminFee",
                        wcRate
                      )
                    }
                    fixedDecimalScale={true}
                    decimalScale={2}
                    placeholder="0.00"
                    autoComplete="off"
                    id="finalAdminFee"
                    style={{
                      textAlign: "center",
                      maxWidth: "-webkit-fill-available",
                    }}
                    type="text"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function PrimaryTable(props) {
  return (
    <div className="additional-row">
      {additionalRows(
        props.additionalData,
        props.setAdditionalData,
        props.wcRate
      )}
    </div>
  );
}
