import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home2 from "./component/pages/Home2";
import Home3 from "./component/pages/Home3";
import ProgramDetails from "./component/pages/ProgramDetails";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import DashboardSales from "./component/pages/DashboardSales";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import ContextProvider from "./contexts/ContextProvider";

class App extends Component {
  componentDidMount() {
    this.setTitle();
  }
  setTitle = () => {
    process.env.REACT_APP_MODE == "beta"
      ? (document.title = "PEO")
      : (document.title = "Affinity ");
  };
  render() {
    return (
      <ContextProvider>
        <Router>
          <Switch>
            <Route path="/demo" component={DemoRouteHandler} />
            <PrivateGroupRoute
              allowedGroup={process.env.REACT_APP_ADMIN_GROUP}
              exact
              path="/AdminDashboard"
              component={Tabs}
            />
            <PrivateGroupRoute
              allowedGroup={process.env.REACT_APP_BRD}
              exact
              path="/brdDashboard"
              component={DashboardSales}
            />
            <PrivateGroupRoute
              allowedGroup={process.env.REACT_APP_SALES_GROUP}
              exact
              path="/SalesDashboard"
              component={DashboardSales}
            />
            <Route exact path="/Quote" component={NewQuote} />
            <AntiPrivateRoute path="/Login" component={Login} />
            {/* <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_CARRIER_GROUP}
            exact
            path="/PanelUW"
            component={PanelUW}
          /> */}
            <Route path="/program-details" component={ProgramDetails} />
            {/* <Route path="/PanelUW" component={PanelUW} /> */}
            <PrivateGroupRoute
              allowedGroup={process.env.REACT_APP_PREUW_GROUP}
              exact
              path="/PanelUW"
              component={PanelUW}
            />
            {/* <Route path="/Home" component={Home3} /> */}
            <Route path="/" component={Home2} />
          </Switch>
        </Router>
      </ContextProvider>
    );
  }
}

export default App;
