import React, { Component } from "react";
import DescriptionCode from "../../subcompo/DescriptionCode";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import { Auth } from "aws-amplify";
let ismobile = window.innerWidth < 992;
const axios = require("axios");
class Newcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PreUWLoginCheck: true,
    };
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      this.setState({ classCodesInfo: this.props.classCodesInfo });
    }
  }

  componentDidMount = async () => {
    this.setState({ locationInfo: this.props.locationInfo });
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          this.setState({ PreUWLoginCheck: false });
        }
      }
    } catch (error) {}
  };

  componentDidMount() {
    const {
      classCodesInfo,
      addressBlockNo,
      addressChildNo,
      handleMinMax,
    } = this.props;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    for (let index in classCodesInfo) {
      if (currProspect && classCodesInfo[index].classCodeDescription) {
        let childrenLoc = currProspect.childrenLoc;
        if (
          Object.keys(classCodesInfo[index].classCodeDescription).length === 0
        ) {
          classCodesInfo[index].classCodeDescription =
            childrenLoc[addressBlockNo].classCodesInfo[
              index
            ].classCodeDescription;
        }
      }
    }
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ classCodesInfo: classCodesInfo });
  }
  conditional_Render_FT_PT = (value) => {
    let style = {};
    if (window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };

      return style;
    }
    if (value === "FT" && window.screen.width > 993) {
      style = {
        width: "13%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "PT" && window.screen.width > 993) {
      style = {
        width: "14%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "annual" && window.screen.width > 993) {
      style = {
        width: "16.5%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "WC" && window.screen.width > 993) {
      style = {
        width: "16%",
        paddingInline: "15px",
      };
      return style;
    }
  };

  render() {
    const {
      addressChildNo,
      addressBlockNo,
      state,
      removeClassCode,
      updateClassCodesInfo,
      handleMinMax,
      updateLocationError,
      isLoggedIn,
    } = this.props;

    const { classCodesInfo } = this.state;

    let isSalespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    )
      ? true
      : false;

    let mailCampaignUserCheck =
      checkMailCampaignUser() && !isSalespersonMailCampaign;

    let showAllFields = sessionStorage.getItem("showAllFields") ? true : false;

    let codeCount = 1;
    try {
      codeCount = Object.keys(classCodesInfo).length;
    } catch (e) {}

    return (
      <div
        className={`row calcPremium grey  ${
          ismobile ? " " : "align-items-end"
        }`}
      >
        <div style={{ width: "8%", paddingInline: "15px" }}></div>
        <DescriptionCode
          addressBlockNo={addressBlockNo}
          addressChildNo={addressChildNo}
          state={(state || "").toLowerCase()}
          handleMinMax={handleMinMax}
          classCodesInfo={classCodesInfo}
          updateLocationError={updateLocationError}
          updateClassCodesInfo={updateClassCodesInfo}
          isLoggedIn={isLoggedIn}
        />
        <span
          id={"invalidCode" + addressBlockNo + addressChildNo}
          style={{ color: "red" }}
        ></span>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("FT")}
        >
          <Form.Group>
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "1"}
              title="Number of full time employees"
            >
              <b className="font-family-montserrat-semi-bold text-nowrap">
                # Full-Time
              </b>
            </label>
            <NumberFormat
              title="Number of full time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "1"}
              isNumericString={true}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "ft",
                  event.target.value.trim()
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].ft &&
                  classCodesInfo[addressChildNo].ft.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo &&
                classCodesInfo[addressChildNo] &&
                classCodesInfo[addressChildNo].ft
                  ? classCodesInfo[addressChildNo].ft.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              name="FT"
              className="text-center"
              format="#####"
              placeholder="# FT"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("PT")}
        >
          <Form.Group>
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "2"}
              title="Number of part time employees"
            >
              <b className="font-family-montserrat-semi-bold"># Part-Time</b>
            </label>
            <NumberFormat
              title="Number of part time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "2"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "pt",
                  event.target.value.trim()
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].pt &&
                  classCodesInfo[addressChildNo].pt.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo &&
                classCodesInfo[addressChildNo] &&
                classCodesInfo[addressChildNo].pt
                  ? classCodesInfo[addressChildNo].pt.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              name="PT"
              className="text-center"
              format="#####"
              placeholder="# PT"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("annual")}
        >
          <Form.Group>
            <label
              for={"classCodes" + addressBlockNo + addressChildNo + "3"}
              title="Annual Payroll"
            >
              <b className="font-family-montserrat-semi-bold">
                Annual Payroll *
              </b>
            </label>
            <NumberFormat
              title="Annual Payroll"
              id={"classCodes" + addressBlockNo + addressChildNo + "3"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "payroll",
                  event.target.value
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].payroll &&
                  classCodesInfo[addressChildNo].payroll.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo &&
                classCodesInfo[addressChildNo] &&
                classCodesInfo[addressChildNo].payroll
                  ? classCodesInfo[addressChildNo].payroll.value
                  : ""
              }
              disabled={false}
              name="payroll"
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              placeholder="Annual Payroll"
              thousandSeparator={true}
              prefix={"$"}
              className="text-center"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        {!mailCampaignUserCheck &&
          isLoggedIn &&
          (this.state.PreUWLoginCheck || showAllFields) && (
            <div
              className="text-left grey"
              style={this.conditional_Render_FT_PT("WC")}
              id="requestedWc"
            >
              <Form.Group>
                <label
                  for={"pricing" + addressBlockNo + addressChildNo + "4"}
                  title="Requested W/C Pricing %"
                  style={{ display: "flex", marginTop: "-1.5rem" }}
                >
                  <b className="font-family-montserrat-semi-bold">
                    Requested W/C Pricing%
                  </b>
                </label>
                <NumberFormat
                  title="Requested W/C Pricing %"
                  id={"pricing" + addressBlockNo + addressChildNo + "3"}
                  // onKeyUp={(event) => {
                  //   updateClassCodesInfo(
                  //     addressBlockNo,
                  //     addressChildNo,
                  //     "pricing",
                  //     event.target.value
                  //   );
                  // }}
                  fixedDecimalScale={true}
                  decimalScale={2}
                // style={{
                //   borderColor:
                //     classCodesInfo &&
                //     classCodesInfo[addressChildNo] &&
                //     classCodesInfo[addressChildNo].pricing &&
                //     classCodesInfo[addressChildNo].pricing.error
                //       ? "red"
                //       : "",
                //   backgroundColor:
                //     classCodesInfo &&
                //     classCodesInfo[addressChildNo] &&
                //     classCodesInfo[addressChildNo].pricing &&
                //     classCodesInfo[addressChildNo].pricing.disabled
                //       ? "#c2c4c5"
                //       : "",
                // }}
                style={{ backgroundColor: "#c2c4c5" }}
                  value={
                    classCodesInfo &&
                    classCodesInfo[addressChildNo] &&
                    classCodesInfo[addressChildNo].pricing
                      ? classCodesInfo[addressChildNo].pricing.value
                      : ""
                  }
                disabled={true}
                  //   classCodesInfo &&
                  //   classCodesInfo[addressChildNo] &&
                  //   classCodesInfo[addressChildNo].pricing &&
                  //   classCodesInfo[addressChildNo].pricing.disabled
                  // }
                  suffix="%"
                  name="pricing"
                  placeholder="W/C Pricing%"
                  // onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  className="text-center"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
          )}
        <div className="companyDeleteButton">
          <div
            className="text-center code-delete"
            // style={{ width: "4%", paddingInline: "15px" }}
          >
            {ismobile?"":<br />}
            {codeCount > 1 && (
              <button
                id={"delete-class-code" + addressBlockNo + addressChildNo + "4"}
                type="button"
                className="rmvLoc btnInvisible transpButton"
                data-address={addressBlockNo}
                data-classno={addressChildNo}
              >
                <img
                  onClick={(event) => {
                    removeClassCode(addressBlockNo, addressChildNo);
                  }}
                  src={require("../../../images/delete4.png")}
                  data-address={addressBlockNo}
                  data-classno={addressChildNo}
                  alt=""
                />
              </button>
            )}
          </div>
        </div>
        {/* {
          <div className="col-sm-4 relDiv rangeSlider">
            <div className="row justify-content-center">
              <div id="minVal" className="premVal">
                <span className="minValueCC">{min}</span>
              </div>
              <div className="barBG"></div>
              <div id="maxVal" className="premVal">
                <span className="manValueCC">{max}</span>
              </div>
            </div>
          </div>
        } */}
      </div>
    );
  }
}
export default Newcode;
