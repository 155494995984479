import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./LandingFooter.module.scss";

export default function LandingFooter({ isLoggedIn, groups, handleLogout }) {
  return (
    <div className={styles.landingFooter}>
      <Container>
        <div className={styles.footerContent}>
          {isLoggedIn &&
            groups &&
            groups.includes(process.env.REACT_APP_ADMIN_GROUP) && (
              <Link to="/AdminDashboard">Dashboard</Link>
            )}
          {isLoggedIn &&
            groups &&
            groups.includes(process.env.REACT_APP_CARRIER_GROUP) && (
              <Link to="/PanelUW">UW Portal</Link>
            )}
          {isLoggedIn &&
            groups &&
            groups.includes(process.env.REACT_APP_SALES_GROUP) && (
              <Link to="/salesdashboard">Dashboard</Link>
            )}
          {isLoggedIn &&
            groups &&
            groups.includes(process.env.REACT_APP_BRD) && (
              <Link to="/brddashboard">Dashboard</Link>
            )}
          {!isLoggedIn ? (
            <Link to="/Login">Login</Link>
          ) : (
            <span onClick={handleLogout}>Logout</span>
          )}
        </div>
      </Container>
    </div>
  );
}
