import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loader from "../common/Loader";
import Navbar from "../common/Navbar";
import FormLogin from "../subcompo/sections-login/FormLogin";
import FormReset from "../subcompo/sections-login/FormReset";
import Footer from "../common/Footer";
import FormChangePass from "../subcompo/sections-login/FormChangePass";
import Header from "../common/Header";
import Stats2 from "../subcompo/sections-home/Stats2";
class Login extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
  }
  render() {
    return (
      <div>
        {/* <Navbar variant="large-logo" /> */}
        <div className="d-flex">
          <Header />
          {/* <Stats2 /> */}
        </div>
        <div className="tab-content tab-content-login">
          <FormLogin />
          <FormReset />
          <FormChangePass />
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Login;
