import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import styles from "./Header.module.scss";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import { startNewQuote, logout } from "../../utils/common";
import { Auth } from "aws-amplify";

class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userName: "user",
      showDashboardLink: false,
      showSalesDashboardLink: false,
      showPreUwDashboardLink: false,
      showBrdDashboradLink: false,
      showSalesManagerLink: true,
    };
  }

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          this.setState({ showDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
          this.setState({
            showSalesManagerLink: false,
            showSalesDashboardLink: true,
          });
        } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          this.setState({ showSalesDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          this.setState({ showPreUwDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_BRD)) {
          this.setState({ showBrdDashboradLink: true });
        }
        this.setState({ isLoggedIn: true, userName });
      }
    } catch (error) {}
  };

  render() {
    const {
      isLoggedIn,
      userName,
      showDashboardLink,
      showSalesDashboardLink,
      showPreUwDashboardLink,
      showBrdDashboradLink,
      showSalesManagerLink,
    } = this.state;
    return (
      <>
        {isLoggedIn && showSalesDashboardLink && showSalesManagerLink && (
          <li onClick={startNewQuote}>
            <Link to={"/Quote"} className="start_new_quote_btn">
              Start New Quote
            </Link>
          </li>
        )}
        {isLoggedIn && (
          <>
            {window.location.pathname.toLowerCase().includes("quote") && (
              <li className="Dashboard__menucontent">
                <Link
                  to={
                    showDashboardLink
                      ? "/AdminDashboard"
                      : showSalesDashboardLink
                      ? "/SalesDashboard"
                      : showPreUwDashboardLink
                      ? "/PanelUW"
                      : showBrdDashboradLink
                      ? "/brdDashboard"
                      : "/"
                  }
                  className="text-dark"
                >
                  Dashboard
                </Link>
              </li>
            )}
            <li
              id="Dropprofile"
              className="d-flex align-items-center text-dark  logout_menu"
            >
              <span
                className="dropdown-toggle text-dark text-capitalize"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {userName}
              </span>
              <div className="dropdown-menu">
                <a className="dropdown-item" onClick={logout}>
                  Logout
                </a>
              </div>
              {/* <DropUserProfile username={this.state.userName} /> */}
            </li>
          </>
        )}
      </>
    );
  }
}

export default HeaderSubPart;
