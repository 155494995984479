import React, { useState, useEffect, useContext, useRef } from "react";
import Context from "../../../contexts/Context";
import { isDeepEquals } from "../../../utils/isDeepEquals";
import { SecondaryTable } from "./SecondaryTable";
import { PrimaryTable } from "./PrimaryTable";
import { ActionButton } from "./ActionButton";
import {
  calculateFinalAdmin,
  calculateFinalSutaAndWC,
  calculateAdminDiscount,
} from "./utils/utils";
import { ceilValues, roundValues } from "../../../utils/math_functions";
import NewProgress from "../../../newcomponent/common/NewProgress";
import numeral from "numeral";

const QuoteStatements = ({ wcRate, smAdmin, finalAdminLim, sign }) => {
  let isAdminSales = wcRate === Number.MAX_SAFE_INTEGER ? true : false;

  return (
    <div className="container Reminderpoints">
      <ul>
        {!isAdminSales && wcRate && (
          <li className="font-family-montserrat-semi-bold">
            {wcRate
              ? `SM WC Rate discount can be Max ${roundValues(wcRate)}% only`
              : ""}
          </li>
        )}
        {!isAdminSales && smAdmin && (
          <li className="font-family-montserrat-semi-bold">
            {smAdmin
              ? `SM Admin discount can be Max ${roundValues(smAdmin)}% only`
              : ""}
          </li>
        )}
        {!isAdminSales && finalAdminLim?.max && finalAdminLim?.min && (
          <li className="font-family-montserrat-semi-bold">
            {finalAdminLim?.max && finalAdminLim?.min
              ? `Final Admin can be Max ${roundValues(finalAdminLim.max) +
                  "" +
                  sign} and Min ${ceilValues(finalAdminLim.min) +
                  "" +
                  sign} only`
              : ""}
          </li>
        )}
        {!isAdminSales && (
          <li className="font-family-montserrat-semi-bold">
            {
              "Special Suta Must be with in the Suta limit only as displayed above"
            }
          </li>
        )}
      </ul>
    </div>
  );
};

const SalesManager = () => {
  const [tableRows, setTableRows] = useState([]);
  const [additionalData, setAdditionalData] = useState({
    additionalData: {},
    tableRows: [],
  });

  const data = useContext(Context);
  const dataRef = useRef(data.salesManagerData);

  if (!isDeepEquals(dataRef.current, data.salesManagerData)) {
    dataRef.current = data.salesManagerData;
  }

  useEffect(() => {
    let salesManagerDataCopy = {};

    try {
      salesManagerDataCopy = JSON.parse(JSON.stringify(data?.salesManagerData));
    } catch (error) {}

    if (salesManagerDataCopy?.rows?.length) {
      calculateFinalSutaAndWC(salesManagerDataCopy.rows);

      setTableRows(salesManagerDataCopy.rows);
    }
    if (
      salesManagerDataCopy?.additionalData &&
      Object.keys(salesManagerDataCopy?.additionalData || {}).length > 0
    ) {
      calculateFinalAdmin(salesManagerDataCopy.additionalData);

      setAdditionalData(salesManagerDataCopy.additionalData);
    }
  }, [dataRef.current]);

  let max_lim_value =
    additionalData[
      additionalData?.type === "percent"
        ? "originalFinalAdminPercent"
        : "originalFinalAdminFee"
    ];
  let min_lim_value = 0;

  let discount_limit =
    additionalData?.smApprovalLimit && Number(additionalData.smApprovalLimit)
      ? Number(additionalData?.smApprovalLimit)
      : 0;

  if (discount_limit !== Number.MAX_SAFE_INTEGER) {
    try {
      min_lim_value = numeral(100)
        .subtract(discount_limit)
        .divide(100)
        .multiply(max_lim_value)
        .value();
      min_lim_value = Number(min_lim_value);
    } catch (error) {}
  }

  return (
    <div id="quoteGenetatepage">
      <NewProgress />
      <div
        className="pink-header font-family-montserrat-bold"
        style={{ fontSize: "x-large", marginTop: "4%" }}
      >
        View and Update Proposal
      </div>
      <PrimaryTable
        additionalData={additionalData}
        setAdditionalData={setAdditionalData}
        wcRate={tableRows?.[0]?.wcRateLimit || ""}
      />
      <SecondaryTable tableRows={tableRows} setTableRows={setTableRows} />
      <QuoteStatements
        wcRate={tableRows?.[0]?.wcRateLimit || ""}
        smAdmin={additionalData.smApprovalLimit}
        finalAdminLim={{ min: min_lim_value, max: max_lim_value }}
        sign={additionalData?.type === "percent" ? "%" : "$"}
      />
      <ActionButton
        tableRows={tableRows}
        additionalData={additionalData}
        reCalculateData={data.reCalculateData}
        setReCalculateData={data.setReCalculateData}
        setTableRows={setTableRows}
        setAdditionalData={setAdditionalData}
      />
    </div>
  );
};

export default SalesManager;
