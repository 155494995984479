import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import {
  updateQuesList,
  updateAmtrustQuesList,
} from "../../../utils/form_ques";
import { setUWQues } from "../../../utils/session_data";
import {
  fourthQues,
  acordQues,
  fourthAmtrustGenericQues,
} from "../../../utils/ques_list";
import {
  salesManagerLoginCheck,
  brdLogincheck,
} from "../../../utils/form_ques";
import {
  submitQues,
  checkQuestionsValidity,
  getClassCodeEligibility,
  getUwQuestionsEligibility,
  getHospitalityEligibility,
} from "../../../utils/form_ques";
import { awsUrl2 } from "../../../config";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";
import axios from "axios";
import { Auth } from "aws-amplify";

import Context from "../../../contexts/Context";

import DropZone from "../PreUwUpload/DropZone";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import { getHeader } from "../../../utils/common";

const eligibilityMap = {
  approved: 1,
  referred: 2,
  declined: 3,
};
class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: [],
      amtrustQuesList: [],
      preUwStatus: "approved",
      clientEligibility: "",
      quoteSubmitted: false,
      salesManagerCheck: false,
      brdCheck: false,
      salesManagerPersonName: "",
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.updateAmtrustQuesList = updateAmtrustQuesList.bind(this);
    this.submitQues = submitQues.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted)
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
  }

  createQuestionsData = () => {
    let { quesList, amtrustQuesList } = JSON.parse(JSON.stringify(this.state));
    for (let question of [...quesList, ...amtrustQuesList]) {
      if (question.response === true) {
        question.response = "Y";
      } else if (question.response === false) {
        question.response = "N";
      }
    }

    return { quesList, amtrustQuesList };
  };

  saveQuestionsResponse = async () => {
    try {
      $("#loader").css("display", "block");

      let { quesList, amtrustQuesList } = this.createQuestionsData();

      let userTrackingData = {
        domain: sessionStorage.getItem("domain"),
        quesList,
        amtrustQuesList,
      };

      await axios
        .post(awsUrl2 + "/api/updatePreUnderwriter/generic", userTrackingData)
        .then((res) => {
          console.log("posted data", res);
          let staticQuestions = this.setQuestions(quesList);
          let amtrustQuestions = this.setQuestions(amtrustQuesList);
          sessionStorage.setItem("quesList", JSON.stringify(staticQuestions));
          sessionStorage.setItem(
            "amtrustQuesList",
            JSON.stringify(amtrustQuestions)
          );
          this.setState({
            preUwFlowMessage: "Questions saved successfully!",
          });
        })
        .catch((error) => {
          console.log("error in saving questions", error);
          this.setState({
            preUwFlowMessage: "Error in saving questions!",
          });
        })
        .finally(() => {
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in saving question responses", error);
      this.setState({
        preUwFlowMessage: "Error in saving questions!",
      });
      $("#loader").css("display", "none");
    }
  };

  clearAllQuestions = () => {
    let { quesList, amtrustQuesList } = JSON.parse(JSON.stringify(this.state));

    for (let question of quesList) {
      delete question.response;
    }

    try {
      for (let question of amtrustQuesList) {
        delete question.response;
      }
    } catch (error) {
      console.log("unabe to delete paygo question responses");
      amtrustQuesList = [];
    }

    this.setState({ quesList, amtrustQuesList });
  };

  amtrustGetQuestionsResponse = (uuid) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await axios.get(
          awsUrl2 + `/api/getAmtrustData/${uuid}/getQuestions`
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  amtrustGetQuestionsPreQuoteResponse = (uuid) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await axios.get(
          awsUrl2 + `/api/getAmtrustData/${uuid}/getQuestionsPreQuote`
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  prefillQuestionResponse = (quesList, savedQuestions) => {
    try {
      let quesListCopy = JSON.parse(JSON.stringify(quesList));
      if (savedQuestions) {
        for (let question of quesListCopy) {
          for (let sQues of savedQuestions) {
            if (sQues.name === question.name) {
              question.response = sQues.response;
            }
          }
        }
      }

      return quesListCopy;
    } catch (error) {
      console.log("error while autofilling questions responses", error);
    }
  };

  getQuesList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
        let { amtrustQuesList } = this.state;
        let restaurantClassCodes;
        let amtrustQuestionsExist = amtrustQuesList?.length > 0;
        let quesList = [];
        let staticAmtrustRequestBody = {};
        let onlyPaygo;

        try {
          let { childrenLoc, companyProfileAddQuestions } = currProspect;
          onlyPaygo = companyProfileAddQuestions?.[2]?.response === "b";

          let dataBody = {
            offices: [],
          };

          for (let addressBlockNo in childrenLoc) {
            let name = `line1_line2_${
              childrenLoc[addressBlockNo].cityName.value
            }_${childrenLoc[addressBlockNo].state.value}_${
              (childrenLoc[addressBlockNo].zipCode ||
                childrenLoc[addressBlockNo].zipcode)["value"]
            }`;

            if (
              !staticAmtrustRequestBody[
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              ]
            ) {
              staticAmtrustRequestBody[
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              ] = [];
            }

            let officesObj = {
              name,
              state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
              requested_suta: "",
              client_suta: "",
              employees: [],
              carriers: ["carrier_p"],
            };

            for (let addressChildNo in childrenLoc[addressBlockNo]
              .classCodesInfo) {
              let classCodeDescArray = childrenLoc[
                addressBlockNo
              ].classCodesInfo[addressChildNo].classCodeDescription.value.split(
                ":"
              );

              let payroll =
                childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                  .payroll.value;

              let code = classCodeDescArray.shift();
              let desc = classCodeDescArray.join(":").trim();
              let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
              officesObj.requested_suta =
                childrenLoc[addressBlockNo].suta &&
                childrenLoc[addressBlockNo].suta.value
                  ? childrenLoc[addressBlockNo].suta.value.split("%")[0]
                  : "";
              officesObj.client_suta =
                childrenLoc[addressBlockNo].clientsuta &&
                childrenLoc[addressBlockNo].clientsuta.value
                  ? childrenLoc[addressBlockNo].clientsuta.value.split("%")[0]
                  : "";

              staticAmtrustRequestBody[state].push(code);

              let classCodeObj = {
                code,
                payroll: childrenLoc[addressBlockNo].classCodesInfo[
                  addressChildNo
                ].payroll.value
                  .split("$")[1]
                  .split(",")
                  .join(""),
                ft: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                  .ft.value
                  ? String(
                      childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                        .ft.value
                    ).trim()
                  : "0",
                pt: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                  .pt.value
                  ? String(
                      childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                        .pt.value
                    ).trim()
                  : "0",
                desc: desc.toLowerCase(),
                pricing:
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                    .pricing &&
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                    .pricing.value
                    ? childrenLoc[addressBlockNo].classCodesInfo[
                        addressChildNo
                      ].pricing.value.split("%")[0]
                    : "",
              };
              officesObj.employees.push(classCodeObj);
            }
            dataBody.offices.push(officesObj);
          }

          let response = await axios.post(
            awsUrl2 + `/api/getHospitalityClassCodes`,
            dataBody
          );

          restaurantClassCodes = response?.data?.value || false;
        } catch (error) {
          console.log("++", error);
          restaurantClassCodes = false;
        }

        let staticAmtrustQuestions;
        try {
          staticAmtrustQuestions = await axios.post(
            awsUrl2 + "/api/getStaticPaygoQuestions",
            staticAmtrustRequestBody
          );
        } catch (error) {
          console.log("error while fetching static amtrust questions");
        }

        this.setState({ uwExpectedResponses: staticAmtrustQuestions?.data }); //here

        if (!onlyPaygo) {
          if (restaurantClassCodes) {
            quesList = fourthQues;
            if (!amtrustQuestionsExist) {
              quesList.push(...fourthAmtrustGenericQues);
              this.setState({ amtrustQuesList: staticAmtrustQuestions?.data });
            }
          } else {
            if (!amtrustQuestionsExist) {
              quesList = acordQues;
              this.setState({ acordQuesAvailable: true });
            }
          }
        } else {
          if (!amtrustQuestionsExist) {
            this.setState({ onlyPaygoError: true });
          }
        }

        resolve(quesList);
      } catch (error) {
        console.log("error", error);
        reject(error);
      }
    });
  };

  prefillQuesitons = (questions, uwResponses, questionSetName) => {
    try {
      let savedResponses = {};
      for (let question of uwResponses) {
        savedResponses[question.name] = question;
      }

      for (let question of questions) {
        if (question.name in savedResponses) {
          question.response = savedResponses[question.name].response;
        } else if (question.paygoName in savedResponses) {
          question.response = savedResponses[question.paygoName].response;
        }
      }

      this.setState({ [questionSetName]: questions });
    } catch (error) {
      console.log(error);
    }
  };

  prefillUwResponses = () => {
    try {
      let { quesList, amtrustQuesList } = this.state;
      let uwQuesList, uwAmtrustQuesList;
      let questionLists = [];
      try {
        uwQuesList = JSON.parse(sessionStorage.getItem("uwQuesList"));
      } catch (error) {}
      try {
        uwAmtrustQuesList = JSON.parse(
          sessionStorage.getItem("uwAmtrustQuesList")
        );
      } catch (error) {}

      if (
        quesList &&
        uwQuesList &&
        quesList?.length > 0 &&
        uwQuesList?.length > 0
      ) {
        this.prefillQuesitons(quesList, uwQuesList, "quesList");
      }

      if (
        amtrustQuesList &&
        uwAmtrustQuesList &&
        amtrustQuesList?.length > 0 &&
        uwAmtrustQuesList?.length > 0
      ) {
        this.prefillQuesitons(
          amtrustQuesList,
          uwAmtrustQuesList,
          "amtrustQuesList"
        );
      }
    } catch (error) {
      console.log("++", error);
    }
  };

  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    $("#loader").css("display", "block");
    try {
      let {
        salesManagerPersonName,
        salesManagerCheck,
        salesManagerPersonNameAttr,
      } = await salesManagerLoginCheck();

      if (salesManagerCheck) {
        this.setState({
          salesManagerCheck,
          salesManagerPersonName,
        });
      }

      //brd login
      let {
        brdPersonName,
        brdPersonNameAttr,
        brdCheck,
      } = await brdLogincheck();

      if (brdCheck) {
        this.setState({
          brdCheck,
          brdPersonName,
        });
      }
    } catch (error) {
      console.log("checking salesmanager login and failed");
    }
    try {
      let formStage = sessionStorage.getItem("formStage");

      let preUwFiles = sessionStorage.getItem("preUwFiles");
      if (preUwFiles) {
        preUwFiles = JSON.parse(preUwFiles);
        let fileList = {};

        preUwFiles.map((ele) => (fileList[ele.keyToFile] = "#empty"));

        this.setState({ fileList });
      }

      if (!formStage || formStage === "one") return;
      let preUnderwriter = sessionStorage.getItem("preUnderwriter") === "true";
      let currProspect;
      currProspect = sessionStorage.getItem("currProspect");
      try {
        currProspect = JSON.parse(currProspect);
      } catch (error) {}

      // console.log("currProspect: ", currProspect);

      // set the amtrust questions if amtrust acount details exist
      if (!preUnderwriter) {
        try {
          let amtrustAccountDetails = sessionStorage.getItem(
            "amtrustAccountDetails"
          );

          try {
            amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
          } catch (error) {}

          if (amtrustAccountDetails && amtrustAccountDetails?.QuoteId) {
            let amtrustQuesList = [];
            let quoteId = amtrustAccountDetails.QuoteId;

            let amtrustTriggerResponse = await axios.get(
              awsUrl2 + `/api/triggerAmtrustGetQuestions/${quoteId}`
            );

            let uuid;
            if (amtrustTriggerResponse.data.uuid) {
              uuid = amtrustTriggerResponse.data.uuid;
            } else {
              throw "error in amtrust create quote trigger";
            }

            let amtrustQuestionsResponse;
            do {
              amtrustQuestionsResponse = await this.amtrustGetQuestionsResponse(
                uuid
              );
              if (amtrustQuestionsResponse === "error") {
                throw "error in amtrust get questions";
              }
            } while (amtrustQuestionsResponse === "processing");

            if (amtrustQuestionsResponse.StatusCode === 200) {
              let amtrustQuesText = [];
              console.log("++", amtrustQuestionsResponse.Data);

              for (let question of amtrustQuestionsResponse.Data) {
                if (!amtrustQuesText.includes(question.Question)) {
                  let response;
                  amtrustQuesText.push(question.Question);
                  if (
                    question.Answer &&
                    question.Answer.toLowerCase() === "yes"
                  ) {
                    response = true;
                  } else if (
                    question.Answer &&
                    question.Answer.toLowerCase() === "no"
                  ) {
                    response = false;
                  }

                  amtrustQuesList.push({
                    name: question.Id,
                    ques: question.Question,
                    response,
                  });
                }
              }
              this.setState({ amtrustQuesList });
            }
          } else if (currProspect.amtrustQuesList) {
            this.setState({ amtrustQuesList: currProspect.amtrustQuesList });
          } else {
            console.log("Amtrust quote not generated");
          }
        } catch (error) {
          console.log("error while setting amtrust questions", error);
        }

        // set the static uw question responses
        try {
          if (currProspect) {
            let quesList = [];
            if (currProspect.uwQues) {
              quesList = currProspect.uwQues;
              this.setUWQues(quesList);
            } else {
              try {
                quesList = await this.getQuesList();
              } catch (error) {
                console.log("error", error);
              } finally {
                this.setState({ quesList });
              }
              this.prefillUwResponses();
            }

            if (currProspect.uwExpectedResponses) {
              this.setState({
                uwExpectedResponses: currProspect.uwExpectedResponses,
              });
            }

            // this.getUniqueAmtrustQuestions();
          }
        } catch (error) {
          console.log(
            "error while setting static uw question responses",
            error
          );
        }
      }

      if (preUnderwriter && currProspect.childrenLoc) {
        try {
          let ClassCodeList = [];
          let amtrustQuesList = [];
          let childrenLoc = currProspect.childrenLoc;

          for (let locIndx in childrenLoc) {
            let state = childrenLoc[locIndx].state.value;
            let classCodesInfo = childrenLoc[locIndx].classCodesInfo;
            for (let ccIndx in classCodesInfo) {
              let cc_desc = classCodesInfo[ccIndx].classCodeDescription.value;
              let code = cc_desc.split(":")[0];
              ClassCodeList.push({
                ClassCode: code,
                LocState: state,
              });
            }
          }

          let amtrustGetPreQuoteResponse = await axios.post(
            awsUrl2 + `/api/triggerAmtrustGetQuestionsPreQuote`,
            JSON.stringify({
              ClassCodeList,
            })
          );

          let uuid;
          if (amtrustGetPreQuoteResponse.data.uuid) {
            uuid = amtrustGetPreQuoteResponse.data.uuid;
          } else {
            throw "error in amtrust create quote trigger";
          }

          let amtrustQuestionsResponse;
          do {
            amtrustQuestionsResponse = await this.amtrustGetQuestionsPreQuoteResponse(
              uuid
            );
            if (amtrustQuestionsResponse === "error") {
              throw "error in amtrust get questions";
            }
          } while (amtrustQuestionsResponse === "processing");

          if (amtrustQuestionsResponse.StatusCode === "200") {
            let idList = [];
            for (let question of amtrustQuestionsResponse.Questions) {
              // remove duplicate question ids
              if (!idList.includes(question.QuestionId)) {
                amtrustQuesList.push({
                  name: question.QuestionId,
                  ques: question.Question,
                });
                idList.push(question.QuestionId);
              }
            }

            // prefill the amtrust question responses if saved earlier
            try {
              let savedQuestions = JSON.parse(
                sessionStorage.getItem("currProspect")
              ).amtrustQuesList;
              if (savedQuestions) {
                console.log("inside saved questions");
                // savedQuestions = JSON.parse(savedQuestions);
                amtrustQuesList = this.prefillQuestionResponse(
                  amtrustQuesList,
                  savedQuestions
                );
                console.log("amtrustQuesList: ", amtrustQuesList);
                console.log("saved: ", savedQuestions);
              }
            } catch (error) {
              console.log("error while autofilling amtrust question responses");
            }
            this.setState({ amtrustQuesList });
          }

          // prefill the static question responses if saved earlier

          try {
            if (currProspect) {
              let quesList = [];
              if (currProspect.uwQues) {
                quesList = currProspect.uwQues;
                this.setState({ quesList });
                // this.setUWQues(quesList);
              } else {
                try {
                  quesList = await this.getQuesList();
                } catch (error) {
                  console.log("error", error);
                } finally {
                  this.setState({ quesList });
                }
              }

              if (currProspect.uwExpectedResponses) {
                this.setState({
                  uwExpectedResponses: currProspect.uwExpectedResponses,
                });
              }
            }
          } catch (error) {
            console.log(
              "error while setting static uw question responses",
              error
            );
          }
        } catch (error) {
          console.log("error in working with preUnderwriter flag", error);
        }
      }

      try {
        if (preUnderwriter) {
          let clientEligibility = sessionStorage.getItem("clientEligibility");
          if (clientEligibility) {
            this.setState({ clientEligibility });
          }
        }
        if (preUnderwriter && currProspect.preUwStatus) {
          this.setState({ preUwStatus: currProspect.preUwStatus });
        }
      } catch (error) {}
    } catch (error) {
      console.log(error);
    } finally {
      $("#loader").css("display", "none");
    }
  }

  getUniqueAmtrustQuestions = () => {
    try {
      let { amtrustQuesList, uwExpectedResponses } = this.state;
      // if (uwAmtrustQuesList && uwExpectedResponses) {
      console.log("++", amtrustQuesList, uwExpectedResponses);

      // }
    } catch (error) {
      console.log("++ error in removing duplicate amtrust questions", error);
    }
  };

  putPeoCampaignData = async (item) => {
    return new Promise(async (resolve, reject) => {
      try {
        let currentUserDetails = await Auth.currentAuthenticatedUser();
        let uwEmail = currentUserDetails.attributes.email;
        item.uwEmail = uwEmail;

        await axios
          .post(
            awsUrl2 + "/api/peoCampaignData/putFromUndrWrtr",
            JSON.stringify(item)
          )
          .then((res) => {
            console.log(res);
            resolve({ data: "updated" });
          })
          .catch((err) => {
            console.log(err);
            reject({ error: err });
          });
      } catch (error) {
        reject({ error: error });
      }
    });
  };

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
      };
    }

    return response;
  };

  updatePreunderWriterData = async (pre_underwriter_selection) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { quesList, amtrustQuesList } = this.createQuestionsData();

        let userTrackingData = {
          domain: sessionStorage.getItem("domain"),
          pre_underwriter_status: "completed",
          pre_underwriter_selection,
          quesList,
          amtrustQuesList,
        };

        await axios
          .post(awsUrl2 + "/api/updatePreUnderwriter/generic", userTrackingData)
          .then((res) => {
            console.log("posted data", res);
            let staticQuestions = this.setQuestions(quesList);
            let amtrustQuestions = this.setQuestions(amtrustQuesList);
            sessionStorage.setItem("quesList", JSON.stringify(staticQuestions));
            sessionStorage.setItem(
              "amtrustQuesList",
              JSON.stringify(amtrustQuestions)
            );
            resolve();
          })
          .catch((error) => {
            console.log("error in posting data to user tracking", error);
            reject();
          });
      } catch (error) {
        console.log("error while updating before Pre underwriter ", error);
        reject();
      }
    });
  };

  onClickApprove = async () => {
    try {
      $("#loader").css("display", "block");
      let uwEmail;
      try {
        let currentUserDetails = await Auth.currentAuthenticatedUser();
        uwEmail = currentUserDetails.attributes.email;
      } catch (error) {}

      let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag")
        ? true
        : false;

      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );
      let domain = sessionStorage.getItem("domain");

      let { childrenLoc } = currProspectDetails;

      let dataBody = {
        common: {},
        offices: [],
      };

      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
        };

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
              .value
              ? String(
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
                    .value
                ).trim()
              : "0",
            pt: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
              .value
              ? String(
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
                    .value
                ).trim()
              : "0",
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                    addressChildNo
                  ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let {
        uwExpectedResponses,
        quesList,
        amtrustQuesList,
        acordQuesAvailable,
        preUwStatus,
      } = this.state;

      let uwQuesData = [];

      for (let question of quesList) {
        let dataQues = {
          response: question.response ? "Y" : "N",
          name: question.name,
          ques: question.ques,
          ifYes: question.ifYes,
          ifNo: question.ifNo,
        };
        uwQuesData.push(dataQues);
      }

      let uwQuestionsEligibility = getUwQuestionsEligibility({
        uwQuesData,
        amtrustQuesList,
        uwExpectedResponses,
        acordQuesAvailable,
      });

      let classCodeEligibility = getClassCodeEligibility(
        currProspectDetails.childrenLoc
      );

      let hospitalityEligibility = await getHospitalityEligibility(dataBody);

      let clientEligibility;
      let eligibilityList = [
        uwQuestionsEligibility || "approved",
        classCodeEligibility || "approved",
        hospitalityEligibility || "approved",
      ];
      try {
        clientEligibility = eligibilityList.reduce((a, b) => {
          if (eligibilityMap[a] > eligibilityMap[b]) {
            return a;
          }
          return b;
        });
      } catch (error) {
        clientEligibility = "approved";
      }

      let preUwData = {
        currProspect: currProspectDetails,
        userId: uwEmail || "",
        preUwStatus,
        preUwDate: Math.floor(Date.now()).toString(),
      };

      let preUwDataToSave = {
        domain,
        clientEligibility,
        classCodeEligibility,
        uwQuestionsEligibility,
        uwExpectedResponses,
        uwQuesList: quesList,
        uwAmtrustQuesList: amtrustQuesList,
        preUwDate: preUwData.preUwDate,
        preUwData,
      };
      try {
        console.log("pre Data: ", preUwDataToSave);
        let apiPath = preUwEngagedFlag ? "updateTrackingData" : "updateData";
        let header = await getHeader();

        await axios
          .post(awsUrl2 + `/api/preUwData/${apiPath}`, preUwDataToSave, header)
          .then((res) => {
            console.log("posted data", res);
          })
          .catch((error) => {
            console.log("error in posting data to user tracking", error);
          });

        try {
          if (!preUwEngagedFlag) {
            await axios.post(
              awsUrl2 +
                "/api/updateSolveTrackingDataGeneric/updateUserTrackingPreUw",
              { domain }
            );

            await axios.post(
              awsUrl2 + "/api/updateEngagedWithEmailCampaign/" + domain,
              JSON.stringify(domain)
            );

            sessionStorage.setItem("preUwEngagedFlag", "true");
          }
        } catch (error) {
          console.log("Error in API: ", error);
        }
      } catch (error) {
        console.log("error while updating before Pre underwriter ", error);
      } finally {
        this.setState({ clientEligibility });
        sessionStorage.setItem("clientEligibility", clientEligibility);
        currProspectDetails = {
          ...currProspectDetails,
          amtrustQuesList: amtrustQuesList || [],
          uwQues: quesList || [],
          uwExpectedResponses: uwExpectedResponses || [],
        };
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify(currProspectDetails)
        );
        $("#loader").css("display", "none");
      }
    } catch (error) {
      $("#loader").css("display", "none");
      console.log("error in onClickApprove");
    }
  };

  firstCaps = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  };

  render() {
    const {
      quesList,
      amtrustQuesList,
      incompleteQuestions,
      preUwFlowMessage,
      acordQuesAvailable,
      uwExpectedResponses,
      onlyPaygoError,
      clientEligibility,
      quoteSubmitted,
      fileList,
      salesManagerCheck,
      brdCheck,
    } = this.state;
    const { updatePopUpData } = this.props;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let disableGetQuote = sessionStorage.getItem("disableGetQuote");

    let mailCampaignUserCheck = checkMailCampaignUser();

    let domain = sessionStorage.getItem("domain")
      ? sessionStorage.getItem("domain")
      : "";
    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;
    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName =
        currProspect?.companyProfile &&
        currProspect?.companyProfile?.companyName
          ? currProspect.companyProfile?.companyName?.value
          : "";
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98 underwrittingcolor ">
        <NewProgress />
        <div id="underWritingQues ">
          <div>
            {onlyPaygoError ? (
              <div>
                The PAYGO API ran into an issue. A representative will reach out
                to you soon.
              </div>
            ) : (
              <div className="table-responsive table-undrques-container ">
                <Allques
                  quesList={quesList}
                  updateQuesList={this.updateQuesList}
                  header={true}
                  clearAll={this.clearAllQuestions}
                  saveQuestionsResponse={this.saveQuestionsResponse}
                />
                {amtrustQuesList.length > 0 && (
                  <Allques
                    quesList={amtrustQuesList}
                    updateQuesList={this.updateAmtrustQuesList}
                  />
                )}
              </div>
            )}
            {incompleteQuestions && (
              <div className="errMsg text-center mb-4">
                Please answer all the questions!
              </div>
            )}
            {flagPreUnderWriter && (
              <div className="m-3 d-flex align-items-center justify-content-center">
                <DropZone
                  updatePopUpData={updatePopUpData}
                  fileList={fileList}
                />
              </div>
            )}
            {flagPreUnderWriter && (
              <div className="row">
                <div className="col-2">
                  <select
                    value={this.state.preUwStatus || "approved"}
                    id="preUwStatus"
                    type="text"
                    style={{ fontSize: "16px", width: "160px" }}
                    onChange={(event) =>
                      this.setState({ preUwStatus: event.target.value })
                    }
                    autoComplete="off"
                    className="form-control mb-2 mt-2"
                  >
                    <option key={1} value={"approved"}>
                      {" "}
                      Approve{" "}
                    </option>
                    <option key={2} value={"rejected"}>
                      {" "}
                      Reject{" "}
                    </option>
                  </select>
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#8ab93f",
                      color: "white",
                    }}
                    disabled={disableGetQuote ? true : false}
                    onClick={() => {
                      if (!disableGetQuote) this.onClickApprove();
                    }}
                  >
                    Submit
                  </button>
                </div>
                {clientEligibility && (
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "left",
                    }}
                  >{`Client Eligibility: ${this.firstCaps(
                    clientEligibility
                  )}`}</div>
                )}
              </div>
            )}
            {flagPreUnderWriter && false && (
              <>
                {preUwFlowMessage && (
                  <div className="text-center mb-4">{preUwFlowMessage}</div>
                )}
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-evenly",
                  }}
                >
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#8ab93f",
                      color: "white",
                    }}
                    onClick={async () => {
                      try {
                        $("#loader").css("display", "block");
                        let validQuestions = checkQuestionsValidity(
                          quesList,
                          amtrustQuesList
                        );
                        if (validQuestions) {
                          this.setState({ incompleteQuestions: false });
                          await this.putPeoCampaignData({
                            domain,
                            onlyPaygo: "false",
                          });
                          await this.updatePreunderWriterData("peo");
                          this.setState({
                            preUwFlowMessage: "Response saved successfully!",
                          });
                          $("#loader").css("display", "none");
                        } else {
                          this.setState({ incompleteQuestions: true });
                          $("#loader").css("display", "none");
                        }
                      } catch (error) {
                        console.log("error in updating the response", error);
                        this.setState({
                          preUwFlowMessage: "Error in saving response!",
                        });
                        $("#loader").css("display", "none");
                      }
                    }}
                  >
                    Approved for PEO-Master
                  </button>
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#c0224d",
                      color: "white",
                    }}
                    onClick={async () => {
                      try {
                        $("#loader").css("display", "block");
                        let validQuestions = checkQuestionsValidity(
                          quesList,
                          amtrustQuesList
                        );
                        if (validQuestions) {
                          this.setState({ incompleteQuestions: false });
                          await this.putPeoCampaignData({
                            domain,
                            onlyPaygo: "true",
                          });
                          await this.updatePreunderWriterData("paygo");
                          this.setState({
                            preUwFlowMessage: "Response saved successfully!",
                          });
                          $("#loader").css("display", "none");
                        } else {
                          this.setState({ incompleteQuestions: true });
                          $("#loader").css("display", "none");
                        }
                      } catch (error) {
                        console.log("error in updating the response", error);
                        this.setState({
                          preUwFlowMessage: "Error in saving response!",
                        });
                        $("#loader").css("display", "none");
                      }
                    }}
                  >
                    Not Approved for PEO-Master
                  </button>
                </div>
              </>
            )}
          </div>
          {!flagPreUnderWriter && !onlyPaygoError && (
            <div className="row">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn button_color btn-lg btn-warning-shadow btnSubmits button_color"
                  onClick={() => {
                    if (
                      hideButtons ||
                      quoteSubmitted ||
                      disableGetQuote ||
                      salesManagerCheck
                    )
                      return;
                    this.submitQues(
                      quesList,
                      amtrustQuesList,
                      updatePopUpData,
                      acordQuesAvailable,
                      uwExpectedResponses
                    );
                  }}
                  disabled={
                    hideButtons ||
                    quoteSubmitted ||
                    disableGetQuote ||
                    salesManagerCheck ||
                    brdCheck
                  }
                >
                  Get Quote
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default function FormUnderQuesWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormUnderQues
          updatePopUpData={props.updatePopUpData}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
        />
      )}
    </Context.Consumer>
  );
}
