import React from "react";

export default function WhyUs(props) {
  return (
    <div className="why-us">
      <div className="row row-1 row-no-margin">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="why-us-text">Why us?</div>
      </div>
      <div
        className="row-2 row-no-margin"
        style={{
          background: `url(${props.purpleBg})`,
        }}
      >
        <div className="row Whyus__coloumn row-no-margin">
          {props.whyUsList &&
            props.whyUsList.map((ele, i) => {
              return (
                <div
                  className="col col-sm-12 col-md-12 col-lg-6"
                  // style={i === 2 || i === 3 ? { height: "min-content" } : {}}
                >
                  <div className="custom-card">
                    <div className="row-custom-card align-items-center">
                      <div className="custom-card-image">
                        <img src={ele.img} className="card-img-top"></img>
                      </div>
                      <div className="custom-card-text">{ele.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
