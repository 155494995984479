import React from "react";
import { useState } from "react";
import axios from "axios";

export default function LearnMoreAboutUs(props) {
  const [first_name, setFirst_name] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();
  const [services, setServices] = useState();
  const [comment, SetComment] = useState();
  const formSubmit = async () => {
    console.log(first_name, email, phone, company, services, comment);
    let raw = JSON.stringify({
      data: {
        first_name: first_name,
        email: email,
        phone: phone,
        company: company,
        services: services,
        comment: comment,
      },
    });

    var myHeaders = new Headers();
    myHeaders.append("Origin", "http://localhost:8000");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://lh8add1jfj.execute-api.us-east-1.amazonaws.com/prod/mail-form/fourthhr",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        if (result) {
          setFirst_name("");
          setEmail("");
          setCompany("");
          setPhone("");
          setServices("");
          SetComment("");
        }
      })
      .catch((error) => console.log("error", error));

    // let requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // await axios
    //   .post(
    //     "https://lh8add1jfj.execute-api.us-east-1.amazonaws.com/prod/mail-form/fourthhr",
    //     raw
    //   )
    //   .then(async (res) => {
    //     console.log(res);
    //   })
    //   .catch((error) => {
    //     console.log("error in sendign the data", error);
    //   });

    // console.log(raw);
  };
  return (
    <div
      className="learn-more-about-us"
      style={{
        background: `url(${props.yellowArrowRight})`,
      }}
    >
      <div className="row row-1 row-no-margin" id="landingform">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="learn-text">Learn more about us</div>
      </div>
      <div className="form-col">
        <input
          type="text"
          placeholder="First Name"
          value={first_name}
          onChange={(e) => {
            setFirst_name(e.target.value);
          }}
        />
        <input
          type="email"
          placeholder="Email ID"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Company Name"
          value={company}
          onChange={(e) => {
            setCompany(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Services"
          value={services}
          onChange={(e) => {
            setServices(e.target.value);
          }}
        />
        <textarea
          placeholder="Comment"
          value={comment}
          className="learn-comment"
          rows="3"
          onChange={(e) => {
            SetComment(e.target.value);
          }}
        />
        <div className="btn-container d-flex justify-content-center">
          <button className="btn btn-sm btn-warning" onClick={formSubmit}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}
