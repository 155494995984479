import React from "react";
import NumberFormat from "react-number-format";
import { firstCaps, calculateFinalSutaAndWC } from "./utils/utils";
import { roundValues } from "../../../utils/math_functions";
import Clamp from "react-multiline-clamp";

const tableDataRow = (rowData, key, handleChange) => {
  let sign = "%";
  return (
    <tr key={key}>
      <td
        style={{
          fontWeight: "bold",
          borderLeft: "2px solid #589aca",
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData.state.toUpperCase()}
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData?.classCode || ""}
      </td>
      <td
        style={{
          overflow: "hidden",
          borderBottom: "3px solid #589aca",
        }}
        title={rowData?.description}
      >
        <Clamp lines={1}>{firstCaps(rowData?.description) || ""}</Clamp>
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData?.requestedWcRate
          ? roundValues(rowData?.requestedWcRate) + sign
          : ""}
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData?.wcRate ? roundValues(rowData?.wcRate) + sign : ""}
      </td>
      <td style={{ borderBottom: "3px solid #589aca" }}>
        <NumberFormat
          value={rowData?.specialWcRate || ""}
          onChange={(event) => {
            let value = event.target.value;
            let limit = rowData.wcRateLimit;
            // if (!value) return;

            if (
              Number(limit) !== Number.MAX_SAFE_INTEGER &&
              value.charAt(0) === "-"
            )
              value = 0;

            let curr = rowData.specialWcRate || 0;

            if (value && Number(value) > Number(limit)) value = curr;
            handleChange(key, "specialWcRate", value);
          }}
          style={{ textAlign: "center", maxWidth: "90px" }}
          placeholder="0.00%"
          autoComplete="off"
          id="specialWcRate"
          type="text"
        />
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData?.finalWcRate ? roundValues(rowData?.finalWcRate) + sign : ""}
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData?.requestedSuta
          ? roundValues(rowData?.requestedSuta) + sign
          : ""}
      </td>
      <td
        style={{
          whiteSpace: "nowrap",
          borderBottom: "3px solid #589aca",
        }}
      >
        {rowData.sutaLimitValue}
      </td>
      <td style={{ borderBottom: "3px solid #589aca" }}>
        <NumberFormat
          value={rowData?.specialSutaLimit || ""}
          onBlur={(event) => {
            let value = event.target.value;
            if (!value) return;

            let curr = rowData.specialSutaLimit;
            let limit = rowData.sutaLimit;

            if (
              !value ||
              (value && isNaN(value)) ||
              (value &&
                Number(value) !== 0 &&
                (Number(value) < Number(limit.start) ||
                  Number(value) > Number(limit.end)))
            )
              value = "";

            handleChange(key, "specialSutaLimit", Number(value));
          }}
          style={{ textAlign: "center", maxWidth: "90px" }}
          placeholder="0.00%"
          autoComplete="off"
          disabled={rowData?.finalSuta === "client"}
          id="specialSutaLimit"
          type="text"
        />
      </td>
      <td
        style={{
          borderBottom: "3px solid #589aca",
          borderRight: "2px solid #589aca",
        }}
      >
        {rowData?.finalSuta === "client"
          ? "CLIENT SUTA"
          : roundValues(rowData?.finalSuta) + "%"}
      </td>
    </tr>
  );
};

const tableData = (rows, setTableRows) => {
  let handleChange = (index, key, value) => {
    try {
      rows[index][key] = value;
      calculateFinalSutaAndWC(rows);
      setTableRows([...rows]);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <table className="table-responsive   text-center" id="quoteGenerate">
      <thead className="Header_QuoteTable">
        <tr>
          <th
            style={{
              borderLeft: "2px solid #589aca",
            }}
          >
            State
          </th>
          <th>
            W.C<br></br>Code
          </th>
          <th>W.C Description</th>

          <th>
            Requested<br></br>W.C Rate
          </th>
          <th>
            Tool W.C <br></br> Rate
          </th>
          <th>
            SM W.C Rate <br></br> Discount
          </th>
          <th>
            Final W.C <br></br>Rate
          </th>
          <th>
            Requested <br></br>SUTA
          </th>
          <th>
            SUTA <br></br>Limit
          </th>
          <th>
            Special<br></br>SUTA
          </th>
          <th
            style={{
              borderRight: "2px solid #589aca",
            }}
          >
            Final SUTA
          </th>
        </tr>
      </thead>

      <tbody>
        {rows.map((data, key) => {
          return tableDataRow(data, key, handleChange);
        })}
      </tbody>
    </table>
  );
};

export function SecondaryTable({ tableRows, setTableRows }) {
  return (
    <div Style={{ overFlow: "auto" }}>
      <div className="container-fluid">
        {tableData(tableRows, setTableRows)}
      </div>
    </div>
  );
}
