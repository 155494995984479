import React, { Component } from "react";
import Context from "./Context";

export default class ContextProvider extends Component {
  state = {
    quoteSubmitted: false,
    salesManagerData: { rows: [], additionalData: {} },
    reCalculateData: false,
    carrierSubmittedWith: null,
    amtrustBound: false,
  };

  render() {
    let {
      quoteSubmitted,
      salesManagerData,
      reCalculateData,
      carrierSubmittedWith,
      amtrustBound,
    } = this.state;

    return (
      <Context.Provider
        value={{
          quoteSubmitted: quoteSubmitted,
          setQuoteSubmittedStatus: (status) => {
            this.setState({ quoteSubmitted: status });
          },
          carrierSubmittedWith: carrierSubmittedWith,
          setCarrierSubmittedWith: (status) => {
            this.setState({ carrierSubmittedWith: status });
          },
          amtrustBound: amtrustBound,
          setAmtrustBoundStatus: (status) => {
            this.setState({ amtrustBound: status });
          },
          salesManagerData: salesManagerData,
          setSalesManagerData: (data) => {
            this.setState({ salesManagerData: data });
          },
          reCalculateData: reCalculateData,
          setReCalculateData: (value) => {
            this.setState({ reCalculateData: value });
          },
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
