//Underwrtting Question data
const amturstUwquestion = (amtrustQueList, amtrustPreparedData) => {
  try {
    for (let key of amtrustQueList) {
      if (key.response === false) {
        key.response = "N";
      }
      if (key.response === true) {
        key.response = "Y";
      }
    }
    amtrustPreparedData.push(...amtrustQueList);
  } catch (error) { }
};

//company profile data
const preparecompanyProfileArray = (companyProfile, companyProfileArray) => {
  try {
    companyProfileArray.push({
      companyName: `${companyProfile["companyName"]?.value}`,
      descriptionOfOperations: `${companyProfile["descriptionOfOperations"]?.value}`,
      fein: `${companyProfile["fein"]?.value}`,
      firstName: `${companyProfile["firstName"]?.value}`,
      lastName: `${companyProfile["lastName"]?.value}`,
      phoneNumber: `${companyProfile["phoneNumber"]?.value}`,
      entityType: `${companyProfile["entityType"]?.value}`,
      yearsInBusiness: `${companyProfile["yearsInBusiness"]?.value}`,
      expectedStartDate: `${companyProfile["expectedStartDate"]?.value}`,
      address: `${companyProfile["address"]?.value}`,
      city: `${companyProfile["city"]?.value}`,
      cityName1: `${companyProfile["cityName1"]?.value}`,
      cityName2: `${companyProfile["CityName2"]?.value}`,
      state1: `${companyProfile["state1"]?.value}`,
      state2: `${companyProfile["state2"]?.value}`,
      street1: `${companyProfile["street1"]?.value}`,
      street2: `${companyProfile["street2"]?.value}`,
      zipCode1: `${companyProfile["zipCode1"]?.value}`,
      zipCode2: `${companyProfile["zipCode2"]?.value}`,
    });
  } catch (error) { }
}

//payroll data
const preparePayrollData = (payrollData, payrollDataPrepared, payrollDetailsData) => {
  try {
    for (let key of Object.values(payrollData)) {
      payrollDetailsData.push(key);
    }

    for (let key in payrollDetailsData) {
      let payload = payrollDetailsData[key];
      let classCodeInfo = payload.classCodesInfo;
      let loc = ++key;
      console.log(key, "++++");
      for (let key in classCodeInfo) {
        let classCodekey = classCodeInfo[key];

        payrollDataPrepared.push({
          street: `${payload["street"]?.value}`,
          zipCode: `${payload["zipCode"]?.value}`,
          cityName: `${payload["cityName"]?.value}`,
          clientsuta: `${payload["clientsuta"]?.value}`,
          state: `${payload["state"]?.value}`,
          suta: `${payload["suta"]?.value}`,
          classCodeDescription: `${classCodekey["classCodeDescription"]?.value}`,
          ft: `${classCodekey["ft"]?.value}`,
          pt: `${classCodekey["pt"]?.value}`,
          payroll: `${classCodekey["payroll"]?.value}`,
          loc: loc,
        });
      }
    }
  } catch (error) { }
}

//amtrust questions
const prepareAmtrustData = (uwquestionPreparedDataa, uwquestionsArray, currentProspect, amtrustPreparedData) => {
  try {
    uwquestionPreparedDataa = [...amtrustPreparedData, ...currentProspect.uwQues];

    for (let key in uwquestionPreparedDataa) {
      let one = uwquestionPreparedDataa[key];
      uwquestionsArray.push({ ques: `${one.ques}`, response: `${one.response}` });
    }
  } catch (error) { }
}

const prepareFinalData = (companyProfileArray, uwquestionsArray, payrollDataPrepared) => {
  try {
    return [
      {
        sheet: "Company Profile",
        columns: [
          { label: "Company Name", value: "companyName" },
          { label: "Description Of Operations", value: "descriptionOfOperations" },
          { label: "FEIN", value: "fein" },
          { label: "First Name", value: "firstName" },
          { label: "Last Name", value: "lastName" },
          { label: "Phone Number", value: "phoneNumber" },
          { label: "Entity Type", value: "entityType" },
          { label: "Years In Business", value: "yearsInBusiness" },
          { label: "Expected Start Date", value: "expectedStartDate" },
          { label: "Primary Adress", value: "street1" },
          { label: "Primary City", value: "cityName1" },
          { label: "Primary State", value: "state2" },
          { label: "Primary Zip", value: "zipCode1" },
          { label: "Mailing Address", value: "street2" },
          { label: "Mailing City", value: "cityName2" },
          { label: "Mailing State", value: "state2" },
          { label: "Mailing Zip", value: "zipCode2" },
        ],

        content: companyProfileArray,
      },
      {
        sheet: "UW_Question",
        columns: [
          { label: " UW_Question", value: "ques" }, // Top level data
          { label: "Response", value: "response" }, // Custom format
        ],

        content: uwquestionsArray,
      },
      {
        sheet: "PayrollDetails",
        columns: [
          { label: "Location", value: "loc" },
          { label: " Street", value: "street" }, // Top level data
          { label: "City Name", value: "cityName" },
          { label: "State", value: "state" },
          { label: "Zip Code", value: "zipCode" },

          { label: "Class Code Description", value: "classCodeDescription" },
          { label: "FT EMP", value: "ft" },
          { label: "PT EMP", value: "pt" },
          { label: "Payroll", value: "payroll" },
          { label: "Client Suta", value: "clientsuta" },
          { label: "Requested Suta", value: "suta" },
        ],

        content: payrollDataPrepared,
      },
    ];
  } catch (error) { }
}

export default function (currentProspect) {
  let data;
  try {
    let uwquestionPreparedDataa = [];
    let uwquestionsArray = [];
    let amtrustPreparedData = [];
    let companyProfileArray = [];
    let payrollDetailsData = [];
    let payrollDataPrepared = [];
    let amtrustQueList = currentProspect?.amtrustQuesList || [];
    let companyProfile = currentProspect?.companyProfile || {};
    let payrollData = currentProspect?.childrenLoc || {};

    preparecompanyProfileArray(companyProfile, companyProfileArray);

    amturstUwquestion(amtrustQueList, amtrustPreparedData);

    prepareAmtrustData(uwquestionPreparedDataa, uwquestionsArray, currentProspect, amtrustPreparedData);

    preparePayrollData(payrollData, payrollDataPrepared, payrollDetailsData);

    data = prepareFinalData(companyProfileArray, uwquestionsArray, payrollDataPrepared);
  } catch (error) {
    console.log("error while processing childrenLoc: ", error);
  }

  return data;
}