import React from "react";

export default function Testimonial(props) {
  return (
    <div
      className="testimonial"
      style={{
        background: `url(${props.greenDesignBg})`,
      }}
    >
      <div className="row">
        <div className="col-md-3">
          <img src={props.whiteGreenMarks} className="quotation-mark" />
        </div>
        <div className="col-md-9 large-col">
          <div className="testimonial-text-container">
            <div className="font-family-open-sans-semi-bold">DAVID CULHANE</div>
            <p className="italic-text-comp font-family-open-sans-italic">
              {`Finance Director,  ${
                process.env.REACT_APP_MODE == "beta" ? "xyz" : "wagamama"
              }`}
            </p>
            <img className="orange-line-img" src={props.orangeLine} />
            <br />
            <br />
            <div className="italic-text font-family-open-sans-italic">
              {`“In the first six months of working with ${
                process.env.REACT_APP_MODE == "beta" ? "PEO" : "Fourth"
              }, we have realized a companywide savings of $40,000 on processing costs and fees alone.Now, Sun Pubs is on track to save $90,000 by the end of the year.”`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
