import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import { Auth } from "aws-amplify";
import {
  validEmail,
  onlyNum,
  validPassword,
  clearErr,
} from "../../../utils/common";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

class FormReset extends Component {
  constructor() {
    super();
    this.state = {
      isOTPSent: false,
      isSubmitted: false,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  forgotPassword() {
    if (!this.state.isOTPSent) {
      if ($("#resetId").val() === "") {
        $("#resetId").css("border-color", "red");
        $("#resetId")
          .siblings(".errMsg")
          .html("Email is required.");
        $("#resetId").focus();
      } else if ($("#resetId").css("border-color") === "red") {
        $("#resetId").focus();
        return false;
      } else {
        $("#loader").css("display", "block");
        var user_name = $("#resetId").val();
        Auth.forgotPassword(user_name.toLowerCase())
          .then((res) => {
            console.log(res);
            this.setState({
              isOTPSent: true,
            });
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            console.log("++", err);
            if (err.code === "LimitExceededException") {
              console.log("limit exceeded exception");
              $("#resetSubmit")
                .siblings(".errMsg")
                .html("Attempt limit exceeded, please try after some time.");
              $("#resetSubmit").focus();
            } else if (
              err.code === "UserNotFoundException" ||
              err.code === "InvalidParameterException"
            ) {
              $("#resetSubmit")
                .siblings(".errMsg")
                .html("User not found.");
              $("#resetSubmit").focus();
            }
            $("#loader").css("display", "none");
          });
      }
    } else if (this.state.isOTPSent) {
      if (this.isValide()) {
        $("#loader").css("display", "block");
        var user_name = $("#resetId").val();
        var password = $("#resetPassword").val();
        var otp = $("#otp").val();
        Auth.forgotPasswordSubmit(user_name.toLowerCase(), otp, password)
          .then((res) => {
            this.setState({
              isSubmitted: true,
            });
            $("#succMsg").css("display", "block");
            $(".rememberPass")
              .children()
              .text("Login");
            $(".rememberPass")
              .children()
              .css({ color: "white", width: "100%" });
            $(".rememberPass").css({
              display: "flex",
              width: "100%",
              background: "#095d98",
              border: "none",
              "border-radius": "4px",
              "box-shadow": "3px 3px 5px #555555",
            });
            console.log("err", res);
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            console.log("err", err);
            if (err.code === "CodeMismatchException") {
              $("#resetSubmit")
                .siblings(".errMsg")
                .html("Invalid verification code provided, please try again.");
              $("#resetSubmit").focus();
            }
            $("#loader").css("display", "none");
          });
      }
    }
  }
  isValide() {
    let flag = true;
    if ($("#resetId").val() === "") {
      $("#resetId").css("border-color", "red");
      $("#resetId")
        .siblings(".errMsg")
        .html("Email is required.");
      $("#resetId").focus();
      flag = false;
    }
    if ($("#resetId").css("border-left-color") === "rgb(255, 0, 0)") {
      $("#resetId").focus();
      flag = false;
    }
    if ($("#otp").val() === "") {
      $("#otp").css("border-color", "red");
      $("#otp")
        .siblings(".errMsg")
        .html("OTP is required.");
      $("#otp").focus();
      flag = false;
    }
    if ($("#otp").css("border-left-color") === "rgb(255, 0, 0)") {
      $("#otp").focus();
      flag = false;
    }
    if ($("#resetPassword").val() === "") {
      $("#resetPassword").css("border-color", "red");
      $("#resetPassword")
        .siblings(".errMsg")
        .html("Please enter a password.");
      $("#resetPassword").focus();
      flag = false;
    }
    if ($("#resetPassword").css("border-left-color") === "rgb(255, 0, 0)") {
      $("#resetPassword").focus();
      flag = false;
    }
    if ($("#resetConfirmPassword").val() === "") {
      $("#resetConfirmPassword").css("border-color", "red");
      $("#resetConfirmPassword")
        .siblings(".errMsg")
        .html("Please confirm your password.");
      $("#resetConfirmPassword").focus();
      flag = false;
    }
    if (
      $("#resetConfirmPassword").css("border-left-color") === "rgb(255, 0, 0)"
    ) {
      $("#resetConfirmPassword").focus();
      flag = false;
    }
    if ($("#resetConfirmPassword").val() !== $("#resetPassword").val()) {
      $("#resetConfirmPassword").css("border-color", "red");
      $("#resetConfirmPassword")
        .siblings(".errMsg")
        .html("Confirm Password and Password must be equal.");
      $("#resetConfirmPassword").focus();
      flag = false;
    }
    return flag;
  }
  render() {
    var { isOTPSent, isSubmitted } = this.state;
    return (
      <div id="fgtpwd" className="tab-pane fade-in">
        <div id="formReset">
          <p
            id="succMsg"
            className="text-center text-success"
            style={{ display: "none" }}
          >
            Your password has been reset successfully! <br />
          </p>
          {!isSubmitted && (
            <span>
              <p className="formTitle text-center">RESET PASSWORD</p>
              <hr />
            </span>
          )}
          {!isSubmitted && (
            <div>
              <form>
                {/* <div> */}
                <span className="errMsg" />
                <input
                  autoComplete="off"
                  id="resetId"
                  type="email"
                  placeholder="Enter Email Address"
                  onBlur={validEmail}
                  onChange={clearErr}
                />
                {/* </div> */}
                {isOTPSent && (
                  <div>
                    <span className="errMsg" />
                    <input
                      autoComplete="off"
                      id="otp"
                      type="text"
                      placeholder="Enter OTP"
                      maxLength="6"
                      minLength="6"
                      onKeyUp={onlyNum}
                      onChange={clearErr}
                    />
                  </div>
                )}
                {isOTPSent && (
                  <div>
                    <span className="errMsg" />
                    <input
                      autoComplete="off"
                      id="resetPassword"
                      type="password"
                      placeholder="Enter Password"
                      onBlur={validPassword}
                      onChange={clearErr}
                    />
                  </div>
                )}
                {isOTPSent && (
                  <div>
                    <span className="errMsg" />
                    <input
                      autoComplete="off"
                      id="resetConfirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      onChange={clearErr}
                    />
                  </div>
                )}
                <div>
                  <span className="errMsg" />
                  <button
                    type="button"
                    id="resetSubmit"
                    className="btnFormFinal button_color"
                    onClick={this.forgotPassword}
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className="formBottomLinks rememberPass text-center">
            <a
              data-toggle="tab"
              href="#lgin"
              aria-expanded="true"
              onClick={() => {
                window.location.reload();
              }}
            >
              Login
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FormReset;
