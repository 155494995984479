import axios from "axios";
import { awsUrl2 } from "../../../../config";
import numeral from "numeral";
import { roundValues } from "../../../../utils/math_functions";
import { getHeader } from "../../../../utils/common";

export const firstCaps = (st) => {
  try {
    return st
      .split(" ")
      .map((e) => {
        if (e && e.trim()) {
          e = e.trim().toLowerCase();
          return e[0].toUpperCase() + e.substring(1);
        }
      })
      .join(" ");
  } catch (error) {
    return st;
  }
};

export const extractSpecialAdminDetails = (rows, additionalData) => {
  let response = {
    rows: [],
    additionalData: {},
  };
  try {
    for (let row of rows) {
      response.rows.push({
        specialWcRate: row.specialWcRate,
        specialSutaLimit: row.specialSutaLimit,
        state: row.state,
        classCode: row.classCode,
        description: row.description,
      });
    }

    response.additionalData = {
      smAdminDiscount: additionalData.smAdminDiscount,
    };
  } catch (error) {
    console.log("error in extract special admin details");
  }
  return response;
};

export const dataToSave = (requestBody) => {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();

    axios
      .post(awsUrl2 + "/api/underWriterData/update", requestBody, header)
      .then((res) => {
        console.log("data saved");
        resolve();
      })
      .catch((err) => {
        console.log("error while saving");
        reject();
      });
  });
};

const calculateFinalValues = (dataObject, specialKey, defaultKey, finalKey) => {
  try {
    let value = dataObject[specialKey];
    let _final = dataObject[defaultKey];
    try {
      _final = Number(roundValues(_final));
    } catch (error) {}

    if (value) {
      let _discountVal = numeral(_final)
        .multiply(value)
        .divide(100)
        .value();
      _final = _final - _discountVal;
    }
    dataObject[finalKey] = _final;
  } catch (error) {
    console.log("error in calculate final values");
  }
};

const calculateDiscountValues = (
  dataObject,
  specialKey,
  defaultKey,
  finalKey
) => {
  try {
    let final_value = dataObject[finalKey];
    let original_value = dataObject[defaultKey];
    let discount_value;
    try {
      original_value = Number(roundValues(original_value));
      final_value = Number(roundValues(final_value));
    } catch (error) {}

    if (final_value) {
      discount_value = numeral(100)
        .subtract(
          numeral(final_value)
            .divide(original_value)
            .multiply(100)
            .value()
        )
        .value();
    }
    dataObject[specialKey] = discount_value;
  } catch (error) {
    console.log("error in calculate final values");
  }
};

export const calculateFinalAdmin = (additionalData) => {
  calculateFinalValues(
    additionalData,
    "smAdminDiscount",
    "originalFinalAdminPercent",
    "finalAdminPercent"
  );
  calculateFinalValues(
    additionalData,
    "smAdminDiscount",
    "originalFinalAdminFee",
    "finalAdminFee"
  );
};

export const calculateAdminDiscount = (additionalData, key) => {
  if (key === "percent") {
    calculateDiscountValues(
      additionalData,
      "smAdminDiscount",
      "originalFinalAdminPercent",
      "finalAdminPercent"
    );
    calculateFinalValues(
      additionalData,
      "smAdminDiscount",
      "originalFinalAdminFee",
      "finalAdminFee"
    );
  } else {
    calculateDiscountValues(
      additionalData,
      "smAdminDiscount",
      "originalFinalAdminFee",
      "finalAdminFee"
    );
    calculateFinalValues(
      additionalData,
      "smAdminDiscount",
      "originalFinalAdminPercent",
      "finalAdminPercent"
    );
  }
};

export const calculateFinalSutaAndWC = (rows) => {
  for (let row of rows) {
    calculateFinalValues(
      row,
      "specialWcRate",
      "originalFinalWcRate",
      "finalWcRate"
    );

    if (row.specialSutaLimit) {
      row.finalSuta = row.specialSutaLimit;
    }
  }
};
