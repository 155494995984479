import React from "react";

export default function OurServices(props) {
  return (
    <div className="our-services m-3">
      <div className="row row-1 row-no-margin">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="our-services-text">Our Services</div>
      </div>
      <div className="row row-2  row-no-margin">
        {props.ourServiceList &&
          props.ourServiceList.map((ele) => {
            return (
              <div className="col col-lg-4 col-md-6 col-sm-12">
                <div className="card shadow-sm rounded ">
                  <div className="card-img-container">
                    <img src={ele.img} className="card-img-top"></img>
                  </div>
                  <div className="card-body">
                    <div className="card-text">{ele.text}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
