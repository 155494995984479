import moment from "moment";

let initialcomment = {
  comment: "",
  commentTimestamp: 0,
  email: "",
};

export const handleUWCommentHistoryButtonClick = (
  data,
  comments,
  submittedDate,
  setModalBody,
  setOpenModal
) => {
  let timestamp = convertToTimestamp(submittedDate)
    if (typeof comments?.[comments?.length-1] === "string") {
        let updatedComment = comments?.slice(0, -1); // Copy all comments except the last one
        updatedComment.push({ // Push the updated last comment
          ...initialcomment,
          comment: comments?.[comments?.length-1],
          commentTimestamp: timestamp,
        });
        setModalBody(updatedComment);
    } else {
      setModalBody(comments);
    }
  setOpenModal(true);
};

const convertToTimestamp = (submittedDate) => {
  let timestamp;
  if (moment(submittedDate, "M/D/YYYY - h:mm A", true)?.isValid()) {
    timestamp = moment(submittedDate, "M/D/YYYY - h:mm A")?.valueOf();
  } else if (moment(submittedDate)?.isValid()) {
    timestamp = moment(submittedDate)?.valueOf();
  } else {
    console.log("Invalid timestamp format");
  }
  return timestamp;
}

export const handleCommentHistory = (rowdata, rowkey, name, setModalBody, setOpenModal) => {
  rowdata.forEach((item) => {
    let submittedDate;
    if(item.preUwDate){
      submittedDate=item.preUwDate
    }else if(item.visitDate){
      submittedDate=item.visitDate
    }else{
      submittedDate = item.submittedDate
    }
    let timestamp = convertToTimestamp(submittedDate)
    if (item.user_email_id || item.salesID || item.domain === rowkey) {
      if (typeof item[name]?.[item[name]?.length-1] === "string") {
        let updatedComment = item[name]?.slice(0, -1); // Copy all comments except the last one
        updatedComment.push({ // Push the updated last comment
          ...initialcomment,
          comment: item[name]?.[item[name]?.length-1],
          commentTimestamp: timestamp,
        });
        setModalBody(updatedComment);
      }
      else {
       setModalBody(item[name]);
      }
    }
  });
  setOpenModal(true);
}