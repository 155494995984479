import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { Auth } from "aws-amplify";
import { isDeepEquals } from "../../utils/isDeepEquals";
import style from "./DealClosedLostButtons.module.css";
import { awsUrl } from "../../config";

function DealClosedLostButtons(props) {
  const [isClosed, setIsClosed] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [recordDetails, setRecordDetails] = useState({});

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res?.attributes?.email) {
          setLoggedInUser(res.attributes.email);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props?.isClosed !== isClosed) {
      setIsClosed(props.isClosed);
    }
  }, [props.isClosed]);

  useEffect(() => {
    if (props?.isLost !== isLost) {
      setIsLost(props.isLost);
    }
  }, [props.isLost]);

  useEffect(() => {
    if (!isDeepEquals(props.recordDetails, recordDetails)) {
      setRecordDetails(props.recordDetails);
    }
  }, [props.recordDetails]);

  let handleButtonClicked = async (button_type) => {
    let api_body = {
      salesId: recordDetails?.salesId || "",
      domain: recordDetails?.domain || "",
      userId: recordDetails?.userId || "",
      uuidList: recordDetails.uuidList || [],
      isMailCmpn: recordDetails.isMailCmpn || false,
      flag: "",
      loggedInUser,
    };

    setIsLoading(true);

    if (button_type === "closed") {
      api_body.flag = isClosed ? "" : "true";
      setIsClosed(!isClosed);
    } else if (button_type === "lost") {
      api_body.flag = isLost ? "" : "false";
      setIsLost(!isLost);
    }

    //updating the state in dashboard so as to update all the tabs
    if (props?.updateDealMap) {
      props.updateDealMap(api_body.salesId || api_body.domain, api_body.flag);
    }

    //api call here
    axios
      .post(awsUrl + "/api/setDealLostClosed", JSON.stringify(api_body))
      .then((res) => {
        // console.log("response from API: ", res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error in API: ", err);
      });
  };

  return (
    <LoadingOverlay active={isLoading}>
      <div className={style.deal_closed_lost_container}>
        <button
          className={
            !isClosed && !isLost
              ? style.button_closed
              : !isClosed
              ? style.hide_button
              : style.button_closed
          }
          disabled={props.disabled}
          onClick={(e) => handleButtonClicked("closed")}
        >
          Won
        </button>
        <button
          className={
            !isClosed && !isLost
              ? style.button_lost
              : !isLost
              ? style.hide_button
              : style.button_lost
          }
          disabled={props.disabled}
          onClick={(e) => handleButtonClicked("lost")}
        >
          Lost
        </button>
      </div>
    </LoadingOverlay>
  );
}

export default DealClosedLostButtons;
