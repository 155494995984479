import React, { Component } from "react";
import { Link } from "react-router-dom";
import { startFresh } from "../../utils/common";

class QuoteButtons extends Component {
  render() {
    let isQuotePage = window.location.pathname === "/Quote";
    return (
      <React.Fragment>
        {!isQuotePage && (
          <Link to={"/Quote"}>
            <li className="h-100 d-flex">
              <button className="btnInvisible">Get Quote</button>
            </li>
          </Link>
        )}
        {isQuotePage && (
          <Link to={"/Quote#loaded"}>
            <li className="h-100 d-flex" onClick={startFresh}>
              <button
                className="btnInvisible btn-quote-continue"
                onClick={startFresh}
              >
                {/* <span className="small" style={{ fontSize: "10px" }}>
                  Start New
                </span>{" "} */}
                Start New Quote
              </button>
            </li>
          </Link>
        )}
        <Link to={"/QuotesHistory"}>
          <li className="h-100 d-flex">
            <button className="btnInvisible btn-view-quotes">
              {/* <span className="small" style={{fontSize: "10px"}}>View Previous</span> Quotes */}
              <span className="nv-link">View Previous Quotes</span>
            </button>
          </li>
        </Link>
        <Link to={"/AdminDashboard"}>
          <li className="h-100 d-flex">
            <button className="btnInvisible btn-view-quotes">
              {/* <span className="small" style={{fontSize: "10px"}}>View Previous</span> Quotes */}
              <span className="nv-link">Dashboard</span>
            </button>
          </li>
        </Link>
      </React.Fragment>
    );
  }
}

export default QuoteButtons;
