let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}
console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://cfvrsdhml2.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://e8srm8034g.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://vzx1icquq4.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://tbykytywg5.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://8zxvil4zo5.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://xd52tp97kg.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL3;
export let awsUrl3 =
  "https://qdfxqxfk5d.execute-api.us-east-1.amazonaws.com/dev";
const awsUrlBeta3 =
  "https://6d8ea4coa9.execute-api.us-east-1.amazonaws.com/beta";
const awsUrlProd3 =
  "https://iz4hsdyrrl.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";
// mode = "local1";
if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  awsUrl3 = awsUrlProd3;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  awsUrl3 = awsUrlBeta3;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.3 (m)";
