import React from "react";

export default function FixedBall(props) {
  return (
    <div
      className="fixed-ball font-family-open-sans-semi-bold button_color"
      onClick={async (e) => {
        // e.defaultPrevented();
        if (props.domain && props.user) {
          await props.handleHomeGetQuoteClicked(props.domain, props.user);
          sessionStorage.clear();
          sessionStorage.setItem("homeCampaign", "true");
          window.location.href = `/Quote/?user=${props.user}&campaignName=${props.campaignName}`;
        } else {
          window.location.href = "/Quote";
        }
      }}
    >
      GET
      <br /> QUOTE NOW
    </div>
  );
}
