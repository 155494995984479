import React, { Component } from "react";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import toast, { Toaster } from "react-hot-toast";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
  clearErrHistoricalClaims,
  getHeader,
} from "../../../utils/common";
import numeral from "numeral";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  handleCheck,
} from "../../../utils/form_cmprRates";
import {
  amtrustResponse,
  salesManagerLoginCheck,
  salesPersonLoginCheck,
  brdLogincheck,
} from "../../../utils/form_ques";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import DropZones from "../../common/DropZones";
import Tabs from "../../common/Tabs";
import NewProgress from "../../../newcomponent/common/NewProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EmodQuestions from "./EmodQuestions";
import Context from "../../../contexts/Context";
import { roundValues } from "../../../utils/math_functions";
import { startLoader, stopLoader } from "../../../utils/loader";
import { json } from "d3";

let isMobile = window.innerWidth < 1000;

class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        peo: [],
        paygo: [],
      },
      visible: false,
      uuid_carrier: [],
      fileURL: undefined,
      peoPaygo: {
        peo: "",
        paygo: "",
      },
      fileURL2: undefined,
      loadingAccord: false,
      isNoLossChckd: false,
      loadingNoLoss: false,
      selectedCarrier: "peo",
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      emodStatesData: {},
      loading: true,
      salespersonName: "",
      salespersonCheck: false,
      loggedIn: false,
      industry_type: "",
      docUploaded: false,
      quoteSubmitted: false,
      carrierSubmittedWith: null,
      amtrustBound: false,
      netRateMap: {},
      salespersonQuoteSubmitted: false,
      salesManagerCheck: false,
      salesManagerPersonName: "",
      carrierStateMap: undefined,
      acordloadtab: {},
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
  }
  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted) {
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
    }

    if (this.props.carrierSubmittedWith !== prevProps.carrierSubmittedWith) {
      this.setState({ carrierSubmittedWith: this.props.carrierSubmittedWith });
    }

    if (this.props.amtrustBound !== prevProps.amtrustBound) {
      this.setState({ amtrustBound: this.props.amtrustBound });
    }

    if (
      this.props.reCalculateData !== prevProps.reCalculateData &&
      this.props.reCalculateData === true
    ) {
      this.setState({ reCalculateData: true });
      await this.onMountFunction();
      this.props.setReCalculateData(false);
    }
  }

  createNetRateMap = (netRatesResponse) => {
    let response = {};
    for (let data of netRatesResponse?.Data) {
      if (!(data?.State in response)) {
        response[data?.State] = {};
      }
      response[data?.State][String(data?.ClassCode).substring(0, 4)] = {
        rate: data?.NetRate || 0,
        description: data?.ClassDescription,
      };
    }

    return response;
  };

  createPayoData = (
    quoteDetailsResponse,
    netRateMap,
    peoStateClassCodeMap,
    industry_type
  ) => {
    let payoData = [];
    let payoEligibility;
    for (let data of quoteDetailsResponse.ClassCodes) {
      let classCode = String(data.ClassCode).substring(0, 4);
      let state = data.State.toLowerCase();
      let obj = {
        state: data.State,
        wc_code: classCode,
        wc_desc:
          netRateMap[data.State] && netRateMap[data.State][classCode]
            ? netRateMap[data.State][classCode].description
            : "-",
        fica:
          peoStateClassCodeMap[state] && peoStateClassCodeMap[state][classCode]
            ? peoStateClassCodeMap[state][classCode].fica
            : 0,
        futa:
          peoStateClassCodeMap[state] && peoStateClassCodeMap[state][classCode]
            ? peoStateClassCodeMap[state][classCode].futa
            : 0,
        suta:
          industry_type === "hospitality"
            ? peoStateClassCodeMap[state] &&
              peoStateClassCodeMap[state][classCode]
              ? peoStateClassCodeMap[state][classCode].suta
              : 0
            : "TBD",
        work_comp: netRateMap[data.State]
          ? netRateMap[data.State][classCode].rate * 100
          : "UW",
        net_rate: netRateMap[data.State]
          ? netRateMap[data.State][classCode].rate * 100
          : "UW",
        admin:
          industry_type === "hospitality"
            ? peoStateClassCodeMap[state] &&
              peoStateClassCodeMap[state][classCode]
              ? peoStateClassCodeMap[state][classCode].admin
              : 0
            : "TBD",
        base_rate: 0,
        limit: 0,
      };
      for (let key of ["fica", "futa", "suta", "work_comp", "admin"]) {
        if (obj[key] && !isNaN(obj[key])) obj.base_rate += Number(obj[key]);
      }
      for (let key of ["fica", "work_comp", "admin"]) {
        if (obj[key] && !isNaN(obj[key])) obj.limit += Number(obj[key]);
      }
      payoData.push(obj);
    }

    payoEligibility = quoteDetailsResponse?.Eligibility;

    return { payoData, payoEligibility };
  };

  sortQuoteTableData = (a, b) => {
    if (a.state > b.state) {
      return 1;
    } else if (a.state < b.state) {
      return -1;
    } else if (a.wc_code > b.wc_code) {
      return 1;
    } else if (a.wc_code < b.wc_code) {
      return -1;
    } else {
      return 0;
    }
  };

  onMountFunction = async () => {
    try {
      let {
        salesManagerPersonName,
        salesManagerCheck,
        salesManagerPersonNameAttr,
      } = await salesManagerLoginCheck();
      let {
        brdPersonName,
        brdPersonNameAttr,
        brdCheck,
      } = await brdLogincheck();

      if (brdCheck) {
        this.setState({
          brdCheck,
          brdPersonName,
        });
      }

      if (salesManagerCheck) {
        this.setState({
          salesManagerCheck,
          salesManagerPersonName,
        });
      }
      let isLoggedIn,
        isAdminSalesManager = false;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
        if (isLoggedIn) {
          let groups =
            isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
          if (
            groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP) &&
            groups.includes(process.env.REACT_APP_ADMIN_GROUP)
          )
            isAdminSalesManager = true;
        }
      } catch (error) {
        console.log("Authentication error here", error);
      }
      $("html, body").animate({ scrollTop: 0 }, 250);
      let {
        historicalClaims,
        historicalClaimsCheck,
        isNoLossChckd,
        startDate,
        salespersonQuoteSubmitted,
        selectedstates,
      } = this.state;
      let { data } = JSON.parse(JSON.stringify(this.state));

      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );

      let primaryKey = sessionStorage.getItem("primaryKey");

      let industry_type, clientEligibility;
      let producerDetails = {
        producerName: isLoggedIn?.attributes.name || " ",
        producerEmail: isLoggedIn?.attributes.email || " ",
        producerFax: isLoggedIn?.attributes["custom:fax"] || " ",
        producerPhone: isLoggedIn?.attributes["custom:phoneNo."] || " ",
        producerMobile: isLoggedIn?.attributes["custom:mobile"] || " ",
      };
      this.setState({ producerDetails });
      let salesID = sessionStorage.getItem("salesID");
      let { salespersonName, salespersonCheck } = await salesPersonLoginCheck();
      this.setState({
        salespersonName,
        salespersonCheck,
        loggedIn: isLoggedIn ? true : false,
      });

      if (currProspectDetails) {
        let { emodStatesData } = currProspectDetails;
        this.setState({ emodStatesData });
      }
      var formStage = sessionStorage.getItem("formStage");
      console.log("Form stage: ", formStage);
      if (
        formStage === "five" ||
        formStage === "six" ||
        formStage === "three" ||
        formStage === "four"
      ) {
        $("#loader").css("display", "block");
        // if (!this.state.fileURL)
        //   await this.generateAccordAndStore(producerDetails);
        let currProspectDetails = JSON.parse(
          sessionStorage.getItem("currProspect")
        );
        let domain = sessionStorage.getItem("domain");

        let amtrustAccountDetails = sessionStorage.getItem(
          "amtrustAccountDetails"
        );

        try {
          amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
        } catch (error) {
          amtrustAccountDetails = {};
        }

        if (currProspectDetails !== null) {
          let user_email = currProspectDetails.companyProfile.companyName.value;
          let primay_key = primaryKey
            ? primaryKey
            : domain
            ? domain
            : (salespersonCheck && salesID) || salesID
            ? salesID.toLowerCase()
            : currProspectDetails.companyProfile &&
              currProspectDetails.companyProfile.companyName &&
              currProspectDetails.companyProfile.companyName.value
            ? currProspectDetails.companyProfile.companyName.value.toLowerCase()
            : "";
          axios
            .get(awsUrl + "/api/getUserDataStatus/" + primay_key + "/quote")
            .then(async (response) => {
              let peoStateClassCodeMap = {};
              let dataFromAPI = response.data;
              let peoData = [];
              let total_payroll_api = 0;
              let toal_employee_api = 0;
              let fileListToStore;
              let quoteSubmitted;
              let carrierSubmittedWith;
              let amtrustBound;
              let adminCalc = {};
              let isSalesforce;

              let salesManagerData = {
                rows: [],
                additionalData: {
                  requestedAdmin: "",
                  toolcalculatedAdmin: "",
                  smAdminDiscount: "",
                  smApprovalLimit: "",
                  finalAdmin: "",
                  blendedFee: "",
                  type: "",
                  finalAdminFee: "",
                  finalAdminPercent: "",
                },
              };

              let salesforceData = {
                id: "",
                fein: "",
                years_of_operation: "",
                peo_fee_type: "",
                peo_admin_fee: "",
                total_employees: "",
                gross_wages: "",
                peo_admin_percent_rate: "",
                broker_name: "",
                workers_comp: "",
                price: "",
                percent_or_amount: "percent",
                discount_percent: "",
                discount_amount: "",
              };

              let specialAdmin = {
                admin: 10,
                wc_rate: 3,
                suta: {
                  min: 2.1,
                  max: 2.75,
                },
              };

              let StateCarrier = [];
              let stateSpecific;
              if (dataFromAPI.carrierBasedData) {
                let firstCarrierObject =
                  dataFromAPI.carrierBasedData[
                    Object.keys(dataFromAPI.carrierBasedData)[0]
                  ];
                try {
                  if (firstCarrierObject) {
                    let firstLineObject =
                      firstCarrierObject[Object.keys(firstCarrierObject)[0]];

                    // sessionStorage.setItem(
                    //   "nolossGenerated",
                    //   firstLineObject.nolossGenerated || false
                    // );

                    this.setState({
                      isNoLossChckd:
                        firstLineObject.nolossGenerated ||
                        firstLineObject.isNoLossChckd ||
                        false,
                    });

                    if (firstLineObject?.dealConfirmed === "true") {
                      sessionStorage.setItem("hideButtons", "true");
                    }

                    // FOR CARRIER STATE SPECIFIC

                    for (let key in firstCarrierObject) {
                      let carrier_location_data =
                        firstCarrierObject[key]["carrier_location_data"];
                      console.log(
                        StateCarrier.push({
                          carrier: carrier_location_data["carrier"],
                          state: carrier_location_data["state"],
                        })
                      );
                    }
                    let map = new Map();
                    for (let index = 0; index < StateCarrier.length; index++) {
                      if (map.has(StateCarrier[index].carrier)) {
                        let value = map.get(StateCarrier[index].carrier);
                        map.set(
                          StateCarrier[index].carrier,
                          value + "," + StateCarrier[index].state
                        );
                      } else {
                        map.set(
                          StateCarrier[index].carrier,
                          StateCarrier[index].state
                        );
                      }
                    }
                    let stateArray = [];
                    let carrierArray = [];
                    for (let [key, value] of map) {
                      stateArray.push(value);
                      carrierArray.push(key);
                    }
                    for (let index = 0; index < stateArray.length; index++) {
                      stateArray[index] = stateArray[index].split(",");
                    }
                    let CarrierStateObject = {};
                    for (let index = 0; index < stateArray.length; index++) {
                      CarrierStateObject[carrierArray[index]] =
                        stateArray[index];
                    }
                    this.setState({ carrierStateMap: CarrierStateObject });

                    // end the created the carreir state.

                    if (firstLineObject) {
                      if (firstLineObject.isSalesforce) {
                        let totalEmployees = 0;
                        let grossWages = 0;
                        for (let locIndex in firstLineObject.prospect_addresses) {
                          for (let ccIndex in firstLineObject
                            .prospect_addresses[locIndex].classCodes) {
                            let {
                              ft,
                              pt,
                              payroll,
                            } = firstLineObject.prospect_addresses[
                              locIndex
                            ].classCodes[ccIndex];
                            totalEmployees += Number(ft) + Number(pt);
                            grossWages += Number(payroll);
                          }
                        }
                        salesforceData = {
                          ...salesforceData,
                          id: firstLineObject.sfQuoteId?.trim() || "",
                          fein: firstLineObject.fein?.trim() || "",
                          years_of_operation:
                            firstLineObject?.currProspectDetails?.companyProfile?.yearsInBusiness?.value?.trim() ||
                            "",
                          total_employees: String(totalEmployees)?.trim(),
                          gross_wages: String(grossWages)?.trim(),
                          broker_name: firstLineObject?.currProspectDetails
                            ?.brokerDetails?.brokerQuestionValue
                            ? firstLineObject.currProspectDetails?.brokerDetails?.brokerdropdownSelected?.label?.trim() ||
                              ""
                            : "",
                        };
                        isSalesforce = true;
                        sessionStorage.setItem("isSalesforce", true);
                        sessionStorage.setItem(
                          "sfQuoteId",
                          firstLineObject.sfQuoteId
                        );
                      }
                      if (firstLineObject.preUwFiles)
                        sessionStorage.setItem(
                          "preUwFiles",
                          JSON.stringify(firstLineObject.preUwFiles)
                        );

                      if (firstLineObject.dashboardFiles)
                        sessionStorage.setItem(
                          "dashboardFiles",
                          JSON.stringify(firstLineObject.dashboardFiles)
                        );

                      if (firstLineObject.uwAmtrustQuesList)
                        sessionStorage.setItem(
                          "uwAmtrustQuesList",
                          JSON.stringify(firstLineObject.uwAmtrustQuesList)
                        );

                      if (firstLineObject.uwQuesList)
                        sessionStorage.setItem(
                          "uwQuesList",
                          JSON.stringify(firstLineObject.uwQuesList)
                        );

                      let currProspectDetailsAPI =
                        firstLineObject.currProspectDetails;

                      let requestedPricing =
                        currProspectDetailsAPI.requestedPricing;
                      let specialAdminData = firstLineObject.specialAdminData;

                      if (requestedPricing) {
                        if (requestedPricing.checked === true) {
                          salesManagerData.additionalData.requestedAdmin =
                            requestedPricing.adminPercent;
                          salesManagerData.additionalData.toolcalculatedAdmin =
                            firstLineObject.carrier_location_data.tool_admin_percent;
                          salesManagerData.additionalData.type = "percent";
                        }
                        if (requestedPricing.checked === false) {
                          salesManagerData.additionalData.requestedAdmin = requestedPricing.perCKdollar.split(
                            "$"
                          )[1];
                          salesManagerData.additionalData.toolcalculatedAdmin =
                            firstLineObject.carrier_location_data.tool_admin_fee;
                          //TODO
                          if (
                            firstLineObject.carrier_location_data
                              ?.admin_type_values
                          ) {
                            this.setState({
                              admin_type_values:
                                firstLineObject.carrier_location_data
                                  .admin_type_values,
                            });
                          }

                          salesManagerData.additionalData.type = "dollar";
                        }

                        salesManagerData.additionalData.finalAdminFee =
                          firstLineObject.carrier_location_data.admin_fee;
                        salesManagerData.additionalData.finalAdminPercent =
                          firstLineObject.carrier_location_data.admin_percent;
                        salesManagerData.additionalData.originalFinalAdminFee =
                          firstLineObject.carrier_location_data.admin_fee;
                        salesManagerData.additionalData.originalFinalAdminPercent =
                          firstLineObject.carrier_location_data.admin_percent;
                      }

                      if (firstLineObject.carrier_location_data.specialLimits) {
                        salesManagerData.additionalData.smApprovalLimit = !isAdminSalesManager
                          ? firstLineObject.carrier_location_data.specialLimits
                              .admin
                          : Number.MAX_SAFE_INTEGER;

                        specialAdmin.admin = !isAdminSalesManager
                          ? firstLineObject.carrier_location_data.specialLimits
                              .admin
                          : Number.MAX_SAFE_INTEGER;
                        specialAdmin.wc_rate = !isAdminSalesManager
                          ? firstLineObject.carrier_location_data.specialLimits
                              .wc_rate
                          : Number.MAX_SAFE_INTEGER;
                      }

                      if (firstLineObject.historicalClaims) {
                        historicalClaims = firstLineObject.historicalClaims;
                      }
                      if (firstLineObject.historicalClaimsCheck) {
                        historicalClaimsCheck =
                          firstLineObject.historicalClaimsCheck;
                      }
                      // if (firstLineObject.isNoLossChckd) {
                      //   isNoLossChckd = firstLineObject.isNoLossChckd;
                      // }
                      if (firstLineObject.startDate) {
                        startDate = moment(
                          firstLineObject.startDate,
                          "YYYY-MM-DD"
                        );
                      }
                      if (firstLineObject.quoteSubmitted) {
                        quoteSubmitted = true;
                      }

                      if (firstLineObject.bindCarrier) {
                        carrierSubmittedWith = firstLineObject.bindCarrier;
                      }

                      if (firstLineObject.amtrustBound) {
                        amtrustBound = true;
                      }

                      if (firstLineObject.salespersonQuoteSubmitted) {
                        salespersonQuoteSubmitted = true;
                      }
                      if (currProspectDetailsAPI) {
                        let childrenLoc = currProspectDetailsAPI.childrenLoc;
                        for (let key in childrenLoc) {
                          let ccInfo = childrenLoc[key].classCodesInfo;
                          for (let keyIndx in ccInfo) {
                            let ccInfoObj = ccInfo[keyIndx];
                            if (ccInfoObj.payroll && ccInfoObj.payroll.value) {
                              total_payroll_api = numeral(total_payroll_api)
                                .add(numeral(ccInfoObj.payroll.value).value())
                                .value();
                            }
                            if (ccInfoObj.ft && ccInfoObj.ft.value) {
                              toal_employee_api = numeral(toal_employee_api)
                                .add(numeral(ccInfoObj.ft.value).value())
                                .value();
                            }
                            if (ccInfoObj.pt && ccInfoObj.pt.value) {
                              toal_employee_api = numeral(toal_employee_api)
                                .add(numeral(ccInfoObj.pt.value).value())
                                .value();
                            }
                          }
                        }
                      }
                    }
                  }
                } catch (error) {
                  console.log("++error", error);
                }
              }

              // let testObj = {};

              // testObj.smAdminDiscount = salesManagerData.additionalData.smAdminDiscount;
              // testObj.toolcalculatedAdmin = salesManagerData.additionalData.toolcalculatedAdmin;
              // testObj.finalAdmin = "";

              // calculateFinalValues(testObj, "smAdminDiscount", "toolcalculatedAdmin", "finalAdmin");

              let amtrustNetRatesResponse = {};
              let amtrustQuoteDetailsResponse = {};
              if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
                // get amtrust quote details
                try {
                  let amtrustQuoteDetailsTriggerResponse = await axios.get(
                    awsUrl2 +
                      `/api/triggerAmtrustGetQuoteDetails/${amtrustAccountDetails.QuoteId}`
                  );

                  let quoteDetailsUuid;
                  if (amtrustQuoteDetailsTriggerResponse.data.uuid) {
                    quoteDetailsUuid =
                      amtrustQuoteDetailsTriggerResponse.data.uuid;
                  } else {
                    throw "error in amtrust create quote trigger";
                  }

                  do {
                    amtrustQuoteDetailsResponse = await amtrustResponse(
                      quoteDetailsUuid,
                      "getQuoteDetails"
                    );
                    if (amtrustQuoteDetailsResponse === "error") {
                      throw "error in amtrust get net rates";
                    }
                  } while (amtrustQuoteDetailsResponse === "processing");
                } catch (error) {
                  console.log(
                    "error in fetching amtrust quote details response",
                    error
                  );
                }

                // get amtrust quote net rates
                try {
                  let amtrustTriggerResponse = await axios.get(
                    awsUrl2 +
                      `/api/triggerAmtrustGetNetRates/${amtrustAccountDetails.QuoteId}`
                  );

                  let netRatesUuid;
                  if (amtrustTriggerResponse.data.uuid) {
                    netRatesUuid = amtrustTriggerResponse.data.uuid;
                  } else {
                    throw "error in amtrust create quote trigger";
                  }

                  do {
                    amtrustNetRatesResponse = await amtrustResponse(
                      netRatesUuid,
                      "getNetRates"
                    );
                    // console.log(amtrustNetRatesResponse);
                    if (amtrustNetRatesResponse === "error") {
                      throw "error in amtrust get net rates";
                    }
                  } while (amtrustNetRatesResponse === "processing");

                  console.log(
                    "amtrustNetRatesResponse",
                    amtrustNetRatesResponse
                  );
                } catch (error) {
                  console.log(
                    "error in fetching amtrust net rates response",
                    error
                  );
                }
              }

              // console.log("++", amtrustNetRatesResponse);
              // console.log("++", amtrustQuoteDetailsResponse);

              // create net rate map
              let netRateMap;
              try {
                netRateMap = this.createNetRateMap(amtrustNetRatesResponse);
                // console.log("++netratemap", netRateMap);
              } catch (error) {
                console.log("error here", error);
                netRateMap = {};
              } finally {
                this.setState({ netRateMap });
              }

              for (let carrier in dataFromAPI.carrierBasedData) {
                let carrObj = dataFromAPI.carrierBasedData[carrier];
                for (let lineIndx in carrObj) {
                  let lineObj = carrObj[lineIndx];

                  if (!fileListToStore && lineObj.fileListToStore) {
                    fileListToStore = lineObj.fileListToStore;
                  }

                  let carrier_location_data = lineObj.carrier_location_data;
                  industry_type = carrier_location_data?.ind_type;
                  clientEligibility = lineObj.clientEligibility || "approved";

                  let adminType = lineObj?.currProspectDetails?.requestedPricing
                    ?.checked
                    ? "percent"
                    : "percheck";

                  this.setState({
                    industry_type,
                    clientEligibility,
                    adminType,
                  });

                  let specialAdminData = lineObj.specialAdminData;
                  specialAdmin.suta = !isAdminSalesManager
                    ? lineObj.carrier_location_data?.specialLimits?.suta || {
                        min: 0,
                        max: 0,
                      }
                    : {
                        min: 0,
                        max: Number.MAX_SAFE_INTEGER,
                      };
                  salesManagerData.additionalData.smAdminDiscount =
                    specialAdminData?.additionalData?.smAdminDiscount || "";
                  let dbSpecialAdminRows = specialAdminData?.rows || [];

                  if (carrier_location_data) {
                    for (let eIndx in carrier_location_data.employees) {
                      let obj = {
                        state: "",
                        wc_code: "",
                        wc_desc: "",
                        fica: "",
                        futa: "",
                        suta: "",
                        work_comp: "",
                        admin: "",
                        base_rate: 0,
                        limit: 0,
                        net_rate: "UW",
                      };

                      let row = {
                        state: carrier_location_data?.state,
                        classCode:
                          carrier_location_data?.employees?.[eIndx]?.code || "",
                        description:
                          carrier_location_data?.employees?.[eIndx]?.desc || "",
                        requestedAdmin:
                          (lineObj?.requestedPricing?.checked
                            ? lineObj?.requestedPricing?.adminPercent
                            : lineObj?.requestedPricing?.perCKdollar) || "",
                        admin: "",
                        specialAdmin: "",
                        requestedWcRate:
                          carrier_location_data?.employees?.[eIndx]?.pricing ||
                          "",
                        wcRate: "",
                        specialWcRate: "",
                        requestedSuta:
                          carrier_location_data?.requested_suta || "",
                        sutaLimit: {
                          start: specialAdmin.suta.min,
                          end: specialAdmin.suta.max,
                        },
                        specialSutaLimit: "",
                        finalSuta: "",
                        wcRateLimit: specialAdmin.wc_rate,
                        finalWcRate: "",
                      };

                      if (
                        row?.sutaLimit?.start === "client" ||
                        row?.sutaLimit?.end === "client"
                      ) {
                        row.sutaLimitValue = "CLIENT SUTA";
                      } else if (
                        row?.sutaLimit?.start != null &&
                        row?.sutaLimit?.end != null
                      ) {
                        if (row?.sutaLimit?.end === Number.MAX_SAFE_INTEGER)
                          row.sutaLimitValue = "-";
                        else
                          row.sutaLimitValue =
                            roundValues(row?.sutaLimit?.start) +
                            "%" +
                            " - " +
                            roundValues(row?.sutaLimit?.end) +
                            "%";
                      } else {
                        row.sutaLimitValue = "-";
                      }

                      salesManagerData.rows.push(row);

                      let currObj = JSON.parse(JSON.stringify(obj));

                      currObj.state = carrier_location_data.state;
                      currObj.wc_code =
                        carrier_location_data.employees[eIndx].code;
                      currObj.wc_desc =
                        carrier_location_data.employees[eIndx].desc;
                      currObj.fica = carrier_location_data.fica
                        ? carrier_location_data.fica
                        : "0";
                      currObj.futa = carrier_location_data.futa
                        ? carrier_location_data.futa
                        : "0";
                      currObj.suta = carrier_location_data.suta
                        ? isNaN(carrier_location_data.suta)
                          ? carrier_location_data.suta
                          : carrier_location_data.suta * 100
                        : "0";
                      currObj.work_comp =
                        carrier_location_data?.manual_rate_values?.[
                          carrier_location_data.employees[eIndx].code
                        ];

                      if (adminType === "percent") {
                        currObj.admin = carrier_location_data.admin_percent
                          ? carrier_location_data.admin_percent
                          : 0;
                      } else {
                        currObj.admin = carrier_location_data.admin_fee
                          ? carrier_location_data.admin_fee
                          : 0;
                      }

                      if (specialAdminData?.additionalData?.smAdminDiscount) {
                        try {
                          let discount =
                            specialAdminData?.additionalData?.smAdminDiscount;
                          let roundCurrAdmin = currObj.admin.toFixed(2);
                          let dis_val = numeral(roundCurrAdmin)
                            .multiply(discount)
                            .divide(100)
                            .value();
                          currObj.admin = roundCurrAdmin - dis_val;

                          if (
                            carrier_location_data?.admin_type_values &&
                            adminType !== "percent"
                          ) {
                            //recalculate admin_type_values
                            let admin_coefficient = {
                              a: { key: "weekly_rate", val: 52 },
                              b: { key: "bi_weekly", val: 26 },
                              c: { key: "semi_monthly", val: 24 },
                              d: { key: "monthly", val: 12 },
                            };

                            let allTypeAdminValues = JSON.parse(
                              JSON.stringify(
                                carrier_location_data?.admin_type_values
                              )
                            );

                            // apply discount to the admin values
                            for (let c in admin_coefficient) {
                              let adminDetailOj = admin_coefficient[c];

                              let adminFee_re = Number(
                                numeral(
                                  allTypeAdminValues[adminDetailOj.key].fee
                                )
                                  .multiply(100 - discount)
                                  .divide(100)
                                  .value()
                                  .toFixed(2)
                              );

                              let percent = Number(
                                numeral(
                                  allTypeAdminValues[adminDetailOj.key].percent
                                )
                                  .multiply(100 - discount)
                                  .divide(100)
                                  .value()
                                  .toFixed(2)
                              );

                              allTypeAdminValues[adminDetailOj.key] = {
                                fee: adminFee_re,
                                percent: percent,
                              };
                            }
                            this.setState({
                              admin_type_values: allTypeAdminValues,
                            });
                          }
                        } catch (error) {}
                      }

                      // currObj.admin = testObj.finalAdmin ? testObj.finalAdmin : row.admin;
                      if (!salesforceData.peo_fee_type) {
                        salesforceData = {
                          ...salesforceData,
                          peo_fee_type:
                            adminType === "percent"
                              ? "Percent of Gross"
                              : adminType === "percheck"
                              ? "Per Check"
                              : "",
                          discount_percent:
                            salesManagerData?.additionalData?.smAdminDiscount ||
                            "",
                        };
                      }
                      if (!salesforceData.price) {
                        salesforceData = {
                          ...salesforceData,
                          price:
                            (carrier_location_data?.admin_cost &&
                              !isNaN(carrier_location_data.admin_cost) &&
                              numeral(carrier_location_data.admin_cost)
                                .divide(12)
                                .value()
                                .toFixed(2)) ||
                            "",
                        };
                      }

                      if (
                        carrier_location_data.net_rate_values &&
                        carrier_location_data.net_rate_values?.[currObj.wc_code]
                      ) {
                        currObj.net_rate =
                          carrier_location_data.net_rate_values[
                            currObj.wc_code
                          ];
                      }

                      if (
                        carrier_location_data.tool_net_rate_values &&
                        row.classCode in
                          carrier_location_data.tool_net_rate_values
                      ) {
                        row.wcRate =
                          carrier_location_data.tool_net_rate_values[
                            row.classCode
                          ];
                      }

                      row.finalSuta = currObj.suta;
                      row.finalWcRate = currObj.net_rate;

                      row.originalFinalWcRate = row.finalWcRate;

                      for (let dbSpecialAdminRow of dbSpecialAdminRows) {
                        if (
                          dbSpecialAdminRow.state ===
                            carrier_location_data.state &&
                          dbSpecialAdminRow.classCode ===
                            carrier_location_data.employees[eIndx].code &&
                          dbSpecialAdminRow.description ===
                            carrier_location_data.employees[eIndx].desc
                        ) {
                          row.specialSutaLimit =
                            dbSpecialAdminRow.specialSutaLimit;
                          row.specialWcRate = dbSpecialAdminRow.specialWcRate;

                          if (row.specialSutaLimit) {
                            currObj.suta = row.specialSutaLimit;
                          }

                          if (row.specialWcRate) {
                            try {
                              let roundNetRate = currObj.net_rate.toFixed(2);
                              let discVal = numeral(roundNetRate)
                                .multiply(row.specialWcRate)
                                .divide(100)
                                .value();
                              currObj.net_rate = roundNetRate - discVal;
                            } catch (error) {}
                          }
                          break;
                        }
                      }

                      for (let key of [
                        "fica",
                        "futa",
                        "suta",
                        "net_rate",
                        "admin",
                      ]) {
                        if (currObj[key] && !isNaN(currObj[key])) {
                          currObj.base_rate += Number(currObj[key]);
                        }
                      }
                      for (let key of ["fica", "net_rate", "admin"]) {
                        if (currObj[key] && !isNaN(currObj[key]))
                          currObj.limit += Number(currObj[key]);
                      }

                      peoData.push(currObj);
                      if (!(currObj.state in peoStateClassCodeMap)) {
                        peoStateClassCodeMap[currObj.state] = {};
                      }
                      peoStateClassCodeMap[currObj.state][
                        String(currObj.wc_code).padStart(4, "0")
                      ] = currObj;
                    }
                  }
                  if (lineObj.doc_status === "uploaded") {
                    this.handleDocUploaded(true);
                  }
                }
              }

              this.props.setSalesManagerData(salesManagerData);

              data.peo = peoData.sort((a, b) => this.sortQuoteTableData(a, b));
              if (!this.state.fileURL)
                await this.generateAccordAndStore(producerDetails);

              // create payo data
              try {
                let { payoData, payoEligibility } = this.createPayoData(
                  amtrustQuoteDetailsResponse,
                  netRateMap,
                  peoStateClassCodeMap,
                  industry_type
                );

                if (payoEligibility) {
                  this.setState({ payoEligibility });
                }
                data.paygo = payoData.sort(this.sortQuoteTableData);
              } catch (error) {
                console.log("error", error);
                data.paygo = [];
              }

              this.setState({
                data,
                historicalClaims,
                historicalClaimsCheck,
                startDate,
              });

              if (isSalesforce) {
                this.setState({ salesforceData, isSalesforce });
              }

              if (fileListToStore) {
                let fileList = {
                  first: {},
                  second: {},
                };
                for (let row in fileListToStore) {
                  if (!fileList[fileListToStore[row].keyToFile])
                    fileList[fileListToStore[row].keyToFile] = {};
                  fileList[fileListToStore[row].keyToFile][
                    fileListToStore[row].key.split("/").reverse()[0]
                  ] = "#empty";
                }
                this.setState({ fileList });
              }

              let blockAllButtons = salespersonCheck
                ? salespersonQuoteSubmitted
                : quoteSubmitted;

              if (blockAllButtons) {
                this.props.setQuoteSubmittedStatus(true);
              }

              if (carrierSubmittedWith) {
                this.props.setCarrierSubmittedWith(carrierSubmittedWith);
              }

              if (amtrustBound) {
                this.props.setAmtrustBoundStatus(true);
              }

              if (salespersonQuoteSubmitted) {
                sessionStorage.setItem("salespersonQuoteSubmitted", "true");
                this.setState({ salespersonQuoteSubmitted: true });
              }
              $("#loader").css("display", "none");
            })
            .catch((err) => {
              console.log("error: ", err);
              // showErrAlert(err);
              $("#loader").css("display", "none");
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else {
          sessionStorage.setItem("formStage", "one");
          deleteAllCookies();
          sessionStorage.clear();
          window.location.reload();
        }
      }
    } catch (error) {
      console.log("HERE IS THE ERROR", error);
    }
  };

  async componentDidMount() {
    await this.onMountFunction();
  }

  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          // showErrAlert("View Quote is not available due to error");
        });
    });
  }

  updateAcordSelectedState = (tabName, state) => {
    let { acordloadtab } = JSON.parse(JSON.stringify(this.state));
    let selectedtabName = tabName.split(" ")[0].toLowerCase();
    acordloadtab[selectedtabName] = state;
    this.setState({ acordloadtab });
  };

  generateAccordAndStore = async (producerDetails) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let address = sessionStorage.getItem("address")
      ? JSON.parse(sessionStorage.getItem("address"))
      : undefined;
    let quoteData = sessionStorage.getItem("quoteData")
      ? JSON.parse(sessionStorage.getItem("quoteData"))
      : undefined;

    let primaryKey = sessionStorage.getItem("primaryKey");

    let salesID = sessionStorage.getItem("salesID");
    let {
      salespersonName,
      salespersonCheck,
      netRateMap,
      selectedCarrier,
      producerDetails: producer,
      peoPaygo,
      carrierStateMap,
      acordloadtab,
    } = this.state;
    JSON.parse(JSON.stringify(this.state));

    let domain = sessionStorage.getItem("domain");

    //to call api with this check

    let statelistMap =
      carrierStateMap["carrier_at"] ||
      carrierStateMap["carrier_ar"] ||
      carrierStateMap["carrier_p"];

    let nccistateCheck = false;
    for (let states of statelistMap) {
      if (states !== "fl" && states !== "ca") {
        nccistateCheck = true;
        break;
      }
    }
    if (statelistMap.includes("fl")) {
      acordloadtab[selectedCarrier] = "FL";
    }
    if (statelistMap.includes("ca")) {
      acordloadtab[selectedCarrier] = "CA";
    }
    if (nccistateCheck) {
      acordloadtab[selectedCarrier] = "Ncci";
    }

    //

    let childrenLoc = currProspect.childrenLoc;
    let stateList = [];
    for (let childrenLocIndx in childrenLoc) {
      let childrenLocObj = childrenLoc[childrenLocIndx];
      if (childrenLocObj.state && childrenLocObj.state.value) {
        stateList.push(childrenLocObj.state.value);
      }
    }

    let primay_key = primaryKey
      ? primaryKey
      : domain
      ? domain
      : (salespersonCheck && salesID) || salesID
      ? salesID.toLowerCase()
      : currProspect.companyProfile &&
        currProspect.companyProfile.companyName &&
        currProspect.companyProfile.companyName.value
      ? currProspect.companyProfile.companyName.value.toLowerCase()
      : "";

    if (currProspect && address && quoteData) {
      let etQuoteDetails = {
        email: primay_key,
        timestamp: quoteData.date,
        carrier: `carrier_p`,
        mail_address: "",
        address: address,
        state: stateList,
        producerDetails: producerDetails ? producerDetails : producer,
        netRateMap: selectedCarrier === "peo" ? {} : netRateMap,
      };
      console.log("inside generateAccordAndStore");
      this.setState({ loadingAccord: true });

      peoPaygo[selectedCarrier] = {};

      if (statelistMap.includes("ca")) {
        axios
          .post(
            awsUrl2 + "/api/generateAcord/ca",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            // console.log("accord result", res);
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);

            var fileURL = URL.createObjectURL(file);

            peoPaygo[selectedCarrier]["CA"] = fileURL;

            this.setState({ peoPaygo, loadingAccord: false });
          })
          .catch((err) => {
            console.log("Err", err);
            // showErrAlert("View Quote is not available due to error.");
            this.setState({ loadingAccord: false });
          });
      }

      if (statelistMap.includes("fl")) {
        axios
          .post(
            awsUrl2 + "/api/generateAcord/fl",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            // console.log("accord result", res);
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);

            var fileURL = URL.createObjectURL(file);

            peoPaygo[selectedCarrier]["FL"] = fileURL;

            this.setState({ peoPaygo, loadingAccord: false });
          })
          .catch((err) => {
            console.log("Err", err);
            // showErrAlert("View Quote is not available due to error.");
            this.setState({ loadingAccord: false });
          });
      }

      if (nccistateCheck) {
        axios
          .post(
            awsUrl2 + "/api/generateAcord/ncci",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            // console.log("accord result", res);
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);

            var fileURL = URL.createObjectURL(file);
            peoPaygo[selectedCarrier]["Ncci"] = fileURL;

            this.setState({ peoPaygo, loadingAccord: false });
          })
          .catch((err) => {
            console.log("Err", err);
            // showErrAlert("View Quote is not available due to error.");
            this.setState({ loadingAccord: false });
          });
      }
    }
  };

  generateNoLoss = async (date) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });
      let cName = `${currProspect.companyProfile.firstName.value} ${currProspect.companyProfile.lastName.value}`;
      let dFrom = "";

      try {
        dFrom = moment(date).format("YYYY-MM-DD");
      } catch (error) {}

      await axios
        .post(
          awsUrl2 + "/api/getNoLossData",
          JSON.stringify({
            company: currProspect.companyProfile.companyName.value,
            name: cName,
            dFrom,
            fein: currProspect.companyProfile.fein.value,
          })
        )
        .then(async (res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);

          // let dataToSend = {
          //   user_email_id: currProspect.companyProfile.emailId.value,
          //   uuid_carrier: sortKeyList[0],
          //   nolossGenerated: true,
          //   dFrom: date,
          // };
          // await axios
          //   .post(awsUrl2 + "/api/updateSolveUserStatusTable", dataToSend)
          //   .then((res) => {})
          //   .catch((error) => {
          //     console.log("error in API-updateSolveUserStatusTable", error);
          //   });
          this.setState({ fileURL2 });
          this.setState({ loadingNoLoss: false });
        })
        .catch((err) => {
          console.log("Err", err);
          // showErrAlert("View Quote is not available due to error.");
          this.setState({ loadingNoLoss: false });
        });
    }
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  handleBind = async () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    let domain = sessionStorage.getItem("domain");

    let body = {
      companyName: currProspect.companyProfile.companyName.value,
      date: quoteData.date,
      uuid: quoteData.uuid,
      email: currProspect.companyProfile.emailId.value,
    };

    try {
      let requestBody = {
        domain,
        email: currProspect.companyProfile.emailId.value,
      };

      await axios
        .post(
          awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateProposalStatus",
          JSON.stringify(requestBody)
        )
        .then((res) => {
          console.log("updated proposal count");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }

    sessionStorage.setItem("formStage", "four");
    window.location.reload();

    // axios
    //   .post(awsUrl2 + "/api/sendBindMail", JSON.stringify(body))
    //   .then((response) => {
    //     console.log("bind mail sent successfully");
    //   })
    //   .catch((error) => {
    //     console.log("error while sending bind email.", error);
    //   })
    //   .finally(() => {
    //     try {
    //       let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
    //       let email = JSON.parse(sessionStorage.getItem("currProspect"))
    //         .companyProfile.emailId.value;

    //       let updateBody = {
    //         sortKeyList,
    //         email,
    //       };

    //       axios
    //         .post(
    //           awsUrl2 + "/api/updateSolveUsersData",
    //           JSON.stringify(updateBody)
    //         )
    //         .then((res) => {
    //           console.log("updated table data successfully");
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         })
    //         .finally(() => {
    //           sessionStorage.setItem("formStage", "four");
    //           window.location.reload();
    //         });
    //     } catch (error) {
    //       console.log("error", error);
    //       sessionStorage.setItem("formStage", "four");
    //       window.location.reload();
    //     }
    //   });
  };

  handleCheckClicked = async (event) => {
    let { isNoLossChckd } = this.state;
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;
    let domain = sessionStorage.getItem("domain");
    let salesID = sessionStorage.getItem("salesID");
    let user_email_id = domain
      ? domain
      : salesID
      ? salesID.toLowerCase()
      : currProspect?.companyProfile?.companyName?.value
      ? currProspect.companyProfile.companyName.value.toLowerCase()
      : "";

    for (let sortkey of sortKeyList) {
      let dataToSend = {
        user_email_id,
        uuid_carrier: sortkey,
        nolossGenerated: !isNoLossChckd,
        dFrom: "",
      };
      this.setState({
        isNoLossChckd: !isNoLossChckd,
      });
      await axios
        .post(awsUrl2 + "/api/updateFourthUserStatusTable", dataToSend)
        .then((res) => {})
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });
    }
  };

  handleDateSelected = (date) => {
    this.setState({ startDate: date });
    this.generateNoLoss(date);
  };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  createTable = () => {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    if (!currProspectDetails) return [];
    let yearsInBusiness;

    try {
      if (
        currProspectDetails &&
        currProspectDetails.companyProfile &&
        currProspectDetails.companyProfile.yearsInBusiness &&
        currProspectDetails.companyProfile.yearsInBusiness.value
      ) {
        yearsInBusiness = Number(
          currProspectDetails.companyProfile.yearsInBusiness.value
        );
      }
    } catch (error) {
      console.log(error);
    }

    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    // let yearBusinessEstablished = moment(
    //   currProspectDetails.companyProfile.effectiveDate.value
    // );
    // let effectiveDate = moment(
    //   currProspectDetails.companyProfile.effectiveDate.value
    // );
    let historyLimitYear = new Date().getFullYear() - (yearsInBusiness || 0);
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date().getFullYear() - 1;
    rowIdDupYear = new Date().getFullYear() - 1;

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      // if (historicalClaimsCheck[absYear]) {
      //   backgroundColor = "#dddddd";
      // } else {
      //   backgroundColor = "";
      // }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup}>
              <td className="col-4" id="carrier">
                <Form.Group>
                  <label
                    htmlFor={`carrier-${absYear}`}
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Carrier
                  </label>
                  <input
                    autoComplete="off"
                    id={`carrier-${absYear}`}
                    type="text"
                    name="carText"
                    onChange={(e) => this.handleChange(e, "carrier")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].carrier
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "carrier");
                    }}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["carrier"]
                        ? historicalClaims[absYear]["carrier"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-3" id="Annaulprem">
                <Form.Group>
                  <label
                    htmlFor={`annPre-${absYear}`}
                    title="Annual Premium"
                    style={{ fontSize: "12px" }}
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Annual Premium
                  </label>
                  <NumberFormat
                    name="anualPremium"
                    id={`annPre-${absYear}`}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "annPre");
                    }}
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "annPre")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].annPre
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["annPre"]
                        ? historicalClaims[absYear]["annPre"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-3" id="claims">
                <Form.Group>
                  <label
                    title="Number of claims"
                    htmlFor={`claims-${absYear}`}
                    className="font-family-montserrat-semi-bold text-nowrap "
                  >
                    # Claims
                  </label>
                  <NumberFormat
                    name="claims"
                    id={`claims-${absYear}`}
                    maxLength="2"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "claim");
                    }}
                    onChange={(e) => this.handleChange(e, "claim")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].claim
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["claim"]
                        ? historicalClaims[absYear]["claim"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-4" id="totalincurred">
                <Form.Group>
                  <label
                    htmlFor={`totInc-${absYear}`}
                    title="Total Incurred"
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Total Incurred
                  </label>
                  <NumberFormat
                    onFocus={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "totInc");
                    }}
                    id={`totInc-${absYear}`}
                    autoComplete="off"
                    name="totInc"
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "totInc")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                        historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].totInc
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    // onBlur={(e) => this.zeroDefault(e, "totInc")}
                    value={
                      historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["totInc"]
                        ? historicalClaims[absYear]["totInc"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              {/* <td className="per10">
            <Form.Group>
              <label
                htmlFor={`totPaid-${absYear}`}
                title="Total Paid"
                className="font-family-montserrat-semi-bold"
              >
                Total Paid
              </label>
              <NumberFormat
                onFocus={(e) => {
                  this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                }}
                onBlur={this.handleTotPaidBlur}
                id={`totPaid-${absYear}`}
                autoComplete="off"
                name="totPaid"
                thousandSeparator={true}
                onChange={(e) => this.handleChange(e, "totPaid")}
                style={{
                  backgroundColor:
                    historyLimitYear > rowId || historicalClaimsCheck[absYear]
                      ? "#dddddd"
                      : "",
                  // backgroundColor: backgroundColor,
                  borderColor:
                    // submitClicked &&
                    historicalClaimsErrorStore[absYear] &&
                    historicalClaimsErrorStore[absYear].totPaid
                      ? "red"
                      : "",
                }}
                disabled={historyLimitYear > rowId}
                prefix={"$"}
                className="text-center"
                type="text"
                // onBlur={zeroDefault}
                value={
                  historicalClaims &&
                  historicalClaims[absYear] &&
                  historicalClaims[absYear]["totPaid"]
                    ? historicalClaims[absYear]["totPaid"]
                    : ""
                }
              />
            </Form.Group>
          </td>
          <td className="per10">
            <Form.Group>
              <label
                htmlFor={`totRes-${absYear}`}
                title="Total Reserves"
                className="font-family-montserrat-semi-bold"
              >
                Total Reserves
              </label>
              <NumberFormat
                onFocus={(e) => {
                  this.clearErr(e, historicalClaimsErrorStore, "totRes");
                }}
                id={`totRes-${absYear}`}
                autoComplete="off"
                name="totRes"
                thousandSeparator={true}
                onChange={(e) => this.handleChange(e, "totRes")}
                style={{
                  backgroundColor:
                    historyLimitYear > rowId || historicalClaimsCheck[absYear]
                      ? "#dddddd"
                      : "",
                  // backgroundColor: backgroundColor,
                  borderColor:
                    // submitClicked &&
                    historicalClaimsErrorStore[absYear] &&
                    historicalClaimsErrorStore[absYear].totRes
                      ? "red"
                      : "",
                }}
                disabled={historyLimitYear > rowId}
                prefix={"$"}
                className="text-center "
                type="text"
                // onBlur={zeroDefault}
                value={
                  historicalClaims &&
                  historicalClaims[absYear] &&
                  historicalClaims[absYear]["totRes"]
                    ? historicalClaims[absYear]["totRes"]
                    : ""
                }
              />
            </Form.Group>
          </td> */}
            </tr>{" "}
          </div>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear > yearId}
                checked={
                  historyLimitYear > yearId || historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear] || true
                    : false
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage in {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  setSelectedCarrier = (val) => {
    let { selectedCarrier, peoPaygo } = this.state;
    if (selectedCarrier !== val) {
      this.setState({ selectedCarrier: val }, () => {
        if (peoPaygo[val]) this.setState({ fileURL: peoPaygo[val] });
        else this.generateAccordAndStore();
      });
    }
  };

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  updateSalesPerson = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + `/api/salesPersonData/update`, JSON.stringify(data))
        .then((res) => resolve())
        .catch((err) => reject());
    });
  };

  updateSubmitUserTracking = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateSubmitStatus",
          JSON.stringify(data)
        )
        .then((res) => resolve())
        .catch((err) => reject());
    });
  };

  handleSubmit = async (
    salespersonCheck,
    mailCampaignUserCheck,
    bindCarrier,
    bindOnline
  ) => {
    $("#loader").css("display", "block");
    let { updatePopUpData } = this.props;
    let {
      historicalClaims,
      historicalClaimsCheck,
      isNoLossChckd,
      startDate,
    } = this.state;
    let currProspect = sessionStorage.getItem("currProspect");
    let sortKeyList = sessionStorage.getItem("sortKeyList");
    let landingEmail = sessionStorage.getItem("landingEmail");

    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");

    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) {}

    try {
      currProspect = JSON.parse(currProspect);
      sortKeyList = JSON.parse(sortKeyList);

      let domain = sessionStorage.getItem("domain");
      let salesID = sessionStorage.getItem("salesID");

      let user_email_id = domain
        ? domain
        : salespersonCheck && salesID
        ? salesID.toLowerCase()
        : currProspect.companyProfile.companyName &&
          currProspect.companyProfile.companyName.value
        ? currProspect.companyProfile.companyName.value.toLowerCase()
        : "";

      try {
        if (startDate) {
          startDate = moment(startDate).format("YYYY-MM-DD");
        } else {
          throw "invalid date";
        }
      } catch (error) {
        startDate = "";
      }

      let requestBody = {
        user_email_id,
        sortKeyList,
        historicalClaims,
        historicalClaimsCheck,
        nolossGenerated: isNoLossChckd,
        startDate,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
      };

      let trackBody = {
        domain: domain,
        email: landingEmail,
        isSubmitted: "true",
      };

      let salesBody = {
        id: salesID?.toLowerCase(),
        isSubmitted: "true",
      };

      requestBody.bindCarrier = bindCarrier;
      salesBody.bindCarrier = bindCarrier;
      trackBody.bindCarrier = bindCarrier;

      if (salespersonCheck) {
        requestBody.salespersonQuoteSubmitted = "true";
        trackBody.isSalespersonSubmitted = "true";
        salesBody.isSalespersonSubmitted = "true";
        sessionStorage.setItem("salespersonQuoteSubmitted", "true");
        this.setState({ salespersonQuoteSubmitted: true });
      } else {
        requestBody.userQuoteSubmitted = "true";
        trackBody.isUserSubmitted = "true";
        salesBody.isUserSubmitted = "true";
      }

      if (amtrustAccountDetails) {
        requestBody.amtrustAccountDetails = amtrustAccountDetails;
        salesBody.amtrustAccountDetails = amtrustAccountDetails;
        trackBody.amtrustAccountDetails = amtrustAccountDetails;
      }
      let header = await getHeader();

      await axios
        .post(awsUrl2 + "/api/underWriterData/update", requestBody, header)
        .then(async (res) => {
          if (salespersonCheck && salesID && !landingEmail) {
            await this.updateSalesPerson(salesBody);
          } else {
            await this.updateSubmitUserTracking(trackBody);
          }
          this.props.setQuoteSubmittedStatus(true);
          this.props.setCarrierSubmittedWith(bindCarrier);
          if (bindCarrier === "paygo" && bindOnline) {
            this.props.setAmtrustBoundStatus(true);
          }
          updatePopUpData({
            show: true,
            title: "Thank You for the Opportunity",
            children: (
              <>
                <br></br>
                <span style={{ fontWeight: "bold" }}>
                  Our Team will get back to you in the next 24 Hours.
                </span>
              </>
            ),
            acceptBtn: "Okay",
            disableNoButton: true,
          });
        })
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });
      $("#loader").css("display", "none");
    } catch (error) {
      console.log("error while saving claims info", error);
      $("#loader").css("display", "none");
    }
  };

  isPeoRequired = () => {
    let response = true;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let companyProfileAddQuestions = currProspect.companyProfileAddQuestions;
      if (
        companyProfileAddQuestions &&
        companyProfileAddQuestions[2].response === "b"
      ) {
        response = false;
      }
    } catch (error) {
      response = true;
    }

    return response;
  };

  isPaygoRequired = () => {
    let response = true;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let companyProfileAddQuestions = currProspect.companyProfileAddQuestions;
      if (
        companyProfileAddQuestions &&
        companyProfileAddQuestions[2].response === "a"
      ) {
        response = false;
      }
    } catch (error) {
      response = true;
    }

    return response;
  };

  handleDocUploaded = (value) => {
    this.setState({ docUploaded: value });
  };

  amtrustQuoteBind = async () => {
    //amtrust bind api call
    let response = true;
    let { salespersonCheck } = this.state;
    try {
      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );

      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);

      let domain = sessionStorage.getItem("domain");
      let salesID = sessionStorage.getItem("salesID");
      let currProspect = sessionStorage.getItem("currProspect");

      let amtrustTriggerResponse = await axios.post(
        awsUrl2 + `/api/triggerAmtrustBindQuote`,
        JSON.stringify({
          quoteId: amtrustAccountDetails.QuoteId,
          amtrustPaygoEligibility: this.state.payoEligibility,
          user_email_id: domain
            ? domain
            : salespersonCheck && salesID
            ? salesID.toLowerCase()
            : currProspect.companyProfile.companyName &&
              currProspect.companyProfile.companyName.value
            ? currProspect.companyProfile.companyName.value.toLowerCase()
            : "",
          sortKeyList: sessionStorage.getItem("sortKeyList")
            ? JSON.parse(sessionStorage.getItem("sortKeyList"))
            : undefined,
        })
      );

      let uuid;
      if (amtrustTriggerResponse.data.uuid) {
        uuid = amtrustTriggerResponse.data.uuid;
      } else {
        throw "error in amtrust create quote trigger";
      }

      let amtrustQuoteBindResponse;
      do {
        amtrustQuoteBindResponse = await amtrustResponse(
          uuid,
          "getBindQuoteResponse"
        );

        if (amtrustQuoteBindResponse === "error") {
          throw "error in amtrust quote binding";
        } else if (
          amtrustQuoteBindResponse &&
          amtrustQuoteBindResponse !== "processing"
        ) {
          amtrustAccountDetails = {
            ...amtrustAccountDetails,
            BindData: amtrustQuoteBindResponse,
          };
        }
      } while (amtrustQuoteBindResponse === "processing");
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(amtrustAccountDetails)
      );
    } catch (error) {
      console.log(error);
      response = false;
    }
    return response;
  };

  onClickProceedToBind = (carrier, bindOnline) => {
    let { updatePopUpData } = this.props;
    let { salespersonCheck } = this.state;

    try {
      startLoader();
      let mailCampaignUserCheck = checkMailCampaignUser();

      let salespersonMailCampaign = sessionStorage.getItem(
        "salespersonMailCampaign"
      );

      let bindWithAmtrust = carrier === "paygo" && bindOnline;
      updatePopUpData({
        show: true,
        title: "Do you wish to continue?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              {`${
                bindWithAmtrust
                  ? "This will bind the quote with"
                  : "This will submit to the UW desk of"
              } ${carrier.toUpperCase()}`}
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          if (bindWithAmtrust) {
            await this.amtrustQuoteBind();
          }
          await this.handleSubmit(
            salespersonCheck,
            mailCampaignUserCheck && !salespersonMailCampaign,
            carrier,
            bindOnline
          );
          stopLoader();
        },
        rejectBtnCallback: () => {
          stopLoader();
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  toastError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  render() {
    let { updatePopUpData } = this.props;
    let calculated_data = "";
    let {
      fileURL,
      fileList,
      quoteStatus,
      isNoLossChckd,
      fileURL2,
      loadingNoLoss,
      startDate,
      loggedIn,
      peoType,
      historicalClaimsError,
      emodStatesData,
      selectedCarrier,
      salespersonCheck,
      industry_type,
      clientEligibility,
      docUploaded,
      payoEligibility,
      quoteSubmitted,
      carrierSubmittedWith,
      amtrustBound,
      adminType,
      netRateMap,
      salespersonQuoteSubmitted,
      salesManagerCheck,
      salesManagerPersonName,
      isSalesforce,
      selectedstates,
      peoPaygo,
      carrierStateMap,
      admin_type_values,
      reCalculateData,
    } = this.state;
    // const table = this.createTable();
    let mailCampaignUserCheck = checkMailCampaignUser();

    let disableGetQuote = sessionStorage.getItem("disableGetQuote");
    let disableContinue = sessionStorage.getItem("disableContinue");

    let salespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    );

    let isPeoRequired = salespersonCheck ? this.isPeoRequired() : true;
    let isPaygoRequired = salespersonCheck ? this.isPaygoRequired() : true;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    if (!isPeoRequired) {
      if (selectedCarrier !== "paygo") {
        this.setState({ selectedCarrier: "paygo" });
      }
    }
    if (!isPaygoRequired) {
      if (selectedCarrier !== "peo") {
        this.setState({ selectedCarrier: "peo" });
      }
    }

    let zeroYearsInBusiness = false;

    let currProspect,
      yearsInBusiness = 100000;
    try {
      currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : null;

      if (
        currProspect &&
        currProspect.companyProfile &&
        currProspect.companyProfile.yearsInBusiness &&
        currProspect.companyProfile.yearsInBusiness.value
      ) {
        yearsInBusiness = Number(
          currProspect.companyProfile.yearsInBusiness.value
        );
      }
    } catch (error) {
      console.log("error on indication page : fetching currProspected", error);
    }

    zeroYearsInBusiness = yearsInBusiness === 0;

    if (!isNoLossChckd) {
      isNoLossChckd = zeroYearsInBusiness;
    }

    let tabList = [];

    if (isNoLossChckd && fileURL2 && startDate) {
      tabList.push({
        tabName: "No Loss",
        children: (
          <LoadingOverlay active={this.state.loadingNoLoss} spinner>
            <iframe
              id={`iframeNoLoss`}
              src={fileURL2}
              style={{ border: "none", height: "645px" }}
              width="100%"
            ></iframe>
          </LoadingOverlay>
        ),
      });
    }

    // tabList.push({
    //   tabName: "Acord",
    //   children: (
    //     <LoadingOverlay active={this.state.loadingAccord} spinner>
    //       <iframe
    //         id={`iframeAccord`}
    //         src={fileURL + "#toolbar=0"}
    //         style={{ border: "none", height: "645px" }}
    //         width="100%"
    //       ></iframe>
    //     </LoadingOverlay>
    //   ),
    // });
    // this.setState({
    //   fileURL: this.state.peofileURL || this.state.paygofileURL,
    // });
    // console.log("FILEURL", this.state.peofileURL);
    // console.log("SELectedCarrier", selectedCarrier);
    // console.log("NetrateMap", Object.keys(netRateMap).length);
    let tabName_acord;
    let flagAcord = false;
    if (selectedCarrier === "peo") {
      tabName_acord = "PEO ACORD";
      flagAcord = true;
    } else if (
      selectedCarrier === "paygo" &&
      Object.keys(netRateMap).length > 0
    ) {
      tabName_acord = "PAYGO ACORD";
      flagAcord = true;
    }
    if (flagAcord) {
      let { acordloadtab } = this.state;
      let fileMapView =
        acordloadtab[selectedCarrier] == "CA"
          ? peoPaygo[selectedCarrier]["CA"]
          : acordloadtab[selectedCarrier] == "FL"
          ? peoPaygo[selectedCarrier]["FL"]
          : peoPaygo[selectedCarrier]["Ncci"];
      tabList.push({
        tabName: tabName_acord,
        children: (
          <LoadingOverlay
            active={this.state.loadingAccord}
            spinner
            className={isMobile ? "spinner" : ""}
          >
            <div
              className={
                isMobile ? "accord-container-mobile" : "accord-container"
              }
            >
              <div
                className={
                  isMobile ? "accord-toolbar-mobile" : "accord-toolbar"
                }
              >
                <a
                  href={fileMapView}
                  download={tabName_acord}
                  title={`Download: ${tabName_acord}`}
                >
                  <img
                    height="30"
                    src={require("../../../images/download-file.png")}
                  ></img>
                  <span style={{ color: "white" }}>
                    {isMobile
                      ? `Download ${
                          acordloadtab[selectedCarrier]
                            ? acordloadtab[selectedCarrier].toUpperCase()
                            : ""
                        } Acord`
                      : ""}
                  </span>
                </a>
              </div>

              {isMobile ? (
                ""
              ) : (
                <iframe
                  id={`iframeAccord`}
                  src={fileMapView || "about:blank" + "#toolbar=0"}
                  style={{ border: "none", height: "645px" }}
                  width="100%"
                ></iframe>
              )}
            </div>
          </LoadingOverlay>
        ),
      });
    }
    if (this.state.data !== undefined && this.state.data !== null) {
      for (let carrier in this.state.data) {
        let requireData = this.getAllDataWRTCarrier(
          carrier,
          this.state.data[carrier]
        );
        calculated_data = requireData.calculated_data;
      }
    }

    if (fileList === undefined || fileList === null) {
      fileList = {
        first: {},
        second: {},
      };
    }

    let showLossRunsAndUploadZonesAndEmod =
      mailCampaignUserCheck &&
      !salespersonMailCampaign &&
      selectedCarrier === "paygo" &&
      payoEligibility?.Eligibility === "BindEligible"
        ? false
        : true;

    let disablePeoSubmitToUw =
      hideButtons ||
      (salespersonCheck ? salespersonQuoteSubmitted : quoteSubmitted) ||
      salesManagerCheck ||
      disableContinue ||
      disableGetQuote
        ? true
        : false;

    let disablePaygoSubmitToUw =
      hideButtons ||
      (salespersonCheck ? salespersonQuoteSubmitted : quoteSubmitted) ||
      salesManagerCheck ||
      disableContinue ||
      disableGetQuote
        ? true
        : false;

    let disablePaygoBindOnline =
      hideButtons || salesManagerCheck || disableContinue || disableGetQuote
        ? true
        : false;

    // keep the bind online button enabled if the quote has been submitted to the UW (paygo)
    let temp = false;
    if (carrierSubmittedWith === "paygo") {
      if (amtrustBound) {
        temp = true;
      }
    } else if (carrierSubmittedWith === "peo") {
      temp = true;
    }

    disablePaygoBindOnline = disablePaygoBindOnline || temp;

    return (
      <div id="cmprQts" className="container-fluid per98">
        <NewProgress />
        <div id="compareQuote">
          <QuoteTable
            data={this.state.data}
            selectedCarrier={selectedCarrier}
            setSelectedCarrier={this.setSelectedCarrier}
            loading={this.state.loading}
            isPeoRequired={isPeoRequired}
            isPaygoRequired={isPaygoRequired}
            industry_type={industry_type}
            clientEligibility={clientEligibility}
            payoEligibility={payoEligibility}
            adminType={adminType}
            onClickProceedToBind={this.onClickProceedToBind}
            disablePeoSubmitToUw={disablePeoSubmitToUw}
            disablePaygoSubmitToUw={disablePaygoSubmitToUw}
            disablePaygoBindOnline={disablePaygoBindOnline}
            isSalesforce={isSalesforce}
            admin_type_values={admin_type_values}
            reCalculateData={reCalculateData}
            carrierSubmittedWith={carrierSubmittedWith}
          />
          {showLossRunsAndUploadZonesAndEmod && (
            <>
              <div className="mt-5">
                <div
                  className="pink-header font-family-montserrat-bold BindableQuoteContent"
                  style={{ fontSize: "x-large" }}
                >
                  Please provide the following information to get bindable quote
                </div>
                <DropZones
                  updatePopUpData={updatePopUpData}
                  fileList={fileList}
                  handleDocUploaded={this.handleDocUploaded}
                  quoteSubmitted={quoteSubmitted}
                  salesPersonLoginCheck={salesPersonLoginCheck}
                  brdCheck={this.state.brdCheck}
                />
              </div>
              {/* <div className="d-flex mt-4">
                <input
                  className="mr-1"
                  type="checkbox"
                  id="chck-1"
                  name={"chck"}
                  checked={isNoLossChckd}
                  disabled={
                    zeroYearsInBusiness ||
                    loadingNoLoss ||
                    quoteStatus === "proceed_uw"
                  }
                  onChange={this.handleCheckClicked}
                />
                <label for="chck-1" className="">
                  No historical claims
                </label>
              </div> */}
              {/* <div if="datepicker-startDate">
                {isNoLossChckd && (
                  <>
                    <label
                      for="startDate"
                      title="Business start date"
                      className="mr-2"
                    >
                      Business Start Date
                    </label>
                    <span id="startDate">
                      <DatePicker
                        selected={startDate}
                        onChange={this.handleDateSelected}
                        maxDate={moment()}
                        showYearDropdown
                        showMonthDropdown
                        useShortMonthInDropdown
                        disabled={quoteStatus === "proceed_uw"}
                      />
                    </span>
                  </>
                )}
              </div> */}
            </>
          )}

          {/* {!isNoLossChckd && (
            <div className="formTitle historical-claims mb-3">
              <h4 className="history-header">
                <b className="font-family-montserrat-bold">
                  Historical claims information
                </b>
              </h4>
            </div>
          )} */}
          {/* {!isNoLossChckd && (
            <div className="row historical-claims-container">
              {historicalClaimsError && (
                <p id="claims-error" className="text-danger">
                  Claims submitted should be for consecutive years!
                </p>
              )}
              <div className="container-fluid">
                <div className="row grey-table-row ">
                  <table id="claimTable" className="grey-table">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>{table}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )} */}
          {(salespersonMailCampaign || !mailCampaignUserCheck) && loggedIn && (
            <>
              <div className="emod-ques mb-3">
                {Object.keys(emodStatesData || {}).map((e) => {
                  return (
                    <EmodQuestions
                      key={e}
                      state={e}
                      emodData={emodStatesData[e]}
                      updateEmodInfo={this.updateEmodInfo}
                    />
                  );
                })}
              </div>
            </>
          )}
          {(salespersonMailCampaign || !mailCampaignUserCheck) && loggedIn && (
            <>
              <div className="mt-2">
                <div className="row no-gutters d-flex justify-content-end w-100">
                  <div>
                    {isMobile ? (
                      ""
                    ) : (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          if (document.getElementById("iframeAccord"))
                            document
                              .getElementById("iframeAccord")
                              .contentWindow.location.reload();

                          if (document.getElementById("iframeNoLoss"))
                            document
                              .getElementById("iframeNoLoss")
                              .contentWindow.location.reload();
                        }}
                      >
                        {"refresh"}
                      </button>
                    )}
                  </div>
                  {tabList.length > 0 && carrierStateMap !== undefined && (
                    <Tabs
                      carrierStateMap={this.state.carrierStateMap}
                      acordloadtab={this.state.acordloadtab}
                      updateAcordSelectedState={this.updateAcordSelectedState}
                      tabList={tabList}
                      className="mt-4"
                    />
                  )}
                </div>
              </div>
            </>
          )}
          <div> </div>
        </div>
        <Toaster />
      </div>
    );
  }
}

export default function FormCmprQuoteWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormCmprQuote
          updatePopUpData={props.updatePopUpData}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
          carrierSubmittedWith={context.carrierSubmittedWith}
          setCarrierSubmittedWith={context.setCarrierSubmittedWith}
          amtrustBound={context.amtrustBound}
          setAmtrustBoundStatus={context.setAmtrustBoundStatus}
          salesManagerData={context.salesManagerData}
          setSalesManagerData={context.setSalesManagerData}
          reCalculateData={context.reCalculateData}
          setReCalculateData={context.setReCalculateData}
        />
      )}
    </Context.Consumer>
  );
}
