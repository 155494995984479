import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

class CustomDropZone extends React.Component {
  render() {
    let {
      fileList,
      updateFileList,
      removeFile,
      uploadMsg,
    } = this.props;

    let listToShow = [];

    for (let fileName in fileList) {
      listToShow.push(
        <li
          className="m-3 d-flex justify-content-between"
          key={fileName}
        >
          <span>{fileName}</span>
          {fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded" && (
            <span className="fw-bold" onClick={() => removeFile(fileName)}>
              {"X"}
            </span>
          )}
        </li>
      );
    }

    return (
      <div className="customDropZone" style={{ width: "500px" }}>
        <DropZone updateFileList={updateFileList} />
        {listToShow?.length > 0 ? <ul style={{ fontSize: "12px" }}>{listToShow}</ul> : <></>}
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(file);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className="dropZoneText">Drag &amp; drop or browse to select file</p>
    </div>
  );
};

export default CustomDropZone;
