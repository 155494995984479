import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

class Allques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: this.props.quesList,
    };
  }

  componentDidUpdate() {
    if (this.state.quesList !== this.props.quesList) {
      console.log();
      this.setState({ quesList: this.props.quesList });
    }
  }

  createTable = () => {
    const { quesList } = this.state;
    const { updateQuesList, defaultChecked } = this.props;
    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < quesList.length; i++) {
      let isPeoPaygoQues = quesList[i].name === "qc3" ? true : false;
      let isPeoPaygoQuesRes = quesList[i].response;
      let checkedYes = false,
        checkedNo = false;
      if (defaultChecked) {
        if (quesList[i].response) {
          checkedYes = true;
        } else {
          checkedNo = true;
        }
      } else {
        if (quesList[i].response === true) {
          checkedYes = true;
        } else if (quesList[i].response === false) {
          checkedNo = true;
        }
      }
      table.push(
        isPeoPaygoQues ? (
          <tr key={i}>
            <td className={"tableQuesCol" + " " + `trow${i}`}>
              <b>{quesList[i].ques}</b>
            </td>
            <td className="tableAns">
              <Form.Check
                inline
                id={`input1-${i}`}
                value="a"
                name={quesList[i].name}
                checked={isPeoPaygoQuesRes === "a"}
                label="PEO"
                type="radio"
                onChange={(e) => updateQuesList(e, "response", "a")}
              />
            </td>
            <td className="tableAns">
              <Form.Check
                inline
                id={`input2-${i}`}
                value="b"
                name={quesList[i].name}
                checked={isPeoPaygoQuesRes === "b"}
                label={"PAYGO"}
                type="radio"
                onChange={(e) => updateQuesList(e, "response", "b")}
              />
            </td>
            <td className="tableAns">
              <Form.Check
                inline
                id={`input3-${i}`}
                value="c"
                name={quesList[i].name}
                checked={isPeoPaygoQuesRes === "c"}
                label={"Both"}
                type="radio"
                onChange={(e) => updateQuesList(e, "response", "c")}
              />
            </td>
          </tr>
        ) : (
          <tr key={i}>
            <td className={"tableQuesCol" + " " + `trow${i}`}>
              <b>{quesList[i].ques}</b>
            </td>
            <td className="tableAns">
              <Form.Check
                inline
                id={`inputY-${i}`}
                value="Y"
                name={quesList[i].name}
                checked={checkedYes}
                label={quesList[i]?.optionLabels?.[0] || "Yes"}
                type="radio"
                onChange={(e) => updateQuesList(e, "response", true)}
              />
            </td>
            <td className="tableAns">
              <Form.Check
                inline
                id={`inputN-${i}`}
                value="N"
                name={quesList[i].name}
                checked={checkedNo}
                label={quesList[i]?.optionLabels?.[1] || "No"}
                type="radio"
                onChange={(e) => updateQuesList(e, "response", false)}
              />
            </td>
            {/* <td className="tableRemarks">
            {quesList[i].type === "percent" ? (
              <NumberFormat
                id={"qr" + (i + 1)}
                className={"ques-input"}
                maxLength="3"
                suffix={"%"}
              />
            ) : (
              <input
                id={`remarks-${i}`}
                value={
                  quesList[i].remarks && quesList[i].remarks !== "none"
                    ? quesList[i].remarks
                    : ""
                }
                type="text"
                className="ques-input"
                disabled={!quesList[i].response}
                onChange={(e) => updateQuesList(e, "remarks", e.target.value)}
                placeholder={
                  quesList[i].response ? "Please enter your comment" : ""
                }
              />
            )}
          </td> */}
          </tr>
        )
      );
    }
    return table;
  };

  render() {
    let { clearAll, saveQuestionsResponse } = this.props;
    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;
    return (
      <table className="table table-borderless table-undrques">
        {this.props.header && (
          <thead>
            <tr>
              <th colSpan={3} className="tableQuesCol">
                <div className="d-flex justify-content-between">
                  <div
                    className="pink-header font-family-montserrat-bold"
                    style={{ fontSize: "x-large" }}
                  >
                    Underwriting Questions
                  </div>
                  <div>
                    {flagPreUnderWriter && false && (
                      <button
                        className="btn clear-btn btn-dark mr-2"
                        style={{
                          width: "max-content",
                          boxShadow: "0px 0px 9px 2px grey",
                          border: "1px solid orange",
                          backgroundColor: "gray",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          saveQuestionsResponse();
                        }}
                      >
                        Save
                      </button>
                    )}
                    <button
                      className="btn clear-btn btn-dark"
                      style={{
                        width: "max-content",
                        boxShadow: "0px 0px 9px 2px grey",
                        border: "1px solid orange",
                        backgroundColor: "gray",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        clearAll();
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        )}
        <tbody>{this.createTable()}</tbody>
      </table>
    );
  }
}
export default Allques;
