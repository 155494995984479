/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_rfozKj6Z7",
    "aws_user_pools_web_client_id": "3tegj73paco36tt6l548os4tr3",
    "oauth": {}
};


export default awsmobile;
